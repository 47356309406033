import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

import Icon from "../../lib/Icon";
import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  fetchAdAccountNavigation,
  fetchAdAccounts,
} from "../../store/modules/adAccount";
import { fetchTargetingQueryNames } from "../../store/modules/adSet";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

interface State {
  selectingAdAccount: boolean;
}

class AdNavigationContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectingAdAccount: false,
    };
    this.handleAdAccountChange = this.handleAdAccountChange.bind(this);
  }

  public componentDidMount() {
    if (!this.props.match) {
      return;
    }
    const { params } = this.props.match;
    this.props.fetchAdAccountNavigation({
      adAccountId: params["ad_account_id"],
    });
    this.props.fetchAdAccounts();
    this.props.fetchTargetingQueryNames();
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      if (!this.props.match) {
        return;
      }
      const { params } = this.props.match;
      this.props.fetchAdAccountNavigation({
        adAccountId: params["ad_account_id"],
      });
    }
  }

  // jsx
  // -----------------------------------------------

  public render() {
    const {
      adAccountIds,
      idAdAccount,
      idAdCampaign,
      idAdSet,
      idConversionTag,
    } = this.props;
    const adAccountId = this.props.match.params["ad_account_id"] as string;
    const focusedAdCampaignId = this.props.match.params[
      "ad_campaign_id"
    ] as string;
    const focusedAdSetId = this.props.match.params["ad_set_id"] as string;
    const adAccount = idAdAccount[adAccountId];

    return (
      <Container>
        <AdAccountWrapper>
          <AdAccountName>
            {adAccountId && (
              <>
                <AdAccountLink
                  to={`/admin/ad/ad_accounts/${adAccountId}/summary`}
                >
                  {!!adAccount && adAccount.name}
                </AdAccountLink>
              </>
            )}
          </AdAccountName>
          <AdAccountSelectTriggerIcon
            name={"wt-icon-triangle-down"}
            onClick={() => this.setState({ selectingAdAccount: true })}
          />
          {this.state.selectingAdAccount ? (
            <AdAccountModal
              onClick={() => this.setState({ selectingAdAccount: false })}
            >
              <AdAccountModalContent>
                {adAccountIds.map(adAccountId => (
                  <AdAccountLinkItemLink
                    to={`/admin/ad/ad_accounts/${adAccountId}/summary`}
                    onClick={() => {
                      this.setState({ selectingAdAccount: false });
                    }}
                  >
                    {idAdAccount[adAccountId].name}
                  </AdAccountLinkItemLink>
                ))}
              </AdAccountModalContent>
            </AdAccountModal>
          ) : null}
        </AdAccountWrapper>
        <AdWrapper>
          <NewAdCampaignLink
            to={`/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/new`}
          >
            New Campaign
          </NewAdCampaignLink>
          <List>
            {!!adAccount &&
              adAccount.adCampaigns.map(adCampaignId => (
                <ListItem key={adCampaignId}>
                  <AdCampaignItem
                    focused={
                      !focusedAdSetId &&
                      Number(adCampaignId) === Number(focusedAdCampaignId)
                    }
                    key={adCampaignId}
                  >
                    <AdCampaignStyledLink
                      to={`/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/summary`}
                    >
                      {idAdCampaign[adCampaignId].name}
                    </AdCampaignStyledLink>
                    {idAdCampaign[adCampaignId].status === "ACTIVE" && (
                      <ActiveIndicator />
                    )}
                    {idAdCampaign[adCampaignId].status === "STOPPED" && (
                      <StoppedIndicator />
                    )}
                    {idAdCampaign[adCampaignId].status === "BUDGET_OVER" && (
                      <BudgetOverIndicator />
                    )}
                  </AdCampaignItem>
                  {idAdCampaign[adCampaignId].adSets.map(
                    adSetId =>
                      !!idAdSet[adSetId] && (
                        <AdSetItem
                          focused={Number(adSetId) === Number(focusedAdSetId)}
                          key={adSetId}
                        >
                          <AdSetStyledLink
                            to={`/admin/ad/ad_accounts/${adAccount.id}/ad_campaigns/${adCampaignId}/ad_sets/${adSetId}/summary`}
                          >
                            {idAdSet[adSetId].name}
                          </AdSetStyledLink>
                          {idAdSet[adSetId].status === "ACTIVE" && (
                            <ActiveIndicator />
                          )}
                          {idAdSet[adSetId].status === "STOPPED" && (
                            <StoppedIndicator />
                          )}
                          {idAdSet[adSetId].status === "OUT_OF_PERIOD" && (
                            <OutOfPeriodIndicator />
                          )}
                        </AdSetItem>
                      )
                  )}
                </ListItem>
              ))}
          </List>
        </AdWrapper>
        <ConversionTagWrapper>
          <NewConversionTag
            to={`/admin/ad/ad_accounts/${adAccountId}/conversion_tags/new`}
          >
            {"New CV Tag"}
          </NewConversionTag>
          <List>
            {!!adAccount &&
              adAccount.conversionTags.map(
                conversionTagId =>
                  !!idConversionTag[conversionTagId] && (
                    <ConversionTagItem key={conversionTagId}>
                      <ConversionTagStyledLink
                        to={`/admin/ad/ad_accounts/${adAccountId}/conversion_tags/${conversionTagId}`}
                      >
                        {idConversionTag[conversionTagId].name}
                      </ConversionTagStyledLink>
                    </ConversionTagItem>
                  )
              )}
          </List>
        </ConversionTagWrapper>
      </Container>
    );
  }

  private handleAdAccountChange(e: React.FormEvent<HTMLSelectElement>) {
    this.props.history.push(
      `/admin/ad/ad_accounts/${e.currentTarget.value}/summary`
    );
  }
}

//  connect
// -----------------------------------------------

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdNavigationContainer)
);

function mapStateToProps(state: RootState) {
  const {
    adAccounts: idAdAccount,
    adAccountIds,
    conversionTags: idConversionTag,
  } = state.adAccount;
  const { adCampaigns: idAdCampaign } = state.adCampaign;
  const { adSets: idAdSet } = state.adSet;

  return { adAccountIds, idAdAccount, idAdCampaign, idAdSet, idConversionTag };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchAdAccountNavigation: (params: { adAccountId: string }) =>
        fetchAdAccountNavigation.started(params),
      fetchAdAccounts: () => fetchAdAccounts.started(),
      fetchTargetingQueryNames: () => fetchTargetingQueryNames.started(),
    },
    dispatch
  );
}

const Container = styled.div`
  width: 240px;
`;

const AdAccountWrapper = styled.div`
  position: relative;
  margin-top: 23px;
  width: 240px;
  display: flex;
  justify-content: space-between;
`;

const AdWrapper = styled.div`
  margin-bottom: 10px;
`;

const ConversionTagWrapper = styled.div``;

const NewConversionTag = styled(Link)`
  ${heading}
  width: 208px;
  height: 40px;
  padding: 9px 48px;
  border-radius: 48px;
  display: block;
  margin-top: 12px;
  margin-left: 14px;
  color: ${colors.blackForeground600};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`;

const AdAccountName = styled.div`
  height: 28px;
  margin-left: 16px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.84);
  overflow: hidden;
`;

const AdAccountLink = styled(Link)`
  ${heading}
  line-height: 1.4;
  color: ${colors.blackForeground840};
`;

const AdAccountSelectTriggerIcon = styled(Icon)`
  font-size: 26px;
  margin-right: 12px;
  cursor: pointer;
`;

const AdAccountModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
`;

const AdAccountModalContent = styled.div`
  position: absolute;
  width: 226px;
  top: 56px;
  left: 64px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`;

const AdAccountLinkItemLink = styled(Link)`
  padding: 7px 10px 7px 12px;
  max-width: 226px;
  word-wrap: break-word;
  display: block;
  font-size: 14px;
  color: ${colors.blackForeground840};

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const NewAdCampaignLink = styled(Link)`
  ${heading}
  width: 208px;
  height: 40px;
  padding: 9px 48px;
  border-radius: 48px;
  display: block;
  margin-top: 12px;
  margin-left: 14px;
  color: ${colors.blackForeground600};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
`;

const ListItem = styled.div``;

const List = styled.div`
  margin-top: 15px;
`;

const AdCampaignItem = styled.div<{ focused: boolean }>`
  width: 240px;
  min-height: 48px;
  padding: 12px 16px;
  display: flex;
  ${props =>
    props.focused
      ? css`
          background-color: rgba(0, 0, 0, 0.03);
        `
      : css``}
`;

const AdSetItem = styled.div<{ focused: boolean }>`
  width: 240px;
  min-height: 40px;
  padding: 8px 16px;
  display: flex;
  ${props =>
    props.focused
      ? css`
          background-color: rgba(0, 0, 0, 0.03);
        `
      : css``}
`;

const AdCampaignStyledLink = styled(Link)`
  ${heading}
  text-decoration: none;
  width: 180px;
  min-height: 24px;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  color: ${colors.blackForeground840};
`;

const AdSetStyledLink = styled(Link)`
  text-decoration: none;
  width: 180px;
  min-height: 24px;
  line-height: 1.71;
  word-wrap: break-word;
  color: ${colors.blackForeground600};
  display: block;
`;

const ConversionTagItem = styled.div`
  width: 240px;
  height: 40px;
  padding: 8px 16px;
  display: flex;
`;

const ConversionTagStyledLink = styled(Link)`
  text-decoration: none;
  width: 208px;
  height: 24px;
  line-height: 1.71;
  word-wrap: break-word;
  color: ${colors.blackForeground600};
  display: block;
`;

const ActiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #08c5e7;
  margin: auto;
`;

const StoppedIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
  margin: auto;
`;

const BudgetOverIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  background-color: #f65948;
  margin: auto;
`;

const OutOfPeriodIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  background-color: #f65948;
  margin: auto;
`;
