import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import ReactModal from "react-modal";

import Icon from "../lib/Icon";
import { RootState } from "../store/modules";
import colors from "../styles/color";
import { heading } from "../styles/font";
import {
  fetchTargetingQueries,
  TargetingCountRequest,
  fetchTargetingCount,
  FetchQueryJobResultRequest,
  fetchQueryJobResult,
  CreateTargetingQueryRequest,
  createTargetingQuery,
  UpdateTargetingQueryRequest,
  updateTargetingQuery,
  targetingQueryCanceled,
} from "../store/modules/adSet";
import Toggle from "../lib/Toggle";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDisptchToProps>;

interface State {
  showModal: boolean;
  focusedTargetingQueryId: string;
  name: string;
  query: string;
  expanded: boolean;
  executedQuery: string;
  executedAsExpanded: boolean;
  intervalId?: NodeJS.Timeout;
}

class TargetingQueryContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      focusedTargetingQueryId: "",
      name: "",
      query: "",
      expanded: false,
      executedQuery: "",
      executedAsExpanded: false,
    };
    this.handleClickNewTargetingQuery = this.handleClickNewTargetingQuery.bind(
      this
    );
    this.handleClickCancelButton = this.handleClickCancelButton.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeExpandedToggle = this.handleChangeExpandedToggle.bind(
      this
    );
    this.handleClickDoneButton = this.handleClickDoneButton.bind(this);
    this.handleClickCalculateButton = this.handleClickCalculateButton.bind(
      this
    );
    this.fetchQueryJobResultByPolling = this.fetchQueryJobResultByPolling.bind(
      this
    );
  }

  public componentDidMount() {
    this.props.fetchTargetingQueries();
    const intervalId = this.fetchQueryJobResultByPolling();
    this.setState({ intervalId });
  }

  public componentWillUnmount() {
    if (!!this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  public render() {
    const { idTargetingQuery } = this.props;
    return (
      <Container>
        <TargetingQueryModal
          isOpen={this.state.showModal}
          ariaHideApp={false}
          style={{
            overlay: {
              position: "fixed",
              backgroundColor: "rgba(224, 224, 224, 0.64)",
            },
            content: {
              position: "absolute",
              width: "650px",
              height: "600px",
              borderRadius: "4px",
              top: "153px",
              left: "400px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              outline: "none",
            },
          }}
        >
          <NameInput
            name="name"
            type="text"
            value={this.state.name}
            placeholder="Query Name"
            onChange={this.handleChangeName}
          />
          <QueryTextArea
            name="query"
            value={this.state.query}
            onChange={this.handleChangeQuery}
          />
          {!!this.props.queryJobErrorMessage && (
            <ErrorMessageWrapper>
              {this.props.queryJobErrorMessage}
            </ErrorMessageWrapper>
          )}
          <ToggleWrapper>
            <ExpandedLabel>
              <ExpandedSpan>Expanded</ExpandedSpan>
              <ExpandedToggle
                checked={this.state.expanded}
                icons={false}
                onChange={this.handleChangeExpandedToggle}
              />
            </ExpandedLabel>
          </ToggleWrapper>
          <CalculationWrapper>
            <CalculateButton onClick={this.handleClickCalculateButton}>
              Calculate
            </CalculateButton>
          </CalculationWrapper>
          {this.props.executingQuery ? (
            <LoaderWrapper>
              <Loader src="/loader-circle.gif" alt="loader" />
            </LoaderWrapper>
          ) : (
            <TargetingUsersText>
              {this.props.targetingCount
                ? `${this.props.targetingCount}`
                : "NOT CALCULATED"}
            </TargetingUsersText>
          )}
          <TargetingUsersHeader>Targeting Users</TargetingUsersHeader>
          <ButtonWrapper>
            <CancelButton onClick={this.handleClickCancelButton}>
              Cancel
            </CancelButton>
            <DoneButton
              onClick={this.handleClickDoneButton}
              disabled={!this.props.canSubmit}
            >
              Done
            </DoneButton>
          </ButtonWrapper>
        </TargetingQueryModal>
        <HeadWrapper>
          <Head>Targeting Queries</Head>
          <NewTargetingQueryButton onClick={this.handleClickNewTargetingQuery}>
            New Query
          </NewTargetingQueryButton>
        </HeadWrapper>
        <TargetingQueryList>
          {this.props.targetingQueryIds.map(targetingQueryId => (
            <TargetingQuery key={targetingQueryId}>
              <Name>{idTargetingQuery[targetingQueryId].name}</Name>
              <TargetingCount>
                {!!idTargetingQuery[targetingQueryId] &&
                  !!idTargetingQuery[targetingQueryId].targetingCount &&
                  idTargetingQuery[
                    targetingQueryId
                  ].targetingCount.toLocaleString()}
              </TargetingCount>
              <PencilIcon
                name={"wt-icon-pencil"}
                onClick={() => this.handleClickPencil(targetingQueryId)}
              />
            </TargetingQuery>
          ))}
        </TargetingQueryList>
      </Container>
    );
  }

  private handleClickNewTargetingQuery() {
    this.setState({ showModal: true });
  }

  private handleClickPencil(targetingQueryId: string) {
    const { name, query, expanded } = this.props.idTargetingQuery[
      targetingQueryId
    ];
    this.setState({
      focusedTargetingQueryId: targetingQueryId,
      name,
      query,
      expanded,
      showModal: true,
    });
  }

  private handleClickCalculateButton() {
    if (!this.state.query) {
      return;
    }

    this.setState({
      executedQuery: this.state.query,
      executedAsExpanded: this.state.executedAsExpanded,
    });
    this.props.fetchTargetingCount({
      query: this.state.query,
      expanded: this.state.expanded,
    });
  }

  private fetchQueryJobResultByPolling() {
    return setInterval(() => {
      if (!this.props.executingQuery || this.props.queryJobId === "") {
        return;
      }

      this.props.fetchQueryJobResult({ jobId: this.props.queryJobId });
    }, 5000);
  }

  private handleClickCancelButton() {
    this.props.cancelTargetingQuery();
    this.setState({
      name: "",
      showModal: false,
      focusedTargetingQueryId: "",
      query: "",
      expanded: false,
    });
  }

  private handleClickDoneButton() {
    const {
      focusedTargetingQueryId: id,
      name,
      executedAsExpanded: expanded,
      executedQuery: query,
    } = this.state;
    const { targetingCount } = this.props;
    if (id) {
      this.props.updateTargetingQuery({
        id,
        name,
        expanded,
        query,
        targetingCount,
      });
    } else {
      this.props.createTargetingQuery({
        name,
        expanded,
        query,
        targetingCount,
      });
    }
    this.setState({
      focusedTargetingQueryId: "",
      name: "",
      showModal: false,
      expanded: false,
      query: "",
      executedQuery: "",
      executedAsExpanded: false,
    });
  }

  private handleChangeName(e: React.FormEvent<HTMLInputElement>) {
    this.setState({ name: e.currentTarget.value });
  }

  private handleChangeQuery(e: React.FormEvent<HTMLTextAreaElement>) {
    this.setState({ query: e.currentTarget.value });
  }

  private handleChangeExpandedToggle() {
    this.setState({ expanded: !this.state.expanded });
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const {
    targetingQueries: idTargetingQuery,
    targetingQueryIds,
    targetingCount,
    executingQuery,
    queryJobId,
    queryJobErrorMessage,
  } = state.adSet;
  const { canSubmit } = state.ui.targetingQueries;

  return {
    idTargetingQuery,
    targetingQueryIds,
    targetingCount,
    canSubmit,
    executingQuery,
    queryJobId,
    queryJobErrorMessage,
  };
}

function mapDisptchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchTargetingQueries: () => fetchTargetingQueries.started(),
      fetchTargetingCount: (params: TargetingCountRequest) =>
        fetchTargetingCount.started(params),
      fetchQueryJobResult: (params: FetchQueryJobResultRequest) =>
        fetchQueryJobResult.started(params),
      createTargetingQuery: (params: CreateTargetingQueryRequest) =>
        createTargetingQuery.started(params),
      updateTargetingQuery: (params: UpdateTargetingQueryRequest) =>
        updateTargetingQuery.started(params),
      cancelTargetingQuery: () => targetingQueryCanceled({}),
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDisptchToProps
)(TargetingQueryContainer);

const Container = styled.div`
  width: 640px;
  margin: 72px auto auto 272px;
`;

const TargetingQueryModal = styled(ReactModal)``;

const HeadWrapper = styled.div`
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Head = styled.div`
  ${heading}
  background-color: var(--gray25);
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.84);
`;

const NewTargetingQueryButton = styled.button`
  ${heading}
  font-size: 14px;
  line-height: 1.71;
  border-radius: 14px;
  color: #ffffff;
  width: 136px;
  height: 40px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  cursor: pointer;
`;

const NameInput = styled.input`
  ${heading}
  width: 536px;
  font-size: 20px;
  line-height: 1.4;
  width: 560px;
  height: 44px;
  border: none;
  border-radius: 22px;
  text-indent: 16px;
  margin: 46px 40px auto;
  color: ${colors.blackForeground840};
  background-color: rgba(0, 0, 0, 0.03);
`;

const ErrorMessageWrapper = styled.div`
  margin: 5px 40px auto;
  color: red;
  font-weight: bold;
`;

// FIXME: right-justify
const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  width: 560px;
  margin: 10px auto auto;
`;

const ExpandedSpan = styled.span`
  margin-right: 10px;
`;

const ExpandedLabel = styled.label`
  display: flex;
`;

const ExpandedToggle = styled(Toggle)``;

const QueryTextArea = styled.textarea`
  width: 560px;
  height: 236px;
  font-size: 16px;
  line-height: 1.75;
  border: none;
  border-radius: 22px;
  padding-left: 16px;
  margin: 15px 40px auto;
  color: ${colors.blackForeground840};
  background-color: rgba(0, 0, 0, 0.03);
`;

const CalculationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 18px 20px 18px auto;
`;

const CalculateButton = styled.button`
  ${heading}
  width: 135px;
  height: 36px;
  border-radius: 18px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  line-height: 1.71;
  color: white;
  cursor: pointer;
`;

const LoaderWrapper = styled.div`
  text-align: right;
  height: 30px;
  margin: 10px 40px auto auto;
`;

const Loader = styled.img`
  width: 30px;
  height: 30px;
  opacity: 0.4;
`;

const TargetingUsersText = styled.div`
  ${heading}
  font-size: 24px;
  line-height: 1.29;
  color: ${colors.blackForeground840};
  margin: 10px 40px auto auto;
  text-align: right;
`;

const TargetingUsersHeader = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 1.71;
  margin-right: 40px;
  color: ${colors.blackForeground840};
  text-align: right;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 36px 20px 18px auto;
`;

const CancelButton = styled.button`
  ${heading}
  width: 88px;
  height: 36px;
  border-radius: 18px;
  margin-right: 8px;
  color: ${colors.blackForeground600};
  background-color: var(--white);
  cursor: pointer;
`;

const DoneButton = styled.button<{ disabled: boolean }>`
  ${heading}
  width: 135px;
  height: 36px;
  border-radius: 18px;
  line-height: 1.71;
  color: white;
  ${props =>
    props.disabled
      ? css`
          background-color: #ccc;
        `
      : css`
          background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
          cursor: pointer;
        `}
`;

const TargetingQueryList = styled.div`
  margin-top: 45px;
`;

const TargetingQuery = styled.div`
  min-height: 47px;
  padding: 12px 16px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const Name = styled.div`
  ${heading}
  font-size: 14px;
  line-height: 1.71;
  color: ${colors.blackForeground800};
  width: 500px;
`;

const TargetingCount = styled.div`
  ${heading}
  width: 63px;
  text-align: right;
  font-size: 14px;
  line-height: 1.71;
  color: ${colors.blackForeground800};
`;

const PencilIcon = styled(Icon)`
  font-size: 15.5px;
`;
