import { createSelector } from "reselect";

import { RootState } from "../modules";

export const linkListsSelector = (state: RootState) => state.linkList.linkLists;

export const linkListSelectorFactory = (uuid: string) => (state: RootState) => {
  return state.linkList.linkLists[uuid];
};

export const linkUuidsSelectorFactory = (uuid: string) => (
  state: RootState
) => {
  return state.linkList.linkLists[uuid].links;
};

export const contentEntryFromLinkListSelectorFactory = (uuid: string) => (
  state: RootState
) => {
  const { focusedSceneEntryId } = state.ui.articles;
  const sceneEntry = state.sceneEntry.sceneEntries.find(
    se => se.id === focusedSceneEntryId
  );
  if (!sceneEntry) {
    return null;
  }
  const contentEntry = sceneEntry.contentEntries.find(
    ce => ce.content === uuid
  );
  return contentEntry;
};

export const subjectsSelector = (state: RootState) => state.linkList.subjects;

export const subjectSelectorFactory = (linkListUuid: string) =>
  createSelector(
    subjectsSelector,
    linkListSelectorFactory(linkListUuid),
    (subjects, linkList) =>
      linkList && linkList.subjectId ? subjects[linkList.subjectId] : null
  );
