import React, { useEffect } from "react";
import styled from "styled-components";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import uuid from "uuid";

import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  sceneEntriesSelector,
  maxDraftSceneEntryIdSelector,
} from "../../store/selectors/sceneEntries";
import {
  fetchSceneEntries,
  addSceneEntry,
  SceneEntry,
} from "../../store/modules/sceneEntry";
import {
  dragLinkListItem,
  DragLinkListItemParams,
} from "../../store/modules/linkList";

import SceneEntryContainer from "./SceneEntryContainer";
import PublishButtonContainer from "./PublishButtonContainer";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function SceneEntriesContainer(props: Props) {
  // props & state
  // -----------------------------------------------

  const { sceneEntries, maxDraftSceneEntryId } = props;

  //  hooks
  // -----------------------------------------------

  useEffect(() => {
    if (sceneEntries.length <= 0) {
      props.fetchSceneEntries();
    }
  }, []);

  //  functions
  // -----------------------------------------------

  const handleClickAddScene = () => {
    const sceneEntry: SceneEntry = {
      id: `DRAFT_ID:${maxDraftSceneEntryId}`,
      sceneEvent: {
        uuid: uuid(),
        headline: "",
        badge: "",
      },
      createdAt: null,
      updatedAt: null,
      contentEntries: [],
    };
    props.addSceneEntry({ sceneEntry });
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      {sceneEntries.map(se => (
        <SceneEntryContainer key={se.id} sceneEntry={se} />
      ))}
      <AddScene onClick={handleClickAddScene}>+ Add Scene</AddScene>
      <PublishButtonContainer />
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SceneEntriesContainer);

function mapStateToProps(state: RootState) {
  return {
    sceneEntries: sceneEntriesSelector(state),
    maxDraftSceneEntryId: maxDraftSceneEntryIdSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchSceneEntries: () => fetchSceneEntries.started(),
      addSceneEntry: ({ sceneEntry }: { sceneEntry: SceneEntry }) =>
        addSceneEntry({ sceneEntry }),
      draglinklistItem: ({
        linkListUuid,
        startIndex,
        endIndex,
      }: DragLinkListItemParams) =>
        dragLinkListItem({ linkListUuid, startIndex, endIndex }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  padding: 24px 8px;
`;

const AddScene = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.blackForeground600};
  margin-bottom: 72px;
  cursor: pointer;
`;
