import { RootState } from "../modules";

export const sceneEntriesSelector = (state: RootState) => {
  return state.sceneEntry.sceneEntries.sort((a, b) =>
    a.id.toString() > b.id.toString() ? 1 : -1
  );
};

export const lastPublishedAtSelector = (state: RootState) =>
  state.sceneEntry.lastPublishedAt;

export const maxDraftContentEntryIdSelector = (state: RootState) =>
  state.sceneEntry.maxContentEntryId;

export const maxDraftSceneEntryIdSelector = (state: RootState) =>
  state.sceneEntry.maxSceneEntryId;
