import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import DatePicker from "antd/lib/date-picker";
import TimePicker from "antd/lib/time-picker";
import moment from "moment";

import colors from "../styles/color";
import { heading } from "../styles/font";
import Input from "../lib/Input";
import Icon from "../lib/Icon";
import { RootState } from "../store/modules";
import {
  linkListSelectorFactory,
  contentEntryFromLinkListSelectorFactory,
} from "../store/selectors/linkList";
import { deleteLinkList } from "../store/modules/linkList";
import { focusedSceneEntryIdSelector } from "../store/selectors/ui";
import {
  UpdateLinkListParams,
  updateLinkList,
} from "../store/modules/sceneEntry";

import LinkListEditHeader from "./LinkList/LinkListEditHeader";
import SubjectSelectContainer from "./LinkList/SubjectSelectContainer";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

function LinkListContainer(props: Props) {
  //  hooks
  // -----------------------------------------------

  const { deliverableFrom, deliverableUntil } = props.contentEntry || {};

  const [startDate, setStartDate] = useState(
    deliverableFrom ? moment(deliverableFrom) : null
  );
  const [endDate, setEndDate] = useState(
    deliverableUntil ? moment(deliverableUntil) : null
  );

  useEffect(() => {
    setStartDate(deliverableFrom ? moment(deliverableFrom) : null);
    setEndDate(deliverableUntil ? moment(deliverableUntil) : null);
  }, [deliverableFrom, deliverableUntil]);

  useEffect(() => {
    const range = {
      deliverableFrom: startDate && startDate.toISOString(),
      deliverableUntil: endDate && endDate.toISOString(),
    };
    props.updateLinkList({ linkList, sceneEntryId, range });
  }, [startDate, endDate]);

  //  props & state
  // -----------------------------------------------

  if (!props.match) {
    return null;
  }
  const { uuid } = props.match.params as { uuid: string };
  if (!uuid) {
    return null;
  }
  const { linkList, contentEntry, focusedSceneEntryId: sceneEntryId } = props;
  if (!linkList) {
    return null;
  }
  if (!sceneEntryId) {
    return <Redirect to="/admin/articles/links" />;
  }

  const dateFormat = "YYYY/MM/DD";
  const timeFormat = "HH:mm";

  if (!contentEntry) {
    return null;
  }

  //  functions
  // -----------------------------------------------

  const handleChangeLinkList = (e: React.FormEvent<HTMLInputElement>) => {
    const { linkList, focusedSceneEntryId: sceneEntryId } = props;
    const { name, value } = e.currentTarget;
    const editedLinkList = { ...linkList, [name]: value };
    props.updateLinkList({ linkList: editedLinkList, sceneEntryId });
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      <LinkListEditHeader
        title="LinkList"
        content={linkList}
        onDelete={props.deleteLinkList}
      />
      <form>
        <Section>
          <SubHeader>Header</SubHeader>
          <SubjectSelectContainer
            sceneEntryId={sceneEntryId}
            linkList={linkList}
          />
        </Section>
        <Section>
          <SubHeader>Body Message</SubHeader>
          <Input
            onChange={handleChangeLinkList}
            name="heading"
            value={linkList.heading}
            placeholder="Description"
          />
        </Section>
        <Section>
          <SubHeader>Settings</SubHeader>
          <DateInputs>
            <CalIcon name="wt-icon-calendar" />
            <StyledDatePicker
              format={dateFormat}
              value={startDate || undefined}
              onChange={time => {
                setStartDate(time);
              }}
            />
            <StyledTimePicker
              format={timeFormat}
              value={startDate || undefined}
              onChange={time => {
                setStartDate(time);
              }}
            />
            <To>to</To>
            <StyledDatePicker
              format={dateFormat}
              value={endDate || undefined}
              onChange={time => {
                setEndDate(time);
              }}
            />
            <StyledTimePicker
              format={timeFormat}
              value={endDate || undefined}
              onChange={time => {
                setEndDate(time);
              }}
            />
          </DateInputs>
        </Section>
      </form>
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LinkListContainer)
);

function mapStateToProps(state: RootState, props: RouteComponentProps) {
  if (!props.match) {
    return null;
  }
  const { uuid } = props.match.params as { uuid: string };
  if (!uuid) {
    return null;
  }
  return {
    linkList: linkListSelectorFactory(uuid)(state),
    contentEntry: contentEntryFromLinkListSelectorFactory(uuid)(state),
    focusedSceneEntryId: focusedSceneEntryIdSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateLinkList: ({
        linkList,
        sceneEntryId,
        range,
      }: UpdateLinkListParams) =>
        updateLinkList({ linkList, sceneEntryId, range }),
      deleteLinkList: (uuid: string) => deleteLinkList({ uuid }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  width: 640px;
  margin: 72px auto 0;
`;

const Section = styled.section`
  margin-top: 32px;
`;

const SubHeader = styled.div`
  ${heading}
  font-size: 20px;
  line-height: 1.4;
  color: ${colors.blackForeground840};
  padding: 0 16px;
  margin-bottom: 16px;
`;

const DateInputs = styled.div``;

const CalIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  margin-left: 16px;
  margin-right: 16px;
`;

const To = styled.span`
  font-size: 16px;
  line-height: 1.75;
  color: ${colors.blackForeground600};
  padding-right: 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 132px;
  margin-right: 8px !important;
  & .ant-calendar-picker-input {
    background-color: #f7f7f7;
    border-style: none;
    border-radius: 4px;
    color: ${colors.blackForeground840};
    &:focus {
      background-color: #f0f0f0;
    }
    &:placeholder {
      color: ${colors.blackForeground400};
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  width: 132px;
  margin-right: 8px;
  & .ant-time-picker-input {
    background-color: #f7f7f7;
    border-style: none;
    border-radius: 4px;
    color: ${colors.blackForeground840};
    &:focus {
      background-color: #f0f0f0;
    }
    &:placeholder {
      color: ${colors.blackForeground400};
    }
  }
`;
