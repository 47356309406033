import React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { produce } from "immer";

import colors from "../styles/color";
import { RootState } from "../store/modules";
import { linkSelectorFactory } from "../store/selectors/link";
import { focusedLinkListUuidSelector } from "../store/selectors/ui";
import { changeLink, ChangeLinkParams } from "../store/modules/link";
import {
  DeleteLinkListItemParams,
  deleteLinkListItem,
} from "../store/modules/linkList";
import Input from "../lib/Input";
import Icon from "../lib/Icon";

import LinkEditHeader from "./Link/LinkEditHeader";

interface OwnProps {
  uuid: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps &
  RouteComponentProps;

function LinkEditContainer(props: Props) {
  //  props & state
  // -----------------------------------------------

  const { link } = props;

  //  functions
  // -----------------------------------------------

  const handleChangeLink = (e: React.FormEvent<HTMLInputElement>) => {
    const { link: link_, focusedLinkListUuid: linkListUuid } = props;
    const { name, value } = e.currentTarget;
    const link = produce(link_, link => {
      link[name] = value;
    });
    props.changeLink({ link, linkListUuid });
  };

  const handleChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const elm = e.currentTarget;
    if (!elm || !elm.files) {
      return;
    }
    const file = elm.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      props.changeLink({ link, rawImage: (event.target as any).result });
    };
  };

  const onDeleteLinkListItem = () => {
    const { focusedLinkListUuid: linkListUuid, link } = props;
    props.deleteLinkListItem({ linkListUuid, linkUuid: link.uuid });
  };

  //  jsx
  // -----------------------------------------------

  if (!link) {
    return null;
  }

  return (
    <Container>
      {props.match.path === "/admin/articles/link_list_items/:uuid/edit" ? (
        <LinkEditHeader
          title="Link"
          content={link}
          onDelete={onDeleteLinkListItem}
        />
      ) : (
        <LinkEditHeader title="Link" content={link} />
      )}
      <form>
        <Url>
          <InputWrapper>
            <Input
              placeholder="URL"
              name="url"
              value={link.url || ""}
              onChange={handleChangeLink}
            />
            <Jump href={link.url} target="_blank">
              <StyledIcon name="wt-icon-jump" />
            </Jump>
          </InputWrapper>
        </Url>
        <Title>
          <Input
            placeholder="Article Title"
            name="title"
            value={link.title || ""}
            onChange={handleChangeLink}
          />
        </Title>
        <Media>
          <Input
            placeholder="Media"
            name="media"
            value={link.media || ""}
            onChange={handleChangeLink}
          />
        </Media>
        <ImageUploadWrapper>
          <Label htmlFor="upload-image" imageUrl={link.imageUrl || ""}>
            {link.imageUrl ? null : <CameraIcon name="wt-icon-camera" />}
            <ImageUploadInput
              id="upload-image"
              type="file"
              onChange={handleChangeInput}
            />
          </Label>
          <Description>Drop an image here</Description>
        </ImageUploadWrapper>
      </form>
    </Container>
  );
}
//  connect
// -----------------------------------------------

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LinkEditContainer)
);

function mapStateToProps(state: RootState, props: OwnProps) {
  const { uuid } = props;
  return {
    link: linkSelectorFactory(uuid)(state),
    focusedLinkListUuid: focusedLinkListUuidSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      changeLink: ({ link, rawImage, linkListUuid }: ChangeLinkParams) =>
        changeLink.started({ link, rawImage, linkListUuid }),
      deleteLinkListItem: ({
        linkListUuid,
        linkUuid,
      }: DeleteLinkListItemParams) =>
        deleteLinkListItem({ linkListUuid, linkUuid }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

interface LabelStyle {
  imageUrl: string;
}

const Container = styled.div`
  width: 640px;
  margin: 72px auto 0;
`;

const Url = styled.div`
  padding-bottom: 28px;
`;

const Title = styled.div`
  padding-bottom: 12px;
`;

const Media = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Jump = styled.a`
  text-decoration: none;
  margin-left: 12px;
  width: 18px;
  height: 18px;
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: ${colors.blackForeground400};
`;

const ImageUploadWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 4px;
  color: ${colors.blackForeground400};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props: LabelStyle) =>
    props.imageUrl
      ? css`
          background-image: url(${props.imageUrl});
          background-size: 100% 100%;
        `
      : css`
          background-color: rgba(0, 0, 0, 0.03);
        `}
`;

const ImageUploadInput = styled.input`
  &[type="file"] {
    display: none;
  }
`;

const CameraIcon = styled(Icon)`
  position: absolute;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: ${colors.blackForeground400};
`;
const Description = styled.span`
  margin-left: 16px;
  font-size: 14px;
  color: ${colors.blackForeground400};
`;
