import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import Icon from "../../lib/Icon";
import colors from "../../styles/color";
import { searchLinks } from "../../store/modules/link";

type Props = ReturnType<typeof mapDispatchToProps>;

function SearchBoxContainer(props: Props) {
  //  functions
  // -----------------------------------------------

  const handleOnKeyDown = (
    e: React.KeyboardEvent & React.FormEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      props.searchLinks(e.currentTarget.value);
    }
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      <IconWrapper>
        <SearchIcon name="wt-icon-search" />
      </IconWrapper>
      <SearchInput
        onKeyDown={handleOnKeyDown}
        type="text"
        placeholder="Select Articles or Specify URL"
      />
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  null,
  mapDispatchToProps
)(SearchBoxContainer);

// function mapStateToProps(state: RootState) {}
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      searchLinks: (word: string) => searchLinks.started({ word }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 56px;
`;

const SearchIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  cursor: pointer;
`;

const SearchInput = styled.input`
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 40px;
  outline: none;
  border-style: none;
  font-size: 16px;
  color: #000;
  &::placeholder {
    color: ${colors.blackForeground400};
  }
  border-radius: 20px;
  padding-left: 56px;
`;
