import { applyMiddleware, createStore, Store, compose } from "redux";
import sagaMiddlewareFactory from "redux-saga";
import { createLogger } from "redux-logger";

import { rootReducer, RootState } from "./modules";
import appSaga from "./sagas";

export default function configureStore(): Store<RootState> {
  const sagaMiddleware = sagaMiddlewareFactory();
  const logger = createLogger({
    diff: true,
  });
  const middlewares = [sagaMiddleware, logger];
  const composeEnhancers =
    process.env.REACT_APP_NODE_ENV !== "production" &&
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(appSaga);

  return store;
}
