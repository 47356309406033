import React from "react";
import styled from "styled-components";

import Icon from "../../lib/Icon";
import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { LinkList } from "../../store/modules/linkList";
import { history } from "../../App";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  content: LinkList;
  onDelete: (...args: any) => void;
}

function LinkListEditHeader(props: Props) {
  const { title, content } = props;

  //  functions
  // -----------------------------------------------

  const handleClickTrashIcon = (props: Props) => {
    const { content } = props;
    props.onDelete(content.uuid);
    history.push("/admin/articles/links");
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Header>
      <HeaderMain>
        <Title>{title}</Title>
        <HeaderMeta>
          <Id>
            UUID:
            <a
              href={`https://people.wantedly.com/updates/contents/${
                content.uuid
              }`}
            >
              {content.uuid}
            </a>
          </Id>
          <Dates>
            <CreatedAt>
              Created :{" "}
              {content.createdAt
                ? new Date(content.createdAt).toLocaleString("ja")
                : ""}
            </CreatedAt>
            <UpdatedAt>
              Edited :{" "}
              {content.updatedAt
                ? new Date(content.updatedAt).toLocaleString("ja")
                : ""}
            </UpdatedAt>
          </Dates>
        </HeaderMeta>
      </HeaderMain>
      <TrashIcon
        onClick={() => handleClickTrashIcon(props)}
        name="wt-icon-trash"
      />
    </Header>
  );
}

export default LinkListEditHeader;
//  style
// -----------------------------------------------

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 32px;
`;

const HeaderMain = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  ${heading}
  font-size: 32px;
  color: ${colors.blackForeground840};
`;

const HeaderMeta = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
`;

const Id = styled.div`
  margin-bottom: 2px;
`;

const Dates = styled.div`
  display: flex;
  margin-top: 2px;
`;

const CreatedAt = styled.div``;
const UpdatedAt = styled.div`
  margin-left: 8px;
`;

const SubHeader = styled.div`
  ${heading}
  font-size: 20px;
  line-height: 1.4;
  color: ${colors.blackForeground840};
  padding: 0 16px;
  margin-bottom: 16px;
`;

const TrashIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: ${colors.blackForeground400};
  cursor: pointer;
`;
