import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { heading } from "../styles/font";
import colors from "../styles/color";

class AdSettingsContainer extends React.Component<{}> {
  public render() {
    return (
      <Container>
        <Head>Settings</Head>
        <AdAccountsLink to="/admin/ad/ad_settings/ad_accounts">
          Ad Accounts
        </AdAccountsLink>
        <TargetingQueryLink to="/admin/ad/ad_settings/targeting_queries">
          TargetingQueries
        </TargetingQueryLink>
      </Container>
    );
  }
}

export default AdSettingsContainer;

const Container = styled.div`
  margin-top: 70px;
  margin-left: 288px;
`;

const Head = styled.div`
  ${heading}
  font-size: 32px;
  line-height: 1.25;
  color: ${colors.blackForeground840};
`;

const AdAccountsLink = styled(Link)`
  ${heading}
  font-size: 20px;
  line-height: 1.4;
  margin-top: 44px;
  color: ${colors.blackForeground840};
  display: block;
`;

const TargetingQueryLink = styled(Link)`
  ${heading}
  font-size: 20px;
  line-height: 1.4;
  margin-top: 29px;
  color: ${colors.blackForeground840};
  display: block;
`;
