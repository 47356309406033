import React from "react";
import styled, { css } from "styled-components";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Link as LinkTag } from "react-router-dom";
import { Droppable } from "react-beautiful-dnd";

import { RootState } from "../../store/modules";
import { addLinkListItem } from "../../store/modules/link";
import { heading } from "../../styles/font";
import colors from "../../styles/color";
import { ContentEntry } from "../../store/modules/sceneEntry";
import LinkListItemsContainer from "../LinkList/LinkListItemsContainer";
import {
  linkListSelectorFactory,
  subjectSelectorFactory,
} from "../../store/selectors/linkList";
import {
  linkListSelected,
  LinkListSelectedParams,
} from "../../store/modules/ui";
import { focusedLinkListUuidSelector } from "../../store/selectors/ui";

import SceneHeader from "./SceneHeader";

interface OwnProps {
  contentEntry: ContentEntry;
  sceneEntryId: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

function ContentEntryContainer(props: Props) {
  //  props & state
  // -----------------------------------------------

  const {
    linkList,
    focusedLinkListUuid,
    sceneEntryId,
    subject,
    contentEntry,
  } = props;
  if (!linkList) {
    return null;
  }

  // functions
  // -----------------------------------------------

  const handleClickLinkItem = () => {
    const { uuid: linkListUuid } = linkList;
    props.linkListSelected({ sceneEntryId, linkListUuid });
  };

  const handleClickAddLink = () => {
    const { uuid: linkListUuid } = linkList;
    props.linkListSelected({ sceneEntryId, linkListUuid });

    // const link = {
    //   uuid: uuid(),
    //   url: "",
    //   media: "",
    //   title: "",
    //   imageUrl: "",
    // } as Link;
    props.linkAdded({ linkListUuid: linkList.uuid });
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Droppable droppableId={linkList.uuid}>
      {(provided, _snapshot) => (
        <Card
          ref={provided.innerRef}
          isSelected={linkList.uuid === focusedLinkListUuid}
          {...provided.droppableProps}
        >
          <StyledLink
            to={`/admin/articles/link_lists/${linkList.uuid}/edit`}
            onClick={handleClickLinkItem}
          >
            <HeaderWrapper>
              <SceneHeader subject={subject} />
              {contentEntry.id.toString().startsWith("DRAFT") && (
                <UnpublishedBatch />
              )}
            </HeaderWrapper>
            <BodyMessage>{linkList.heading}</BodyMessage>
          </StyledLink>
          <LinkListItemsContainer
            key={linkList.uuid}
            linkListUuid={linkList.uuid}
            sceneEntryId={sceneEntryId}
          />
          <StyledLink to={"/admin/articles/links"} onClick={handleClickAddLink}>
            <AddLink>+ Add Link</AddLink>
          </StyledLink>
        </Card>
      )}
    </Droppable>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentEntryContainer);

function mapStateToProps(state: RootState, props: OwnProps) {
  const { contentUuid: uuid } = props.contentEntry;
  return {
    linkList: linkListSelectorFactory(uuid)(state),
    focusedLinkListUuid: focusedLinkListUuidSelector(state),
    subject: subjectSelectorFactory(uuid)(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      linkAdded: ({ linkListUuid }: { linkListUuid: string }) =>
        addLinkListItem.started({ linkListUuid }),
      linkListSelected: ({
        linkListUuid,
        sceneEntryId,
      }: LinkListSelectedParams) =>
        linkListSelected({ linkListUuid, sceneEntryId }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

interface CardStyle {
  isSelected: boolean;
}

const Card = styled.div`
  width: 343px;
  padding: 18px 0;
  margin: 0 auto 24px;
  background-color: ${colors.white};
  border-radius: 20px;
  ${(props: CardStyle) =>
    props.isSelected
      ? css`
          box-shadow: 0 0 0pt 8pt ${colors.blackForeground50};
        `
      : css`
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02),
            0 8px 16px 0 rgba(0, 0, 0, 0.1);
        `}
`;

const StyledLink = styled(LinkTag)`
  text-decoration: none;
  color: #fff;
  &:hover,
  a:hover {
    color: #fff;
    text-decoration: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnpublishedBatch = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 16px;
  background-color: ${colors.blue400};
`;

const BodyMessage = styled.div`
  margin: 16px 0;
  padding: 0 16px;
  ${heading}
  font-size: 18px;
  line-height: 1.56;
  color: ${colors.blackForeground840};
`;

const AddLink = styled.div`
  ${heading}
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${colors.blackForeground600};
  margin-top: 12px;
  padding: 0 16px;
  cursor: pointer;
`;
