import React from "react";
import styled from "styled-components";

import { heading } from "../../styles/font";
import colors from "../../styles/color";
import { OfficialSubject } from "../../store/modules/linkList";

interface Props {
  subject: OfficialSubject | null;
}

function SceneHeader(props: Props) {
  //  props
  // -----------------------------------------------

  const { subject } = props;
  if (!subject) {
    return null;
  }

  //  style
  // -----------------------------------------------

  return (
    <Container>
      <AvatarImage src={subject.avatarUrl} />
      <Reason>
        <Name>{subject.name}</Name>
        <Info>{subject.shortDescription}</Info>
      </Reason>
    </Container>
  );
}

export default SceneHeader;

//  style
// -----------------------------------------------

const Container = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const AvatarImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 1;
`;

const Reason = styled.div`
  margin-left: 16px;
`;

const Name = styled.div`
  ${heading};
  font-size: 14px;
  line-height: 1.43;
  color: ${colors.blackForeground700};
`;

const Info = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground600};
`;
