import { all, call, put, takeLatest, fork } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import * as updatesContentsAdApi from "../../utils/api/updatesContentsAdApi";
import {
  AdSetSummaryResponse,
  fetchAdSetSummary,
  UpdateAdSetRequest,
  UpdateAdSetResponse,
  updateAdSet,
  CreateAdSetRequest,
  CreateAdSetResponse,
  createAdSet,
  fetchTargetingQueries,
  fetchTargetingQueryNames,
  TargetingQuery,
  TargetingCountRequest,
  TargetingCountResponse,
  fetchTargetingCount,
  FetchQueryJobResultRequest,
  FetchQueryJobResultResponse,
  fetchQueryJobResult,
  CreateTargetingQueryRequest,
  CreateTargetingQueryResponse,
  createTargetingQuery,
  UpdateTargetingQueryRequest,
  UpdateTargetingQueryResponse,
  updateTargetingQuery,
  FetchTargetingQueriesResponse,
} from "../modules/adSet";
import { adSetCreated } from "../modules/adCampaign";
import { history } from "../../App";

export function* handleAdSetSummary(
  action: Action<{ adAccountId: string; adCampaignId: string; adSetId: string }>
) {
  const { adAccountId, adCampaignId, adSetId } = action.payload;
  try {
    const res: AdSetSummaryResponse = yield call(
      updatesContentsAdApi.fetchAdSetSummary({
        adAccountId,
        adCampaignId,
        adSetId,
      })
    );
    yield put(
      fetchAdSetSummary.done({
        params: {
          adAccountId,
          adCampaignId,
          adSetId,
        },
        result: res,
      })
    );
  } catch (e) {
    yield put(
      fetchAdSetSummary.failed({
        params: {
          adAccountId,
          adCampaignId,
          adSetId,
        },
        error: e,
      })
    );
  }
}

export function* handleCreateAdSet(action: Action<CreateAdSetRequest>) {
  try {
    const res: CreateAdSetResponse = yield call(
      updatesContentsAdApi.createAdSet(action.payload)
    );
    const { adAccountId } = action.payload;
    const { id, adCampaignId } = res;

    yield put(
      createAdSet.done({
        params: action.payload,
        result: res,
      })
    );
    yield put(
      adSetCreated({
        adSetId: id,
        adCampaignId,
      })
    );
    history.push(
      `/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/ad_sets/${id}/summary`
    );
  } catch (e) {
    yield put(
      createAdSet.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleUpdateAdSet(action: Action<UpdateAdSetRequest>) {
  try {
    const res: UpdateAdSetResponse = yield call(
      updatesContentsAdApi.updateAdSet(action.payload)
    );

    yield put(
      updateAdSet.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      updateAdSet.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleFetchTargetingQueries() {
  try {
    const res: FetchTargetingQueriesResponse[] = yield call(
      updatesContentsAdApi.fetchTargetingQueries()
    );

    yield put(
      fetchTargetingQueries.done({
        result: res,
      })
    );
  } catch (e) {
    yield put(
      fetchTargetingQueries.failed({
        error: e,
      })
    );
  }
}

export function* handleFetchTargetingQueryNames() {
  try {
    const res: TargetingQuery[] = yield call(
      updatesContentsAdApi.fetchTargetingQueryNames()
    );

    yield put(
      fetchTargetingQueryNames.done({
        result: res,
      })
    );
  } catch (e) {
    yield put(
      fetchTargetingQueryNames.failed({
        error: e,
      })
    );
  }
}

export function* handleFetchTargetingCount(
  action: Action<TargetingCountRequest>
) {
  try {
    const res: TargetingCountResponse = yield call(
      updatesContentsAdApi.fetchTargetingCount(action.payload)
    );

    yield put(
      fetchTargetingCount.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      fetchTargetingCount.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleFetchQueryJobResult(
  action: Action<FetchQueryJobResultRequest>
) {
  try {
    const res: FetchQueryJobResultResponse = yield call(
      updatesContentsAdApi.fetchQueryJobResult(action.payload)
    );

    yield put(
      fetchQueryJobResult.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      fetchQueryJobResult.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleCreateTargetingQuery(
  action: Action<CreateTargetingQueryRequest>
) {
  try {
    const res: CreateTargetingQueryResponse = yield call(
      updatesContentsAdApi.createTargetingQuery(action.payload)
    );

    yield put(
      createTargetingQuery.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      createTargetingQuery.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleUpdateTargetingQuery(
  action: Action<UpdateTargetingQueryRequest>
) {
  try {
    const res: UpdateTargetingQueryResponse = yield call(
      updatesContentsAdApi.updateTargetingQuery(action.payload)
    );

    yield put(
      updateTargetingQuery.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      updateTargetingQuery.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

function* watchHandleAdSetSummary() {
  yield takeLatest(fetchAdSetSummary.started, handleAdSetSummary);
}

function* watchHandleUpdateAdSet() {
  yield takeLatest(updateAdSet.started, handleUpdateAdSet);
}

function* watchHandleCreateAdSet() {
  yield takeLatest(createAdSet.started, handleCreateAdSet);
}

function* watchHandleFetchTargetingQueries() {
  yield takeLatest(fetchTargetingQueries.started, handleFetchTargetingQueries);
}

function* watchHandleFetchTargetingQueryNames() {
  yield takeLatest(
    fetchTargetingQueryNames.started,
    handleFetchTargetingQueryNames
  );
}

function* watchHandleFetchTargetingCount() {
  yield takeLatest(fetchTargetingCount.started, handleFetchTargetingCount);
}

function* watchHandleFetchQueryJobResult() {
  yield takeLatest(fetchQueryJobResult.started, handleFetchQueryJobResult);
}

function* watchHandleCreateTargetingQuery() {
  yield takeLatest(createTargetingQuery.started, handleCreateTargetingQuery);
}

function* watchHandleUpdateTargetingQuery() {
  yield takeLatest(updateTargetingQuery.started, handleUpdateTargetingQuery);
}

export function* adSetWorker() {
  yield all([
    fork(watchHandleAdSetSummary),
    fork(watchHandleUpdateAdSet),
    fork(watchHandleCreateAdSet),
    fork(watchHandleFetchTargetingQueries),
    fork(watchHandleFetchTargetingQueryNames),
    fork(watchHandleFetchTargetingCount),
    fork(watchHandleFetchQueryJobResult),
    fork(watchHandleCreateTargetingQuery),
    fork(watchHandleUpdateTargetingQuery),
  ]);
}
