import React from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import LinkItem from "../SceneEntry/LinkItem";
import { linkUuidsSelectorFactory } from "../../store/selectors/linkList";
import { RootState } from "../../store/modules";

interface OwnProps {
  linkListUuid: string;
  sceneEntryId: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

function LinkListItemsContainer(props: Props) {
  //  props & state
  // -----------------------------------------------

  const { linkUuids, linkListUuid, sceneEntryId } = props;
  if (!linkUuids) {
    return null;
  }

  //  jsx
  // -----------------------------------------------

  return (
    <>
      {linkUuids.map((linkUuid, index) => {
        return (
          <Draggable
            key={linkUuid}
            draggableId={`draggable-link-list-${linkListUuid}-link-${linkUuid}`}
            index={index}
          >
            {(provided, snapshot) => {
              const style = {
                backgroundColor: snapshot.isDragging ? "#f7f7f7" : "#fff",
                boxShadow: snapshot.isDragging
                  ? "0 12px 24px 0 rgba(0, 0, 0, 0.1)"
                  : "",
                ...provided.draggableProps.style,
              };
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={style}
                >
                  <LinkItem
                    linkUuid={linkUuid}
                    linkListUuid={linkListUuid}
                    sceneEntryId={sceneEntryId}
                    isDragging={snapshot.isDragging}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
}

//  connect
// -----------------------------------------------

export default connect(mapStateToProps)(LinkListItemsContainer);

function mapStateToProps(state: RootState, props: OwnProps) {
  const { linkListUuid: uuid } = props;
  return {
    linkUuids: linkUuidsSelectorFactory(uuid)(state),
  };
}
