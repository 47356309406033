import React from "react";
import styled from "styled-components";

import RootTemplate from "./RootTemplate";
import LinkIndexContainer from "./LinkIndexContainer";

function LinkIndexPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <LinkIndexContainer />;
}

export default LinkIndexPage;
