import "whatwg-fetch";
import { camelizeKeys, decamelizeKeys } from "humps";

import { ChangeLinkParams, SearchLinksParams } from "../../store/modules/link";
import { PublishParams } from "../../store/modules/sceneEntry";

export interface RequestParams {
  method: string;
  headers?: RequestInit["headers"];
  body?: { [key: string]: any };
}

export function updatesContentsApi(
  path: string,
  params: RequestParams
): Promise<Response> {
  const url = new URL(path, window.location.origin);
  const request = {} as RequestInit;
  request.mode = "cors";
  request.method = params.method;
  request.credentials = "same-origin";
  request.body = JSON.stringify(params.body);
  request.headers = params.headers || {};
  request.headers = {
    // tslint:disable-next-line: object-literal-key-quotes
    Authorization: `Basic ${Buffer.from(
      `${(window as any).BASIC_AUTH_ADMIN_USERNAME}:${
        (window as any).BASIC_AUTH_ADMIN_PASSWORD
      }`
    ).toString("base64")}`,
    "Content-Type": "application/json; charset=utf-8",
  };

  return fetch(url.href, request);
}

export async function fetchSceneEntries() {
  const res = await updatesContentsApi("/api/admin/scene_entries", {
    method: "GET",
  });
  if (res.status === 204) {
    return {};
  }
  const body = await res.json();
  return camelizeKeys(body);
}

export async function addSceneEntry() {
  const res = await updatesContentsApi("/api/admin/scene_entries", {
    method: "POST",
  });
  if (res.status === 204) {
    return {};
  }
  const body = await res.json();
  return camelizeKeys(body);
}

export function searchLinks({ word }: SearchLinksParams) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/links/search?word=${word}`,
      {
        method: "GET",
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function changeLink({ link, rawImage }: ChangeLinkParams) {
  const requestBody = { link };
  if (rawImage) {
    const base64Image = rawImage.split(",")[1];
    requestBody["link_image_base64"] = base64Image;
  }
  return async () => {
    const res = await updatesContentsApi(`/api/admin/links/${link.uuid}`, {
      method: "PUT",
      body: requestBody,
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export interface UpdateOrderParams {
  linkListUuid: string;
  linkUuids: string;
}
export async function addNewLink() {
  const res = await updatesContentsApi(`/api/admin/links`, {
    method: "POST",
  });
  if (res.status === 204) {
    return {};
  }
  const body = await res.json();
  return camelizeKeys(body);
}

export function requestToPublishSceneEntries({ sceneEntries }: PublishParams) {
  return async () => {
    const res = await updatesContentsApi(`/api/admin/scene_entries/publish`, {
      method: "PUT",
      body: {
        scene_entries: decamelizeKeys(sceneEntries),
      },
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function requestToFetchLinkReports() {
  return async () => {
    const res = await updatesContentsApi(`/api/admin/link_reports`, {
      method: "GET",
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}
