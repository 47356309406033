import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import color from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";

interface OwnProps {
  adContentId: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class AdContentReportContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const adContent = this.props.idAdContent[this.props.adContentId];
    return (
      <>
        <TopBody>
          {!!adContent && adContent.report.impression.toLocaleString()}
        </TopBody>
        <Head>Impression</Head>
        <Body>{!!adContent && adContent.report.click.toLocaleString()}</Body>
        <Head>Click</Head>
        <Body>{!!adContent && adContent.report.cpc}</Body>
        <Head>CPC</Head>
        <Body>
          {!!adContent && adContent.report.spentBudget.toLocaleString()}
        </Body>
        <Head>Spent Budget</Head>
      </>
    );
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { adContents: idAdContent } = state.adContent;
  return { idAdContent };
}

export default connect(mapStateToProps)(AdContentReportContainer);

const Body = styled.div`
  ${heading}
  margin-top: 23px;
  font-size: 28px;
  line-height: 1;
  color: ${color.blackForeground840};
`;

const TopBody = styled(Body)`
  margin-top: 0px;
`;

const Head = styled.div`
  font-size: 14px;
  line-height: 1.8;
  color: ${color.blackForeground840};
`;
