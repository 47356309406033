import { combineReducers, Reducer } from "redux";

import LinkReducer, { LinkState } from "./link";
import LinkListReducer, { LinkListState } from "./linkList";
import sceneEntryReducer, { SceneEntryState } from "./sceneEntry";
import uiReducer, { UiState } from "./ui";
import adCampaignReducer, { AdCampaignState } from "./adCampaign";
import adAccountReducer, { AdAccountState } from "./adAccount";
import adSetReducer, { AdSetState } from "./adSet";
import adContentReducer, { AdContentState } from "./adContent";
import LinkReportReducer, { LinkReportState } from "./linkReport";

export interface RootState {
  ui: UiState;
  link: LinkState;
  linkReport: LinkReportState;
  linkList: LinkListState;
  sceneEntry: SceneEntryState;
  adAccount: AdAccountState;
  adCampaign: AdCampaignState;
  adSet: AdSetState;
  adContent: AdContentState;
}

export const rootReducer: Reducer<RootState> = combineReducers({
  ui: uiReducer,
  link: LinkReducer,
  linkList: LinkListReducer,
  sceneEntry: sceneEntryReducer,
  adCampaign: adCampaignReducer,
  adAccount: adAccountReducer,
  adSet: adSetReducer,
  adContent: adContentReducer,
  linkReport: LinkReportReducer,
});

export default rootReducer;
