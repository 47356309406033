import { all, call, put, takeLatest, fork } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import { history } from "../../App";
import * as updatesContentsAdApi from "../../utils/api/updatesContentsAdApi";
import {
  AdCampaignSummaryResponse,
  fetchAdCampaignSummary,
  UpdateAdCampaignRequest,
  UpdateAdCampaignResponse,
  updateAdCampaign,
  CreateAdCampaignRequest,
  CreateAdCampaignResponse,
  createAdCampaign,
} from "../modules/adCampaign";
import { adCampaignCreated } from "../modules/adAccount";

export function* handleAdCampaignSummary(
  action: Action<{ adAccountId: string; adCampaignId: string }>
) {
  const {
    adAccountId: adAccountId,
    adCampaignId: adCampaignId,
  } = action.payload;
  try {
    const res = yield call(
      updatesContentsAdApi.fetchAdCampaignSummary({ adAccountId, adCampaignId })
    );
    const adCampaignSummary = res as AdCampaignSummaryResponse;
    yield put(
      fetchAdCampaignSummary.done({
        params: { adAccountId, adCampaignId },
        result: adCampaignSummary,
      })
    );
  } catch (e) {
    yield put(
      fetchAdCampaignSummary.failed({
        params: { adAccountId, adCampaignId },
        error: e,
      })
    );
  }
}

export function* handleCreateAdCampaign(
  action: Action<CreateAdCampaignRequest>
) {
  try {
    const res = yield call(
      updatesContentsAdApi.createAdCampaign(action.payload)
    );
    const createdAdCampaign = res as CreateAdCampaignResponse;

    yield put(
      createAdCampaign.done({
        params: action.payload,
        result: createdAdCampaign,
      })
    );
    yield put(
      adCampaignCreated({
        adAccountId: createdAdCampaign.adAccountId,
        adCampaignId: createdAdCampaign.id,
      })
    );
    history.push(
      `/admin/ad/ad_accounts/${createdAdCampaign.adAccountId}/ad_campaigns/${
        createdAdCampaign.id
      }/summary`
    );
  } catch (e) {
    yield put(
      createAdCampaign.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleUpdateAdCampaign(
  action: Action<UpdateAdCampaignRequest>
) {
  try {
    const res = yield call(
      updatesContentsAdApi.updateAdCampaign(action.payload)
    );
    const updatedAdCampaign = res as UpdateAdCampaignResponse;

    yield put(
      updateAdCampaign.done({
        params: action.payload,
        result: updatedAdCampaign,
      })
    );
  } catch (e) {
    yield put(
      updateAdCampaign.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

function* watchHandleAdCampaignSummary() {
  yield takeLatest(fetchAdCampaignSummary.started, handleAdCampaignSummary);
}

function* watchHandleCreateAdCampaign() {
  yield takeLatest(createAdCampaign.started, handleCreateAdCampaign);
}

function* watchHandleUpdateAdCampaign() {
  yield takeLatest(updateAdCampaign.started, handleUpdateAdCampaign);
}

export function* adCampaignWorker() {
  yield all([
    fork(watchHandleAdCampaignSummary),
    fork(watchHandleUpdateAdCampaign),
    fork(watchHandleCreateAdCampaign),
  ]);
}
