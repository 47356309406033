import { call, all, fork, takeLatest, put } from "redux-saga/effects";

import {
  fetchLinkReports,
  FetchLinkReportResponse,
} from "../modules/linkReport";
import * as updatesContentsApi from "../../utils/api/updatesContentsApi";

function* handlefetchLinkReports() {
  try {
    const res: FetchLinkReportResponse = yield call(
      updatesContentsApi.requestToFetchLinkReports()
    );
    yield put(fetchLinkReports.done({ result: res }));
  } catch (e) {
    yield put(fetchLinkReports.failed({ error: e }));
  }
}

function* watchFetchLinkReports() {
  yield takeLatest(fetchLinkReports.started, handlefetchLinkReports);
}

export function* linkReportWorker() {
  yield all([fork(watchFetchLinkReports)]);
}
