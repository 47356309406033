import React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router";

import { heading } from "../styles/font";
import colors from "../styles/color";
import { fetchAdAccountSummary } from "../store/modules/adAccount";
import { RootState } from "../store/modules";
import { adCampaignFocused } from "../store/modules/ui";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

class AdCampaignListContainer extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    this.props.fetchAdAccountSummary({ adAccountId: params["ad_account_id"] });
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      if (!this.props.match) {
        return null;
      }
      const { params } = this.props.match;
      this.props.fetchAdAccountSummary({
        adAccountId: params["ad_account_id"],
      });
    }
  }

  // jsx
  // -----------------------------------------------

  public render() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const { idAdAccount, idAdCampaign, focusedAdCampaignId } = this.props;
    const adAccount = idAdAccount[adAccountId];

    return (
      <Container>
        <Wrapper>
          <SearchInput />
        </Wrapper>
        <ContentsTable>
          <HeaderRow>
            <AdCampaignNameHeader>Ad Campaign</AdCampaignNameHeader>
            <AdCampaignHeader>Spent Budget</AdCampaignHeader>
            <AdCampaignHeader>Total Budget</AdCampaignHeader>
            <AdCampaignHeader>Imp</AdCampaignHeader>
            <AdCampaignHeader>Click</AdCampaignHeader>
            <AdCampaignHeader>Ctr</AdCampaignHeader>
            <AdCampaignHeader>Cpc</AdCampaignHeader>
            <StatusHeader />
          </HeaderRow>
          {!!adAccount &&
            adAccount.adCampaigns.map(adCampaignId => {
              const adCampaign = idAdCampaign[adCampaignId];
              const adCampaignReport = adCampaign.report;
              if (!adCampaignReport) {
                return;
              }
              return (
                <TableRow
                  key={adCampaignId}
                  focused={Number(adCampaignId) === Number(focusedAdCampaignId)}
                  onClick={() => this.props.focusAdCampaign({ adCampaignId })}
                >
                  <TableData>
                    <AdCampaignName>{adCampaign.name}</AdCampaignName>
                  </TableData>
                  <TableData>
                    <ReportData>
                      {adCampaignReport.spentBudget.toLocaleString()}
                    </ReportData>
                  </TableData>
                  <TableData>
                    <ReportData>
                      {!!adCampaign.totalBudget &&
                        adCampaign.totalBudget.toLocaleString()}
                    </ReportData>
                  </TableData>
                  <TableData>
                    <ReportData>
                      {adCampaignReport.impression.toLocaleString()}
                    </ReportData>
                  </TableData>
                  <TableData>
                    <ReportData>
                      {adCampaignReport.click.toLocaleString()}
                    </ReportData>
                  </TableData>
                  <TableData>
                    <ReportData>{adCampaignReport.ctr}</ReportData>
                  </TableData>
                  <TableData>
                    <ReportData>{adCampaignReport.cpc}</ReportData>
                  </TableData>
                  <TableData>
                    <Status>
                      {idAdCampaign[adCampaignId].status === "ACTIVE" && (
                        <ActiveIndicator />
                      )}
                      {idAdCampaign[adCampaignId].status === "STOPPED" && (
                        <StoppedIndicator />
                      )}
                      {idAdCampaign[adCampaignId].status === "BUDGET_OVER" && (
                        <BudgetOverIndicator />
                      )}
                    </Status>
                  </TableData>
                </TableRow>
              );
            })}
        </ContentsTable>
      </Container>
    );
  }
}

//  connect
// -----------------------------------------------

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdCampaignListContainer)
);

function mapStateToProps(state: RootState) {
  const { adAccounts: idAdAccount } = state.adAccount;
  const { adCampaigns: idAdCampaign } = state.adCampaign;
  const { focusedAdCampaignId } = state.ui.adAccountSummary;
  return { idAdAccount, idAdCampaign, focusedAdCampaignId };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchAdAccountSummary: (params: { adAccountId: string }) =>
        fetchAdAccountSummary.started(params),
      focusAdCampaign: (params: { adCampaignId: string }) =>
        adCampaignFocused(params),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  min-width: 640px;
  margin: 71px auto auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
`;

const SearchInput = styled.input`
  border-style: none;
  height: 40px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-right: 20px;
  width: 100%;
  outline: 0;
`;

const ContentsTable = styled.table`
  min-width: 640px;
`;

const HeaderRow = styled.tr`
  height: 30px;
`;
const AdCampaignNameHeader = styled.th`
  text-indent: 7px;
  text-align: left;
`;
const AdCampaignHeader = styled.th`
  text-align: right;
`;
const StatusHeader = styled.th``;

const TableRow = styled.tr<{ focused: boolean }>`
  border-top: 1px solid ${colors.blackForeground50};
  ${props =>
    props.focused
      ? css`
          background-color: rgba(0, 0, 0, 0.03);
        `
      : css``}
  cursor: pointer;
`;
const TableData = styled.td`
  vertical-align: middle;
`;

const AdCampaignName = styled.div`
  ${heading}
  max-width: 210px;
  margin: 12px auto 11px 7px;
  font-size: 14px;
  line-height: 1.71;
  color: ${colors.blackForeground800};
  word-wrap: break-word;
`;
const ReportData = styled.div`
  margin-left: 10px;
  text-align: right;
`;
const Status = styled.div`
  margin: auto 20px;
`;
const ActiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #08c5e7;
`;
const StoppedIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
`;
const BudgetOverIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  background-color: #f65948;
`;
