import actionCreatorFactory, { Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Reducer } from "redux";
import produce from "immer";

import { AdContent } from "./adContent";
import { fetchAdAccountNavigation } from "./adAccount";
import { fetchAdCampaignSummary } from "./adCampaign";

//  Types
// -----------------------------------------------

export type AdSetStatus = "ACTIVE" | "STOPPED" | "OUT_OF_PERIOD";

export interface DailyReport {
  date: string;
  impressionCount: string;
  clickCount: string;
  ctr: string;
  cpc: string;
  spentBudget: string;
}
export interface AdSetSummaryResponse {
  id: string;
  name: string;
  unitPrice: string; // TODO: 小数点の扱いについて調べる
  unitType: string;
  enabled: boolean;
  targetingQueryId: string;
  startedAt: string;
  endedAt: string;
  frequencyCap: string;
  frequencyCapPeriod: string;
  strength: string;
  adContentType: string;
  adCampaignId: string;
  status: AdSetStatus;
  report: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
  dailyReports: DailyReport[];
  adContents: AdContent[];
  conversionTagIds: string[];
}

export interface IdAdSet {
  [id: string]: AdSet;
}

export interface AdSet {
  id: string;
  name: string;
  unitPrice?: string; // TODO: 小数点の扱いについて調べる
  unitType?: string;
  enabled?: boolean;
  targetingQueryId?: string;
  startedAt?: string;
  endedAt?: string;
  frequencyCap?: string;
  frequencyCapPeriod?: string;
  strength?: string;
  adContentType?: string;
  adCampaignId?: string;
  status: AdSetStatus;
  report?: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
  dailyReport?: DailyReport[];
  adContents: string[];
  conversionTagIds?: string[];
}

export interface CreateAdSetRequest {
  adAccountId: string;
  adCampaignId: string;
  name: string;
  unitPrice: string;
  targetingQueryId: string;
  startedAt: string;
  endedAt: string;
  frequencyCap: string;
  frequencyCapPeriod: string;
  strength: string;
  conversionTagIds: string[];
}

export interface CreateAdSetResponse {
  id: string;
  name: string;
  unitPrice: string;
  enabled: boolean;
  targetingQueryId?: string;
  startedAt: string;
  endedAt: string;
  frequencyCap: string;
  frequencyCapPeriod: string;
  strength: string;
  adContentType: string;
  adCampaignId: string;
  status: AdSetStatus;
  conversionTagIds: string[];
}

export interface UpdateAdSetRequest {
  adAccountId: string;
  adCampaignId: string;
  adSetId: string;
  name?: string;
  unitPrice?: string;
  enabled?: boolean;
  targetingQueryId?: string;
  startedAt?: string;
  endedAt?: string;
  frequencyCap?: string;
  frequencyCapPeriod?: string;
  strength?: string;
  conversionTagIds?: string[];
}

export interface UpdateAdSetResponse {
  id: string;
  name: string;
  unitPrice: string;
  enabled: boolean;
  targetingQueryId?: string;
  startedAt: string;
  endedAt: string;
  frequencyCap: string;
  frequencyCapPeriod: string;
  strength: string;
  adCampaignId: string;
  status: string;
  conversionTagIds: string[];
}

export interface TargetingQuery {
  id: string;
  name: string;
  query?: string;
  expanded?: boolean;
  targetingCount?: string;
}

export interface IdTargetingQuery {
  [id: string]: TargetingQuery;
}

export interface FetchTargetingQueriesResponse {
  id: string;
  name: string;
  query: string;
  expanded: boolean;
  targetNumbers: string;
}

export interface TargetingCountRequest {
  query: string;
  expanded: boolean;
}

export interface TargetingCountResponse {
  jobId: string;
}

export interface FetchQueryJobResultRequest {
  jobId: string;
}

export interface FetchQueryJobResultResponse {
  targetingCount?: string;
  errorMessage?: string;
}

export interface CreateTargetingQueryRequest {
  name: string;
  query: string;
  expanded: boolean;
  targetingCount: string;
}

export interface CreateTargetingQueryResponse {
  id: string;
  name: string;
  query: string;
  expanded: boolean;
  targetNumbers: string;
}

export interface UpdateTargetingQueryRequest {
  id: string;
  name: string;
  query: string;
  expanded: boolean;
  targetingCount: string;
}

export interface UpdateTargetingQueryResponse {
  id: string;
  name: string;
  query: string;
  expanded: boolean;
  targetNumbers: string;
}

//  State
// -----------------------------------------------

export interface AdSetState {
  adSets: IdAdSet;
  targetingQueries: IdTargetingQuery[];
  targetingQueryIds: string[];
  targetingCount: string;
  executingQuery: boolean;
  queryJobId: string;
  queryJobErrorMessage: string;
}

export const initialState: AdSetState = {
  adSets: {},
  targetingQueries: [],
  targetingQueryIds: [],
  targetingCount: "",
  executingQuery: false,
  queryJobId: "",
  queryJobErrorMessage: "",
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("AD_SET");

export const fetchAdSetSummary = actionCreator.async<
  { adAccountId: string; adCampaignId: string; adSetId: string },
  AdSetSummaryResponse,
  Error
>("FETCH_AD_SET_SUMMARY");

export const createAdSet = actionCreator.async<
  CreateAdSetRequest,
  CreateAdSetResponse,
  Error
>("CREATE_AD_SET");

export const updateAdSet = actionCreator.async<
  UpdateAdSetRequest,
  UpdateAdSetResponse,
  Error
>("UPDATE_AD_SET");

export const fetchTargetingQueryNames = actionCreator.async<
  void,
  TargetingQuery[],
  Error
>("FETCH_TARGETING_QUERY_NAMES");

export const fetchTargetingQueries = actionCreator.async<
  void,
  FetchTargetingQueriesResponse[],
  Error
>("FETCH_TARGETING_QUERIES");

export const fetchTargetingCount = actionCreator.async<
  TargetingCountRequest,
  TargetingCountResponse,
  Error
>("FETCH_TARGETING_COUNT");

export const fetchQueryJobResult = actionCreator.async<
  FetchQueryJobResultRequest,
  FetchQueryJobResultResponse,
  Error
>("FETCH_QUERY_JOB_RESULT");

export const createTargetingQuery = actionCreator.async<
  CreateTargetingQueryRequest,
  CreateTargetingQueryResponse,
  Error
>("CREATE_TARGETING_QUERY");

export const updateTargetingQuery = actionCreator.async<
  UpdateTargetingQueryRequest,
  UpdateTargetingQueryResponse,
  Error
>("UPDATE_TARGETING_QUERY");

export const targetingQueryCanceled = actionCreator<{}>(
  "TARGETING_QUERY_CANCELED"
);

//  Reducer
// -----------------------------------------------

const adSetReducer: Reducer<AdSetState> = reducerWithInitialState<AdSetState>(
  initialState
)
  .case(
    fetchAdAccountNavigation.done,
    (state, { result: adAccountNavigation }) =>
      produce(state, (draft: AdSetState) => {
        adAccountNavigation.adCampaigns.forEach(adCampaign => {
          adCampaign.adSets.forEach(adSet => {
            if (draft.adSets[adSet.id]) {
              Object.assign(draft.adSets[adSet.id], adSet);
            } else {
              draft.adSets[adSet.id] = {
                ...adSet,
                adContents: [],
                conversionTagIds: [],
              };
            }
          });
        });
      })
  )
  .case(
    fetchAdCampaignSummary.done,
    (state, { result: adCampaignSummaryResponse }) =>
      produce(state, (draft: AdSetState) => {
        adCampaignSummaryResponse.adSets.forEach(adSet => {
          if (draft.adSets[adSet.id]) {
            Object.assign(draft.adSets[adSet.id], adSet);
          } else {
            draft.adSets[adSet.id] = { ...adSet, adContents: [] };
          }
        });
      })
  )
  .case(fetchAdSetSummary.done, (state, { result }) =>
    produce(state, (draft: AdSetState) => {
      const adSetId = result.id;
      if (draft.adSets[adSetId]) {
        Object.assign(draft.adSets[adSetId], result, {
          adContents: result.adContents.map(adContent => adContent.id),
          conversionTagIds: result.conversionTagIds.map(conversionTagId =>
            conversionTagId.toString()
          ),
        });
      } else {
        draft.adSets[adSetId] = {
          ...result,
          adContents: result.adContents.map(adContent => adContent.id),
          conversionTagIds: result.conversionTagIds.map(conversionTagId =>
            conversionTagId.toString()
          ),
        };
      }
      draft.adSets[adSetId].adContents.sort((a, b) => {
        if (a < b) {
          return 1;
        } else {
          return -1;
        }
      });
    })
  )
  .case(createAdSet.done, (state, { result }) =>
    produce(state, (draft: AdSetState) => {
      const { id } = result;
      draft.adSets[id] = {
        ...result,
        adContents: [],
        conversionTagIds: result.conversionTagIds.map(conversionTagId =>
          conversionTagId.toString()
        ),
      };
    })
  )
  .case(updateAdSet.done, (state, { result }) =>
    produce(state, (draft: AdSetState) => {
      const { id } = result;
      Object.assign(draft.adSets[id], result, {
        conversionTagIds: result.conversionTagIds.map(conversionTagId =>
          conversionTagId.toString()
        ),
      });
    })
  )
  .case<Success<void, FetchTargetingQueriesResponse[]>>(
    fetchTargetingQueries.done,
    (state, { result: targetingQueries }) =>
      produce(state, (draft: AdSetState) => {
        draft.targetingQueryIds = targetingQueries.map(
          targetingQuery => targetingQuery.id
        );
        draft.targetingQueryIds.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
        targetingQueries.forEach(targetingQuery => {
          const { id, name, query, expanded, targetNumbers } = targetingQuery;
          draft.targetingQueries[targetingQuery.id] = {
            id,
            name,
            query,
            expanded,
            targetingCount: targetNumbers,
          };
        });
      })
  )
  .case<Success<void, TargetingQuery[]>>(
    fetchTargetingQueryNames.done,
    (state, { result: targetingQueries }) =>
      produce(state, (draft: AdSetState) => {
        draft.targetingQueryIds = targetingQueries.map(
          targetingQuery => targetingQuery.id
        );
        draft.targetingQueryIds.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
        targetingQueries.forEach(targetingQuery => {
          draft.targetingQueries[targetingQuery.id] = targetingQuery;
        });
      })
  )
  .case(fetchTargetingCount.started, (state, {}) =>
    produce(state, (draft: AdSetState) => {
      draft.targetingCount = "";
      draft.queryJobErrorMessage = "";
    })
  )
  .case(fetchTargetingCount.done, (state, { result: { jobId } }) =>
    produce(state, (draft: AdSetState) => {
      draft.executingQuery = true;
      draft.queryJobId = jobId;
    })
  )
  .case(fetchTargetingCount.failed, (state, {}) =>
    produce(state, (draft: AdSetState) => {
      resetTargetingQuery(draft);
    })
  )
  .case(
    fetchQueryJobResult.done,
    (state, { result: { targetingCount, errorMessage } }) =>
      produce(state, (draft: AdSetState) => {
        if (!targetingCount && !errorMessage) {
          return;
        }
        draft.executingQuery = false;
        draft.queryJobId = "";
        draft.targetingCount = !!targetingCount ? targetingCount : "";
        draft.queryJobErrorMessage = !!errorMessage ? errorMessage : "";
      })
  )
  .case(fetchQueryJobResult.failed, (state, {}) =>
    produce(state, (draft: AdSetState) => {
      resetTargetingQuery(draft);
    })
  )
  .case(
    createTargetingQuery.done,
    (state, { result: { id, name, query, expanded, targetNumbers } }) =>
      produce(state, (draft: AdSetState) => {
        draft.targetingQueries[id] = {
          id,
          name,
          query,
          expanded,
          targetingCount: targetNumbers,
        };
        draft.targetingQueryIds.push(id);
        draft.targetingQueryIds.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
        resetTargetingQuery(draft);
      })
  )
  .case(
    updateTargetingQuery.done,
    (state, { result: { id, name, query, expanded, targetNumbers } }) =>
      produce(state, (draft: AdSetState) => {
        draft.targetingQueries[id] = {
          id,
          name,
          query,
          expanded,
          targetingCount: targetNumbers,
        };
        resetTargetingQuery(draft);
      })
  )
  .case(targetingQueryCanceled, (state, {}) =>
    produce(state, (draft: AdSetState) => {
      resetTargetingQuery(draft);
    })
  );

const resetTargetingQuery = (draft: AdSetState) => {
  draft.targetingCount = "";
  draft.executingQuery = false;
  draft.queryJobErrorMessage = "";
  draft.queryJobId = "";
};

export default adSetReducer;
