import styled from "styled-components";

import colors from "../styles/color";

const Input = styled.input`
  background-color: #f7f7f7;
  outline: none;
  border-style: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  height: 40px;
  line-height: 1.75;
  text-indent: 16px;
  color: ${colors.blackForeground840};
  &:focus {
    background-color: #f0f0f0;
  }
  &:placeholder {
    color: ${colors.blackForeground400};
  }
`;

export default Input;
