import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import colors from "../styles/color";
import { heading } from "../styles/font";
import { truncateLines } from "../utils/styles";
import { RootState } from "../store/modules";
import { linkReportsSelector } from "../store/selectors/linkReport";
import { fetchLinkReports } from "../store/modules/linkReport";
import { loadingLinkReportsSelector } from "../store/selectors/ui";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function LinkReportIndexContainer(props: Props) {
  const { linkReports, loading } = props;

  //  hooks
  // -----------------------------------------------

  useEffect(() => {
    if (linkReports.length <= 0) {
      props.fetchLinkReports();
    }
  }, []);

  return (
    <Container>
      <Header>CTR Ranking</Header>
      <SubHeader>
        直近10日間のLinkが対象: {loading ? null : `${linkReports.length}件`}
      </SubHeader>
      <Content>
        {loading ? (
          <LoaderWrapper>
            <Loader src="/loader-circle.gif" alt="loader" />
          </LoaderWrapper>
        ) : (
          <>
            {linkReports.map(linkReport => (
              <ReportItem key={linkReport.uuid}>
                <UpperHalf>
                  <Title href={linkReport.url} target="_blank">
                    {linkReport.title}
                  </Title>
                  <Meta>
                    <Media>{linkReport.media}</Media>
                    <CreatedAt>
                      Created:{" "}
                      {new Date(linkReport.createdAt).toLocaleString("ja")}
                    </CreatedAt>
                  </Meta>
                </UpperHalf>
                <LowerHalf>
                  <Wrapper>
                    <Metrics>
                      {Math.round(
                        (linkReport.report.clickCount /
                          linkReport.report.impressionCount) *
                          1000
                      ) / 10}
                      %
                    </Metrics>
                    <Subtitle>CTR</Subtitle>
                  </Wrapper>
                  <Wrapper>
                    <Metrics>{linkReport.report.clickCount}</Metrics>
                    <Subtitle>CLICK</Subtitle>
                  </Wrapper>
                  <Wrapper>
                    <Metrics>{linkReport.report.impressionCount}</Metrics>
                    <Subtitle>IMPRESSION</Subtitle>
                  </Wrapper>
                </LowerHalf>
              </ReportItem>
            ))}
          </>
        )}
      </Content>
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkReportIndexContainer);

function mapStateToProps(state: RootState) {
  return {
    linkReports: linkReportsSelector(state),
    loading: loadingLinkReportsSelector(state),
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchLinkReports: () => fetchLinkReports.started(),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  padding: 32px 16px;
`;

const Header = styled.div`
  ${heading}
  font-size: 32px;
  color: ${colors.blackForeground840};
  padding: 0 16px;
`;
const SubHeader = styled.div`
  color: ${colors.blackForeground400};
  font-size: 16px;
  padding: 0 16px;
`;

const ReportItem = styled.div`
  border-bottom: 1px solid ${colors.blackForeground50};
  padding: 16px 16px;
`;

const Title = styled.a`
  text-decoration: none;
  cursor: pointer;
  flex-shrink: 1;
  font-size: 20px;
  line-height: 1.43;
  color: ${colors.blackForeground800};
  ${heading};
  ${truncateLines(2)}
`;

const UpperHalf = styled.div``;

const Meta = styled.div`
  display: flex;
`;

const Media = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
`;

const CreatedAt = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
  margin-left: 24px;
`;

const LowerHalf = styled.div`
  display: flex;
  margin-top: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 16px;
`;

const Metrics = styled.div`
  ${heading};
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.84);
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.84);
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const Loader = styled.img`
  width: 40px;
  height: 40px;
  opacity: 0.4;
`;

const Content = styled.div`
  margin-top: 48px;
`;
