import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import DatePicker from "antd/lib/date-picker";
import TimePicker from "antd/lib/time-picker";
import moment from "moment";

import color from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import colors from "../../styles/color";
import {
  UpdateAdSetRequest,
  updateAdSet,
  CreateAdSetRequest,
  createAdSet,
} from "../../store/modules/adSet";

interface OwnProps {
  adSetId: string;
}
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  OwnProps;

interface State {
  name: string;
  unitPrice: string;
  unitType: string;
  enabled: boolean;
  targetingQueryId: string;
  startedAt: moment.Moment;
  endedAt: moment.Moment;
  frequencyCap: string;
  frequencyCapPeriod: string;
  strength: string;
  conversionTagIds: string[];
}

class AdSetSettingsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const adSet = this.props.idAdSet[this.props.adSetId];
    this.state = {
      name: adSet ? adSet.name : "",
      unitPrice: adSet && adSet.unitPrice ? adSet.unitPrice : "",
      unitType: adSet && adSet.unitType ? adSet.unitType : "",
      enabled: adSet && adSet.enabled ? adSet.enabled : false,
      targetingQueryId:
        adSet && adSet.targetingQueryId ? adSet.targetingQueryId : "",
      startedAt:
        !!adSet && adSet.startedAt
          ? moment(new Date(adSet.startedAt))
          : moment(new Date()),
      endedAt:
        !!adSet && adSet.endedAt
          ? moment(new Date(adSet.endedAt))
          : moment(new Date()),
      frequencyCap: adSet && adSet.frequencyCap ? adSet.frequencyCap : "",
      frequencyCapPeriod:
        adSet && adSet.frequencyCapPeriod ? adSet.frequencyCapPeriod : "day",
      strength: adSet && adSet.strength ? adSet.strength : "",
      conversionTagIds:
        adSet && adSet.conversionTagIds ? adSet.conversionTagIds : [],
    };
    this.handleChangeAdSet = this.handleChangeAdSet.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.adSetId !== prevProps.adSetId ||
      this.props.idAdSet !== prevProps.idAdSet
    ) {
      const adSet = this.props.idAdSet[this.props.adSetId];
      this.setState({
        name: adSet ? adSet.name : "",
        unitPrice: adSet && adSet.unitPrice ? adSet.unitPrice : "",
        unitType: adSet && adSet.unitType ? adSet.unitType : "",
        enabled: adSet && adSet.enabled ? adSet.enabled : false,
        targetingQueryId:
          adSet && adSet.targetingQueryId ? adSet.targetingQueryId : "",
        startedAt:
          !!adSet && adSet.startedAt
            ? moment(new Date(adSet.startedAt))
            : moment(new Date()),
        endedAt:
          !!adSet && adSet.endedAt
            ? moment(new Date(adSet.endedAt))
            : moment(new Date()),
        frequencyCap: adSet && adSet.frequencyCap ? adSet.frequencyCap : "",
        frequencyCapPeriod:
          adSet && adSet.frequencyCapPeriod ? adSet.frequencyCapPeriod : "day",
        strength: adSet && adSet.strength ? adSet.strength : "",
        conversionTagIds:
          adSet && adSet.conversionTagIds ? adSet.conversionTagIds : [],
      });
    }
  }

  public render() {
    if (!this.props.match) {
      return;
    }
    const { params } = this.props.match;
    const adAccount = this.props.idAdAccount[params["ad_account_id"]];
    if (!adAccount) {
      return;
    }
    const dateFormat = "YYYY/MM/DD";
    const timeFormat = "HH:mm";
    return (
      <form onSubmit={this.handleSubmit}>
        <NameHeader>Name</NameHeader>
        <NameInput
          name="name"
          value={this.state.name}
          onChange={this.handleChangeAdSet}
        />
        <PeriodHeader>Period</PeriodHeader>
        <PeriodFrom>
          <StyledDatePicker
            format={dateFormat}
            value={this.state.startedAt}
            allowClear={false}
            onChange={time => {
              if (time !== null) {
                this.setState({ startedAt: time });
              }
            }}
          />
          <StyledTimePicker
            format={timeFormat}
            value={this.state.startedAt}
            allowClear={false}
            onChange={time => {
              if (time !== null) {
                this.setState({ startedAt: time });
              }
            }}
          />
        </PeriodFrom>
        <To>to</To>
        <PeriodTo>
          <StyledDatePicker
            format={dateFormat}
            value={this.state.endedAt}
            allowClear={false}
            onChange={time => {
              if (time !== null) {
                this.setState({ endedAt: time });
              }
            }}
          />
          <StyledTimePicker
            format={timeFormat}
            value={this.state.endedAt}
            allowClear={false}
            onChange={time => {
              if (time !== null) {
                this.setState({ endedAt: time });
              }
            }}
          />
        </PeriodTo>
        <UnitPriceHeader>Unit Price</UnitPriceHeader>
        <UnitPriceInput
          name="unitPrice"
          value={this.state.unitPrice}
          onChange={this.handleChangeAdSet}
        />
        <Unit>Yen</Unit>
        <FrequencyCapHeader>Frequency Cap</FrequencyCapHeader>
        <FrequencyCapInput
          name="frequencyCap"
          value={this.state.frequencyCap}
          onChange={this.handleChangeAdSet}
        />
        <Unit>per</Unit>
        <FrequencyCapPeriodSelect
          name="frequencyCapPeriod"
          value={this.state.frequencyCapPeriod}
          onChange={(e: React.FormEvent<HTMLSelectElement>) =>
            this.setState({ frequencyCapPeriod: e.currentTarget.value })
          }
        >
          <option value="day">day</option>
          <option value="week">week</option>
          <option value="month">month</option>
        </FrequencyCapPeriodSelect>
        <StrengthHeader>Strength</StrengthHeader>
        <StrengthInput
          name="strength"
          value={this.state.strength}
          onChange={this.handleChangeAdSet}
        />
        <TargetingHeader>Targeting</TargetingHeader>
        <TargetingQueriesSelect
          name="targetingQueryId"
          value={this.state.targetingQueryId}
          onChange={(e: React.FormEvent<HTMLSelectElement>) =>
            this.setState({ targetingQueryId: e.currentTarget.value })
          }
        >
          <option value="" />
          {this.props.targetingQueryIds.map(targetingQueryId => (
            <option key={targetingQueryId} value={targetingQueryId}>
              {this.props.idTargetingQuery[targetingQueryId].name}
            </option>
          ))}
        </TargetingQueriesSelect>
        <ConversionTagHeader>Conversion Tag</ConversionTagHeader>
        <ConversionTagNotice>
          ※配信途中でタグを変更するとコンバージョン実績とアドセットの対応が崩れる可能性があるので確認してください
        </ConversionTagNotice>
        {!!adAccount &&
          !!adAccount.conversionTags &&
          adAccount.conversionTags.map(conversionTagId => (
            <ConversionTagCheckboxWrapper key={conversionTagId}>
              <ConversionTagCheckboxInput
                type="checkbox"
                onChange={this.handleChangeCheckBox}
                name="conversionTagIds"
                value={conversionTagId}
                checked={this.state.conversionTagIds.includes(conversionTagId)}
              />
              <ConversionTagCheckboxLabel>
                {this.props.idConversionTag[conversionTagId].name}
              </ConversionTagCheckboxLabel>
            </ConversionTagCheckboxWrapper>
          ))}
        <PublishWrapper>
          <Publish>Publish</Publish>
        </PublishWrapper>
      </form>
    );
  }

  private handleChangeAdSet(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    const nextState = {};
    nextState[name] = value;
    this.setState(nextState);
  }

  private handleChangeCheckBox(e: React.FormEvent<HTMLInputElement>) {
    const checkedId = e.currentTarget.value.toString();
    if (this.state.conversionTagIds.includes(checkedId)) {
      this.setState({
        conversionTagIds: this.state.conversionTagIds.filter(
          conversionTagId => conversionTagId !== checkedId
        ),
      });
    } else {
      this.setState({
        conversionTagIds: this.state.conversionTagIds.concat([checkedId]),
      });
    }
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.props.match) {
      return;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const adCampaignId = params["ad_campaign_id"];
    const {
      name,
      unitPrice,
      enabled,
      targetingQueryId,
      startedAt,
      endedAt,
      frequencyCap,
      frequencyCapPeriod,
      strength,
      conversionTagIds,
    } = this.state;
    const adSetId = this.props.adSetId;
    if (adSetId) {
      this.props.updateAdSet({
        adAccountId,
        adCampaignId,
        adSetId,
        name,
        unitPrice,
        enabled,
        targetingQueryId,
        startedAt: startedAt.toISOString(),
        endedAt: endedAt.toISOString(),
        frequencyCap,
        frequencyCapPeriod,
        strength,
        conversionTagIds,
      });
    } else {
      this.props.createAdSet({
        adAccountId,
        adCampaignId,
        name,
        unitPrice,
        targetingQueryId,
        startedAt: startedAt.toISOString(),
        endedAt: endedAt.toISOString(),
        frequencyCap,
        frequencyCapPeriod,
        strength,
        conversionTagIds,
      });
    }
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const {
    adSets: idAdSet,
    targetingQueries: idTargetingQuery,
    targetingQueryIds,
  } = state.adSet;
  const {
    conversionTags: idConversionTag,
    adAccounts: idAdAccount,
  } = state.adAccount;
  return {
    idAdSet,
    idTargetingQuery,
    targetingQueryIds,
    idConversionTag,
    idAdAccount,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateAdSet: (params: UpdateAdSetRequest) => updateAdSet.started(params),
      createAdSet: (params: CreateAdSetRequest) => createAdSet.started(params),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdSetSettingsContainer)
);

const NameHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 32px;
  color: ${color.blackForeground840};
`;
const PeriodHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 22px;
  color: ${color.blackForeground840};
`;
const UnitPriceHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  color: ${color.blackForeground840};
`;
const FrequencyCapHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 24px;
  color: ${color.blackForeground840};
`;
const StrengthHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 24px;
  color: ${color.blackForeground840};
`;
const TargetingHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 24px;
  color: ${color.blackForeground840};
`;

const ConversionTagHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 24px;
  color: ${color.blackForeground840};
`;

const NameInput = styled.input`
  width: 272px;
  height: 40px;
  font-size: 16px;
  border: none;
  text-indent: 12px;
  margin-top: 13px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const PeriodFrom = styled.div`
  margin-top: 13px;
`;

const PeriodTo = styled.div`
  margin-top: 5px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 162px;
  height: 40px;
  & .ant-calendar-picker-input {
    background-color: #f7f7f7;
    border-style: none;
    border-radius: 4px;
    font-size: 16px;
    color: ${colors.blackForeground840};
    background-color: rgba(0, 0, 0, 0.03);
    height: 40px;
    &:focus {
      background-color: #f0f0f0;
    }
    &:placeholder {
      color: ${colors.blackForeground400};
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  width: 88px;
  height: 40px;
  margin-left: 8px;
  margin-top: 4px;
  & .ant-time-picker-input {
    background-color: #f7f7f7;
    font-size: 16px;
    border-style: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.03);
    height: 40px;
    color: ${colors.blackForeground840};
    &:focus {
      background-color: #f0f0f0;
    }
    &:placeholder {
      color: ${colors.blackForeground400};
    }
  }
`;
const Unit = styled.div`
  font-size: 12px;
  margin: 5px auto auto 11px;
  line-height: 1.33;
  color: ${color.blackForeground400};
`;

const To = styled.div`
  width: 12px;
  height: 16px;
  margin-top: 5px;
  margin-left: 11px;
  font-size: 12px;
  line-height: 1.33;
  color: ${color.blackForeground400};
`;

const UnitPriceInput = styled.input`
  width: 272px;
  height: 40px;
  border: none;
  font-size: 16px;
  text-indent: 12px;
  margin-top: 13px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const FrequencyCapInput = styled.input`
  width: 272px;
  height: 40px;
  border: none;
  font-size: 16px;
  text-indent: 12px;
  margin-top: 13px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const FrequencyCapPeriodSelect = styled.select`
  width: 272px;
  height: 40px;
  border: none;
  font-size: 16px;
  margin-top: 7px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const StrengthInput = styled.input`
  width: 272px;
  height: 40px;
  border: none;
  font-size: 16px;
  text-indent: 12px;
  margin-top: 13px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const TargetingQueriesSelect = styled.select`
  width: 272px;
  height: 40px;
  border: none;
  margin-top: 13px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const ConversionTagCheckboxWrapper = styled.div`
  margin-top: 5px;
`;

const ConversionTagNotice = styled.div`
  font-size: 10px;
  color: red;
`;

const ConversionTagCheckboxLabel = styled.label`
  padding-left: 5px;
`;

const ConversionTagCheckboxInput = styled.input``;

const PublishWrapper = styled.div`
  text-align: right;
  margin-top: 35px;
`;

const Publish = styled.button`
  ${heading}
  right: 0px;
  font-size: 14px;
  line-height: 1.71;
  border-radius: 18px;
  color: #ffffff;
  width: 112px;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
`;
