import actionCreatorFactory, { Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Reducer } from "redux";
import produce from "immer";

import { AdCampaignStatus } from "./adCampaign";
import { AdSetStatus } from "./adSet";

//  Types
// -----------------------------------------------

export interface AdAccountNavigationResponse {
  id: string;
  name: string;
  adCampaigns: AdCampaignNavigationResponse[];
  conversionTags: ConversionTagNavigationResponse[];
}

interface AdCampaignNavigationResponse {
  id: string;
  name: string;
  status: AdCampaignStatus;
  adSets: AdSetNavigationResponse[];
}

interface AdSetNavigationResponse {
  id: string;
  name: string;
  status: AdSetStatus;
}

interface ConversionTagNavigationResponse {
  id: string;
  name: string;
  adAccountId: string;
}

export interface AdAccountSummaryResponse {
  id: string;
  name: string;
  adCampaigns: AdAccountSummaryCampaignResponse[];
}

interface AdAccountSummaryCampaignResponse {
  id: string;
  name: string;
  totalBudget: string;
  dailyBudget: string;
  enabled: boolean;
  adAccountId: string;
  status: AdCampaignStatus;
  report?: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
}

export interface ConversionTagSummaryResponse {
  id: string;
  name: string;
  adAccountId: string;
  report: {
    conversion: string;
  };
}

export interface AdAccountsResponse {
  adAccounts: AdAccountsResponseAdAccount[];
}

interface AdAccountsResponseAdAccount {
  id: string;
  name: string;
}

export interface IdAdAccount {
  [id: string]: AdAccount;
}

export interface IdConversionTag {
  [id: string]: ConversionTag;
}

export interface AdAccount {
  id: string;
  name: string;
  adCampaigns: string[];
  conversionTags: string[];
}

export interface ConversionTag {
  id: string;
  name: string;
  adAccountId: string;
  report?: {
    conversion: string;
  };
}

export interface CreateAdAccountRequest {
  name: string;
}

export interface CreateAdAccountResponse {
  id: string;
  name: string;
}

export interface UpdateAdAccountRequest {
  id: string;
  name: string;
}

export interface UpdateAdAccountResponse {
  id: string;
  name: string;
}

export interface CreateConversionTagRequest {
  name: string;
  adAccountId: string;
}

export interface CreateConversionTagResponse {
  id: string;
  name: string;
  adAccountId: string;
}

export interface UpdateConversionTagRequest {
  id: string;
  name: string;
  adAccountId: string;
}

export interface UpdateConversionTagResponse {
  id: string;
  name: string;
  adAccountId: string;
}

//  State
// -----------------------------------------------

export interface AdAccountState {
  adAccounts: IdAdAccount;
  adAccountIds: string[];
  conversionTags: IdConversionTag;
}

export const InitialState: AdAccountState = {
  adAccounts: {},
  adAccountIds: [],
  conversionTags: {},
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("AD_ACCOUNT");

export const fetchAdAccountSummary = actionCreator.async<
  { adAccountId: string },
  AdAccountSummaryResponse,
  Error
>("FETCH_AD_ACCOUNT_SUMMARY");

export const fetchAdAccountNavigation = actionCreator.async<
  { adAccountId: string },
  AdAccountNavigationResponse,
  Error
>("FETCH_AD_ACCOUNT_NAVIGATION");

export const fetchAdAccounts = actionCreator.async<
  void,
  AdAccountsResponse,
  Error
>("FETCH_AD_ACCOUNTS");

export const createAdAccount = actionCreator.async<
  CreateAdAccountRequest,
  CreateAdAccountResponse,
  Error
>("CREATE_AD_ACCOUNT");

export const updateAdAccount = actionCreator.async<
  UpdateAdAccountRequest,
  UpdateAdAccountResponse,
  Error
>("UPDATE_AD_ACCOUNT");

// FIXME: createAdCampaign from "/adCampaign" をreducerの処理に追加するべきだが、
// importするとundefiedになってしまうため、別でactionを定義している
export interface AdCampaignCreated {
  adAccountId: string;
  adCampaignId: string;
}
export const adCampaignCreated = actionCreator<AdCampaignCreated>(
  "AD_CAMPAIGN_CREATED"
);

export const fetchConversionTagSummary = actionCreator.async<
  { adAccountId: string; conversionTagId: string },
  ConversionTagSummaryResponse,
  Error
>("FETCH_CONVERSION_TAG_SUMMARY");

export const createConversionTag = actionCreator.async<
  CreateConversionTagRequest,
  CreateConversionTagResponse,
  Error
>("CREATE_CONVERSION_TAG");

export const updateConversionTag = actionCreator.async<
  UpdateConversionTagRequest,
  UpdateConversionTagResponse,
  Error
>("UPDATE_CONVERSION_TAG");

//  Reducer
// -----------------------------------------------

const adAccountReducer: Reducer<AdAccountState> = reducerWithInitialState<
  AdAccountState
>(InitialState)
  .case(fetchAdAccountNavigation.done, (state, { result }) =>
    produce(state, (draft: AdAccountState) => {
      const adAccountId = result.id;
      if (draft.adAccounts[adAccountId]) {
        Object.assign(draft.adAccounts[adAccountId], result, {
          adCampaigns: result.adCampaigns.map(adCapmpaign => adCapmpaign.id),
          conversionTags: result.conversionTags.map(conversionTag =>
            conversionTag.id.toString()
          ),
        });
      } else {
        draft.adAccounts[adAccountId] = {
          ...result,
          adCampaigns: result.adCampaigns.map(adCapmpaign => adCapmpaign.id),
          conversionTags: result.conversionTags.map(conversionTag =>
            conversionTag.id.toString()
          ),
        };
        draft.adAccountIds.push(adAccountId);
        draft.adAccountIds.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      draft.adAccounts[adAccountId].adCampaigns.sort((a, b) => {
        if (a < b) {
          return 1;
        } else {
          return -1;
        }
      });
      result.conversionTags.forEach(conversionTag => {
        if (!draft.conversionTags[conversionTag.id]) {
          draft.conversionTags[conversionTag.id] = conversionTag;
        }
      });
    })
  )
  .case<Success<void, AdAccountsResponse>>(
    fetchAdAccounts.done,
    (state, { result: adAccountsResponse }) =>
      produce(state, (draft: AdAccountState) => {
        adAccountsResponse.adAccounts.forEach(adAccount => {
          if (!draft.adAccounts[adAccount.id]) {
            draft.adAccounts[adAccount.id] = {
              ...adAccount,
              adCampaigns: [],
              conversionTags: [],
            };
            draft.adAccountIds.push(adAccount.id);
          }
        });
        draft.adAccountIds.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
      })
  )
  .case(
    fetchAdAccountSummary.done,
    (state, { result: adAccountSummaryResponse }) =>
      produce(state, (draft: AdAccountState) => {
        const adAccountId = adAccountSummaryResponse.id;
        if (draft.adAccounts[adAccountId]) {
          Object.assign(
            draft.adAccounts[adAccountId],
            adAccountSummaryResponse,
            {
              adCampaigns: adAccountSummaryResponse.adCampaigns.map(
                adCapmpaign => adCapmpaign.id
              ),
            }
          );
        } else {
          draft.adAccounts[adAccountId] = {
            ...adAccountSummaryResponse,
            adCampaigns: adAccountSummaryResponse.adCampaigns.map(
              adCapmpaign => adCapmpaign.id
            ),
            conversionTags: [],
          };
        }
        draft.adAccounts[adAccountId].adCampaigns.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
      })
  )
  .case(createAdAccount.done, (state, { result: createdAdAccount }) =>
    produce(state, (draft: AdAccountState) => {
      draft.adAccounts[createdAdAccount.id] = {
        ...createdAdAccount,
        adCampaigns: [],
        conversionTags: [],
      };
      draft.adAccountIds.push(createdAdAccount.id);
      draft.adAccountIds.sort((a, b) => {
        if (a < b) {
          return 1;
        } else {
          return -1;
        }
      });
    })
  )
  .case(updateAdAccount.done, (state, { result: updatedAdAccount }) =>
    produce(state, (draft: AdAccountState) => {
      const { id, name } = updatedAdAccount;
      draft.adAccounts[id].name = name;
    })
  )
  .case(adCampaignCreated, (state, adCampaignCreated) =>
    produce(state, (draft: AdAccountState) => {
      const { adCampaignId, adAccountId } = adCampaignCreated;
      draft.adAccounts[adAccountId].adCampaigns.push(adCampaignId);
      draft.adAccounts[adAccountId].adCampaigns.sort((a, b) => {
        if (a < b) {
          return 1;
        } else {
          return -1;
        }
      });
    })
  )
  .case(createConversionTag.done, (state, { result: createdConversionTag }) =>
    produce(state, (draft: AdAccountState) => {
      const { adAccountId } = createdConversionTag;
      let { id } = createdConversionTag;
      id = id.toString();
      if (!draft.conversionTags[id]) {
        draft.conversionTags[id] = createdConversionTag;
      }
      draft.adAccounts[adAccountId].conversionTags.push(id);
      draft.adAccounts[adAccountId].conversionTags.sort((a, b) => {
        if (a < b) {
          return 1;
        } else {
          return -1;
        }
      });
    })
  )
  .case(updateConversionTag.done, (state, { result: updatedConversionTag }) =>
    produce(state, (draft: AdAccountState) => {
      let { id } = updatedConversionTag;
      id = id.toString();
      draft.conversionTags[id] = updatedConversionTag;
    })
  )
  .case(fetchConversionTagSummary.done, (state, { result }) =>
    produce(state, (draft: AdAccountState) => {
      const id = result.id.toString();
      draft.conversionTags[id] = result;
    })
  );

export default adAccountReducer;
