import React from "react";
import styled, { css } from "styled-components";
import { Link as LinkTag } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import colors from "../../styles/color";
import { truncateLines } from "../../utils/styles";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import { linkSelectorFactory } from "../../store/selectors/link";
import { linkSelected, LinkSelectedParams } from "../../store/modules/ui";
import {
  focusedLinkListUuidSelector,
  focusedLinkUuidSelector,
} from "../../store/selectors/ui";

interface OwnProps {
  linkUuid: string;
  linkListUuid: string;
  sceneEntryId: string;
  isDragging: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

function LinkItem(props: Props) {
  //  props & state
  // -----------------------------------------------

  const {
    link,
    linkUuid,
    linkListUuid,
    focusedLinkUuid,
    focusedLinkListUuid,
    isDragging,
  } = props;

  //  function
  // -----------------------------------------------

  const handleClickLinkItem = () => {
    const { linkListUuid, linkUuid, sceneEntryId } = props;
    props.linkSelected({ linkListUuid, linkUuid, sceneEntryId });
  };

  //  jsx
  // -----------------------------------------------

  if (!link) {
    return null;
  }

  return (
    <Container
      isSelected={
        linkListUuid === focusedLinkListUuid && linkUuid === focusedLinkUuid
      }
    >
      <StyledLink
        to={`/admin/articles/link_list_items/${link.uuid}/edit`}
        onClick={handleClickLinkItem}
      >
        <Inner isDragging={isDragging}>
          <Body>
            <Title>{link.title}</Title>
            <Media>{link.media}</Media>
          </Body>
          <div>{link.imageUrl && <Image src={link.imageUrl} />}</div>
        </Inner>
      </StyledLink>
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkItem);

function mapStateToProps(state: RootState, props: OwnProps) {
  const { linkUuid } = props;
  return {
    link: linkSelectorFactory(linkUuid)(state),
    focusedLinkListUuid: focusedLinkListUuidSelector(state),
    focusedLinkUuid: focusedLinkUuidSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      linkSelected: ({
        linkListUuid,
        linkUuid,
        sceneEntryId,
      }: LinkSelectedParams) =>
        linkSelected({ linkListUuid, linkUuid, sceneEntryId }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

interface ContainerStyle {
  isSelected: boolean;
}

interface InnerStyle {
  isDragging: boolean;
}

const Container = styled.div`
  padding: 0 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  ${(props: ContainerStyle) =>
    props.isSelected
      ? css`
          background-color: rgba(0, 0, 0, 0.03);
        `
      : css``}
`;

const StyledLink = styled(LinkTag)`
  text-decoration: none;
`;

const Inner = styled.div`
  height: 80px;
  box-sizing: border-box;
  padding: 12px 0;
  border-top: ${(props: InnerStyle) =>
    props.isDragging ? `` : `1px solid ${colors.blackForeground50}`};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
  max-width: 236px;
`;

const Title = styled.div`
  flex-shrink: 1;
  font-size: 14px;
  line-height: 1.43;
  color: ${colors.blackForeground800};
  ${heading};
  ${truncateLines(2)}
`;

const Media = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
  ${truncateLines(1)}
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
  flex-shrink: 0;
`;
