import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import styled, { css } from "styled-components";

import color from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  updateAdContent,
  createAdContent,
} from "../../store/modules/adContent";
import Icon from "../../lib/Icon";
import colors from "../../styles/color";

interface Props {
  adContentId: string;
}

type InputEvent = React.FormEvent<HTMLInputElement>;
type TextAreaEvent = React.FormEvent<HTMLTextAreaElement>;

function useField(
  s: string
): [string, (e: InputEvent | TextAreaEvent) => void] {
  const [v, setValue] = useState(s);
  return [
    v,
    ({ currentTarget: el }: InputEvent | TextAreaEvent) => {
      setValue(el.value);
    },
  ];
}

function useImageField(): [string | undefined, (e: InputEvent) => void] {
  const [image, updateImage] = useState<string | undefined>();
  return [
    image,
    ({ currentTarget: el }: InputEvent) => {
      if (!el || !el.files) {
        return;
      }
      const file = el.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        updateImage((e.target as any).result);
      };
    },
  ];
}

function AdContentSettingsContainer({ adContentId }: Props) {
  const adContents = useSelector(
    ({ adContent: ac }: RootState) => ac.adContents
  );
  const dispatch = useDispatch();

  const adContent = adContents[adContentId];

  const [name, updateName] = useField(adContent?.name || "");
  const [title, updateTitle] = useField(adContent?.title || "");
  const [sourceName, updateSourceName] = useField(adContent?.sourceName || "");
  const [finalUrl, updateFinalUrl] = useField(adContent?.finalUrl || "");
  const [onPageloadFinishedScript, updateOnPageloadFinishedScript] = useField(
    adContent?.onPageloadFinishedScript || ""
  );
  const [javascriptEnabledUrl, updateJavascriptEnabledUrl] = useField(
    adContent?.javascriptEnabledUrl || ""
  );
  const [rawImage, updateRawImage] = useImageField();

  const image = rawImage || adContent?.imageUrl;

  const routeParams = useParams();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      adAccountId: routeParams["ad_account_id"],
      adCampaignId: routeParams["ad_campaign_id"],
      adSetId: routeParams["ad_set_id"],
      name,
      title,
      sourceName,
      finalUrl,
      onPageloadFinishedScript,
      javascriptEnabledUrl,
    };

    if (adContentId) {
      dispatch(
        updateAdContent.started({ id: adContentId, ...payload, rawImage })
      );
    } else {
      if (rawImage == null) {
        return;
      }
      dispatch(createAdContent.started({ ...payload, rawImage }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Header>Name</Header>
      <InputText value={name} onChange={updateName} />
      <Header>Title</Header>
      <InputText value={title} onChange={updateTitle} />
      <Header>Source Name</Header>
      <InputText value={sourceName} onChange={updateSourceName} />
      <Header>Final URL</Header>
      <InputText value={finalUrl} onChange={updateFinalUrl} />
      <Header>JavaScript Enabled URL</Header>
      <InputText
        value={javascriptEnabledUrl}
        onChange={updateJavascriptEnabledUrl}
      />
      <Header>On Pageload Finished Script</Header>
      <TextArea
        value={onPageloadFinishedScript}
        onChange={updateOnPageloadFinishedScript}
      />
      <ImageUploadWrapper>
        <ImageLabel htmlFor="upload-image" imageUrl={image}>
          {image ? null : <CameraIcon name="wt-icon-camera" />}
          <ImageUploadInput
            id="upload-image"
            type="file"
            onChange={updateRawImage}
          />
        </ImageLabel>
      </ImageUploadWrapper>
      <PublishWrapper>
        <Publish>Publish</Publish>
      </PublishWrapper>
    </form>
  );
}

//  connect
// -----------------------------------------------

export default AdContentSettingsContainer;

const Header = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  color: ${color.blackForeground840};
`;

const InputText = styled.input`
  width: 272px;
  height: 40px;
  margin-top: 12px;
  text-indent: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
`;

const TextArea = styled.textarea`
  width: 272px;
  height: 120px;
  border: none;
  border-radius: 3px;
  padding-left: 12px;
  margin-top: 13px;
  color: ${colors.blackForeground840};
  background-color: rgba(0, 0, 0, 0.03);
`;

const ImageUploadWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
`;

const ImageLabel = styled.label<{ imageUrl: string }>`
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 4px;
  color: ${colors.blackForeground400};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.imageUrl
      ? css`
          background-image: url(${props.imageUrl});
          background-size: 100% 100%;
        `
      : css`
          background-color: rgba(0, 0, 0, 0.03);
        `}
`;

const ImageUploadInput = styled.input`
  &[type="file"] {
    display: none;
  }
`;

const CameraIcon = styled(Icon)`
  position: absolute;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: ${colors.blackForeground400};
`;

const PublishWrapper = styled.div`
  text-align: right;
`;

const Publish = styled.button`
  ${heading}
  font-size: 14px;
  line-height: 1.71;
  border-radius: 14px;
  margin-top: 20px;
  color: #ffffff;
  width: 112px;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
`;
