import actionCreatorFactory, { Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { Reducer } from "redux";

import { LinkListResponse, LinkList, IdSubject } from "./linkList";

//  Types
// -----------------------------------------------

export interface SceneEntry {
  id: string;
  sceneEvent: {
    uuid: string | null;
    headline: string;
    badge: string;
  };
  createdAt: string | null;
  updatedAt: string | null;
  contentEntries: ContentEntry[];
}

export interface SceneEntryResponse {
  id: string;
  sceneEvent: {
    uuid: string | null;
    headline: string;
    badge: string;
  };
  createdAt: string;
  updatedAt: string;
  contentEntries: ContentEntryResponse[];
}

export interface ContentEntry {
  id: string;
  deliverableFrom: string | null;
  deliverableUntil: string | null;
  sceneEntryId: string;
  contentUuid: string;
  order: string;
  createdAt: string | null;
  updatedAt: string | null;
  content: string;
}

export interface ContentEntryResponse {
  id: string;
  deliverableFrom: string;
  deliverableUntil: string;
  sceneEntryId: string;
  contentUuid: string;
  createdAt: string;
  updatedAt: string;
  content: ContentResponse;
}

export type ContentResponse = LinkListResponse;
export type Content = LinkList;

//  State
// -----------------------------------------------

export interface SceneEntryState {
  sceneEntries: SceneEntry[];
  maxContentEntryId: number;
  maxSceneEntryId: number;
  lastPublishedAt: string | null;
}

export const initialState: SceneEntryState = {
  sceneEntries: [],
  maxContentEntryId: 1,
  maxSceneEntryId: 1,
  lastPublishedAt: null,
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("SCENE_ENTRY");

//  FETCH_SCENES
// -----------------------
export interface FetchScenesResponse {
  sceneEntries: SceneEntryResponse[];
  subjects: IdSubject;
  lastPublishedAt: string | null;
}
export const fetchSceneEntries = actionCreator.async<
  void,
  FetchScenesResponse,
  Error
>("FETCH_SCENES");

//  ADD_SCENE_ENTRY
// -----------------------
export const addSceneEntry = actionCreator<{ sceneEntry: SceneEntry }>(
  "ADD_SCENE_ENTRY"
);

//  ADD_CONTENT
// -----------------------
export interface AddContentParams {
  sceneEntryId: string;
  linkList: LinkList;
  contentEntry: ContentEntry;
}
export const addContent = actionCreator<AddContentParams>("ADD_CONTENT");

//  PUBLISH
// -----------------------
export interface PublishParams {
  sceneEntries: SceneEntryResponse[];
}
export interface PublishResponse {
  sceneEntries: SceneEntryResponse[];
  lastPublishedAt: string;
}
export const publishSceneEntries = actionCreator.async<
  PublishParams,
  PublishResponse,
  Error
>("PUBLISH");

//  UPDATE_SCENE_ENTRY
// -----------------------
export interface UpdateSceneEntryParams {
  sceneEntryId: string;
  headline: string;
}
export const updateSceneEntry = actionCreator<UpdateSceneEntryParams>(
  "UPDATE_SCENE_ENTRY"
);

//  UPDATE_LINK_LIST
// -----------------------
export interface Range {
  deliverableFrom: string | null;
  deliverableUntil: string | null;
}
export interface UpdateLinkListParams {
  linkList: LinkList;
  sceneEntryId: string;
  range?: Range;
}
export const updateLinkList = actionCreator<UpdateLinkListParams>(
  "UPDATE_LINK_LIST"
);

export interface LinkListDragged {
  sceneEntryId: string;
  startIndex: number;
  endIndex: number;
}
export const linkListDragged = actionCreator<LinkListDragged>("DRAG_LINK_LIST");

//  Reducer
// -----------------------------------------------

const sceneEntryReducer: Reducer<SceneEntryState> = reducerWithInitialState<
  SceneEntryState
>(initialState)
  .cases<
    Success<void, FetchScenesResponse>,
    Success<PublishParams, PublishResponse>
  >(
    [fetchSceneEntries.done, publishSceneEntries.done],
    (state, { result: { sceneEntries, lastPublishedAt } }) =>
      produce(state, (draft: SceneEntryState) => {
        const ses = sceneEntries.map(sceneEntry => {
          const contentEntries = sceneEntry.contentEntries.map(contentEntry => {
            return {
              ...contentEntry,
              content: contentEntry.content.uuid,
            } as ContentEntry;
          });
          return {
            ...sceneEntry,
            contentEntries,
          };
        });
        draft.sceneEntries = ses;
        draft.lastPublishedAt = lastPublishedAt;
      })
  )
  .case(addContent, (state, { sceneEntryId, contentEntry }) =>
    produce(state, (draft: SceneEntryState) => {
      const se = draft.sceneEntries.find(se => se.id === sceneEntryId);
      if (!se) {
        return;
      }
      se.contentEntries.push(contentEntry);
      draft.maxContentEntryId += 1;
    })
  )
  .case(addSceneEntry, (state, { sceneEntry }) =>
    produce(state, (draft: SceneEntryState) => {
      const se = { ...sceneEntry, contentEntries: [] } as SceneEntry;
      draft.sceneEntries.push(se);
      draft.maxSceneEntryId += 1;
    })
  )
  .case(updateSceneEntry, (state, { sceneEntryId, headline }) =>
    produce(state, (draft: SceneEntryState) => {
      const sceneEntry = draft.sceneEntries.find(se => se.id === sceneEntryId);
      if (!sceneEntry) {
        return;
      }
      sceneEntry.sceneEvent.headline = headline;
    })
  )
  .case(updateLinkList, (state, { linkList, range, sceneEntryId }) =>
    produce(state, (draft: SceneEntryState) => {
      const sceneEntry = draft.sceneEntries.find(se => se.id === sceneEntryId);
      if (!sceneEntry) {
        return;
      }
      const contentEntry = sceneEntry.contentEntries.find(
        ce => ce.contentUuid === linkList.uuid
      );
      if (!contentEntry) {
        return;
      }
      if (!range) {
        return;
      }
      contentEntry.deliverableFrom = range.deliverableFrom;
      contentEntry.deliverableUntil = range.deliverableUntil;
    })
  )
  .case(linkListDragged, (state, { sceneEntryId, startIndex, endIndex }) =>
    produce(state, (draft: SceneEntryState) => {
      const sceneEntry = draft.sceneEntries.find(se => {
        return se.id.toString() === sceneEntryId;
      });
      if (!sceneEntry) {
        return;
      }
      const { contentEntries } = sceneEntry;
      const [removed] = contentEntries.splice(startIndex, 1);
      contentEntries.splice(endIndex, 0, removed);
    })
  );

export default sceneEntryReducer;
