import actionCreatorFactory, { Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Reducer } from "redux";
import produce from "immer";

import { fetchSceneEntries, FetchScenesResponse } from "./sceneEntry";

//  Types
// -----------------------------------------------

export interface Link {
  uuid: string;
  url: string;
  title: string;
  media: string;
  imageUrl: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface UuidLink {
  [uuid: string]: Link;
}

//  State
// -----------------------------------------------

export interface LinkState {
  links: UuidLink;
  searchedLinkUuids: string[];
}

export const initialState: LinkState = {
  links: {},
  searchedLinkUuids: [],
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("LINK");

//  CHANGE_LINK
// -----------------------
export interface ChangeLinkParams {
  link: Link;
  rawImage?: string;
  linkListUuid?: string;
}
export interface ChangeLinkResponse {
  link: Link;
}
export const changeLink = actionCreator.async<
  ChangeLinkParams,
  ChangeLinkResponse,
  Error
>("CHANGE_LINK");

//  ADD_LINK_LIST_ITEM
// -----------------------
export interface AddLinkListItemParams {
  linkListUuid: string;
}
export interface AddLinkListItemResponse {
  link: Link;
}
export const addLinkListItem = actionCreator.async<
  AddLinkListItemParams,
  AddLinkListItemResponse,
  Error
>("ADD_LINK_LIST_ITEM");

//  ADD_NEW_LINK
// -----------------------
export interface AddNewLinkResponse {
  link: Link;
}
export const addNewLink = actionCreator.async<void, AddNewLinkResponse, Error>(
  "ADD_NEW_LINK"
);

// SEARCH_LINKS
// -----------------------
export interface SearchLinksParams {
  word: string;
}
export interface SearchLinksResponse {
  links: Link[];
}
export const searchLinks = actionCreator.async<
  SearchLinksParams,
  SearchLinksResponse,
  Error
>("SEARCH_LINKS");

//  Reducer
// -----------------------------------------------

const LinkReducer: Reducer<LinkState> = reducerWithInitialState<LinkState>(
  initialState
)
  .case<Success<void, FetchScenesResponse>>(
    fetchSceneEntries.done,
    (state, { result: { sceneEntries } }) =>
      produce(state, (draft: LinkState) => {
        const links = draft.links;
        sceneEntries.forEach(se => {
          se.contentEntries.forEach(ce => {
            ce.content.links.forEach(link => {
              if (!links[link.uuid]) {
                links[link.uuid] = link;
              }
            });
          });
        });
      })
  )
  .case(changeLink.started, (state, { link }) =>
    produce(state, (draft: LinkState) => {
      draft.links[link.uuid] = { ...link };
    })
  )
  .case(changeLink.done, (state, { result: { link } }) =>
    // TODO:(kdnk) Update only createdAt and UpdateAt
    produce(state, (draft: LinkState) => {
      draft.links[link.uuid] = { ...link };
    })
  )
  .case(addLinkListItem.done, (state, { result: { link } }) =>
    produce(state, (draft: LinkState) => {
      draft.links[link.uuid] = link;
    })
  )
  .case<Success<void, AddNewLinkResponse>>(
    addNewLink.done,
    (state, { result: { link } }) =>
      produce(state, (draft: LinkState) => {
        draft.links[link.uuid] = link;
      })
  )
  .case(searchLinks.done, (state, { result: { links } }) =>
    produce(state, (draft: LinkState) => {
      if (!links) {
        draft.searchedLinkUuids = [];
        return;
      }
      links.forEach(link => {
        if (!draft.links[link.uuid]) {
          draft.links[link.uuid] = link;
        }
      });
      draft.searchedLinkUuids = links.map(l => l.uuid);
    })
  );

export default LinkReducer;
