import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import colors from "../styles/color";
import { heading } from "../styles/font";
import { RootState } from "../store/modules";
import { fetchAdCampaignSummary } from "../store/modules/adCampaign";
import { adSetFocused } from "../store/modules/ui";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

class AdSetListContainer extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    this.props.fetchAdCampaignSummary({
      adAccountId: params["ad_account_id"],
      adCampaignId: params["ad_campaign_id"],
    });
    this.handleClickNewAdSetLink = this.handleClickNewAdSetLink.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      if (!this.props.match) {
        return null;
      }
      const { params } = this.props.match;
      this.props.fetchAdCampaignSummary({
        adAccountId: params["ad_account_id"],
        adCampaignId: params["ad_campaign_id"],
      });
    }
  }

  // jsx
  // -----------------------------------------------

  public render() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const adCampaignId = params["ad_campaign_id"];
    const { idAdCampaign, idAdSet, focusedAdSetId } = this.props;
    const adCampaign = idAdCampaign[adCampaignId];

    return (
      <Container>
        <Wrapper>
          <SearchInput />
          <NewAdSetLink
            to={`/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/ad_sets/new`}
            onClick={this.handleClickNewAdSetLink}
          >
            Create Ad Set
          </NewAdSetLink>
        </Wrapper>
        <ContentsTable>
          <HeaderRow>
            <AdSetNameHeader>Ad Set</AdSetNameHeader>
            <AdSetHeader>Spent Budget</AdSetHeader>
            <AdSetHeader>Imp</AdSetHeader>
            <AdSetHeader>Click</AdSetHeader>
            <AdSetHeader>Ctr</AdSetHeader>
            <AdSetHeader>Cpc</AdSetHeader>
            <StatusHeader />
          </HeaderRow>
          {!!adCampaign &&
            adCampaign.adSets.map(adSetId => {
              const adSet = idAdSet[adSetId];
              const adSetReport = adSet.report;
              if (!adSetReport) {
                return;
              }
              return (
                <TableRow
                  key={adSetId}
                  focused={Number(adSetId) === Number(focusedAdSetId)}
                  onClick={() => this.props.focusAdSet({ adSetId })}
                >
                  <td>
                    <AdSetName>{adSet.name}</AdSetName>
                  </td>
                  <td>
                    <ReportData>
                      {adSetReport.spentBudget.toLocaleString()}
                    </ReportData>
                  </td>
                  <td>
                    <ReportData>
                      {adSetReport.impression.toLocaleString()}
                    </ReportData>
                  </td>
                  <td>
                    <ReportData>
                      {adSetReport.click.toLocaleString()}
                    </ReportData>
                  </td>
                  <td>
                    <ReportData>{adSetReport.ctr}</ReportData>
                  </td>
                  <td>
                    <ReportData>{adSetReport.cpc}</ReportData>
                  </td>
                  <td>
                    <Status>
                      {idAdSet[adSetId].status === "ACTIVE" && (
                        <ActiveIndicator />
                      )}
                      {idAdSet[adSetId].status === "STOPPED" && (
                        <StoppedIndicator />
                      )}
                      {idAdSet[adSetId].status === "OUT_OF_PERIOD" && (
                        <OutOfPeriodIndicator />
                      )}
                    </Status>
                  </td>
                </TableRow>
              );
            })}
        </ContentsTable>
      </Container>
    );
  }

  private handleClickNewAdSetLink() {
    this.props.focusAdSet({ adSetId: "" });
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdSetListContainer)
);

function mapStateToProps(state: RootState) {
  const { adCampaigns: idAdCampaign } = state.adCampaign;
  const { adSets: idAdSet } = state.adSet;
  const { focusedAdSetId } = state.ui.adCampaignSummary;
  return { idAdCampaign, idAdSet, focusedAdSetId };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchAdCampaignSummary: (params: {
        adAccountId: string;
        adCampaignId: string;
      }) => fetchAdCampaignSummary.started(params),
      focusAdSet: (params: { adSetId: string }) => adSetFocused(params),
    },
    dispatch
  );
}

const Container = styled.div`
  min-width: 640px;
  margin: 71px auto auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
`;

const SearchInput = styled.input`
  border-style: none;
  height: 40px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-right: 20px;
  width: 100%;
  outline: 0;
`;

const NewAdSetLink = styled(Link)`
  ${heading}
  line-height: 1.71;
  border-radius: 24px;
  color: #ffffff;
  width: 161px;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  padding: 8px 32px;
  flex-shrink: 0;
`;

const ContentsTable = styled.table`
  min-width: 640px;
`;

const HeaderRow = styled.tr`
  height: 30px;
`;

const AdSetNameHeader = styled.th`
  text-indent: 7px;
  text-align: left;
`;
const AdSetHeader = styled.th`
  text-align: right;
`;
const StatusHeader = styled.th``;

const TableRow = styled.tr<{ focused: boolean }>`
  border-top: 1px solid ${colors.blackForeground50};
  ${props =>
    props.focused
      ? css`
          background-color: rgba(0, 0, 0, 0.03);
        `
      : css``}
  cursor: pointer;
`;
const AdSetName = styled.div`
  ${heading}
  max-width: 256px;
  margin: 12px auto 11px 7px;
  font-size: 14px;
  line-height: 1.71;
  color: ${colors.blackForeground800};
  word-wrap: break-word;
`;
const ReportData = styled.div`
  margin-left: 10px;
  text-align: right;
  color: ${colors.blackForeground840};
`;

const Status = styled.div`
  margin: auto 20px;
`;

const ActiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #08c5e7;
`;
const StoppedIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
`;

const OutOfPeriodIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  background-color: #f65948;
`;
