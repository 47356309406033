import React from "react";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";

import { DailyReport } from "../store/modules/adSet";

interface Props {
  rawData: DailyReport[];
}

const AdReport = (props: Props) => {
  const data: DailyReportData[] = props.rawData.map((rawDatum, index) => ({
    key: index,
    date: rawDatum.date,
    spentBudget: rawDatum.spentBudget,
    impressionCount: rawDatum.impressionCount,
    clickCount: rawDatum.clickCount,
    cpc: rawDatum.cpc,
    ctr: rawDatum.ctr,
  }));

  return <Table<DailyReportData> columns={columns} dataSource={data} />;
};

interface DailyReportData {
  key: number;
  date: string;
  spentBudget: string;
  impressionCount: string;
  clickCount: string;
  ctr: string;
  cpc: string;
}

const columns: Array<ColumnProps<DailyReportData>> = [
  {
    key: "date",
    title: "Date",
    dataIndex: "date",
    sortDirections: ["descend"],
  },
  {
    key: "spentBudget",
    title: "Spent Budget",
    dataIndex: "spentBudget",
    align: "right",
  },
  {
    key: "impressionCount",
    title: "imp",
    dataIndex: "impressionCount",
    align: "right",
  },
  {
    key: "clickCount",
    title: "click",
    dataIndex: "clickCount",
    align: "right",
  },
  {
    key: "cpc",
    title: "cpc",
    dataIndex: "cpc",
    align: "right",
  },
  {
    key: "ctr",
    title: "ctr",
    dataIndex: "ctr",
    align: "right",
  },
];

export default AdReport;
