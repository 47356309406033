import React from "react";
import { RouteChildrenProps } from "react-router";

import RootTemplate from "./RootTemplate";
import LinkEditContainer from "./LinkEditContainer";

interface Params {
  uuid: string;
}

function LinkEditPage(props: RouteChildrenProps) {
  if (!props.match) {
    return null;
  }

  let uuid = "";
  uuid = (props.match.params as Params).uuid;
  if (!uuid) {
    return null;
  }

  const render = (uuid: string) => {
    return () => <LinkEditContainer uuid={uuid} />;
  };
  return <RootTemplate render={render(uuid)} />;
}

export default LinkEditPage;
