import React from "react";

import RootTemplate from "./RootTemplate";
import AdSetSummaryContainer from "./AdSetSummaryContainer";

function AdSetSummaryPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <AdSetSummaryContainer />;
}

export default AdSetSummaryPage;
