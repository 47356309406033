import React from "react";
import styled from "styled-components";
import { Link as LinkTag } from "react-router-dom";

import colors from "../../styles/color";
import { truncateLines } from "../../utils/styles";
import { heading } from "../../styles/font";

interface Props {
  linkUuid: string;
  title: string;
  media: string;
  imageUrl: string;
  isDragging: boolean;
  createdAt: string;
  updatedAt: string;
}

function LinkItem(props: Props) {
  //  props & state
  // -----------------------------------------------

  const { linkUuid, isDragging } = props;

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      <StyledLink to={`/admin/articles/links/${linkUuid}/edit`}>
        <Inner isDragging={isDragging}>
          <Body>
            <Title>{props.title}</Title>
            <Meta>
              <Media>{props.media}</Media>
              <CreatedAt>
                Created: {new Date(props.createdAt).toLocaleString("ja")}
              </CreatedAt>
              <UpdatedAt>
                Edited: {new Date(props.updatedAt).toLocaleString("ja")}
              </UpdatedAt>
            </Meta>
          </Body>
          <div>{props.imageUrl && <Image src={props.imageUrl} />}</div>
        </Inner>
      </StyledLink>
    </Container>
  );
}

export default LinkItem;

//  style
// -----------------------------------------------

interface InnerStyle {
  isDragging: boolean;
}

const Container = styled.div`
  padding: 0 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const StyledLink = styled(LinkTag)`
  text-decoration: none;
`;

const Inner = styled.div`
  height: 80px;
  padding: 12px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: ${(props: InnerStyle) =>
    props.isDragging ? `` : `1px solid ${colors.blackForeground50}`};
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  flex-shrink: 1;
  margin-right: 16px;
  line-height: 1.43;
  color: ${colors.blackForeground800};
  ${heading};
  ${truncateLines(2)}
`;

const Meta = styled.div`
  display: flex;
`;

const Media = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
`;

const CreatedAt = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
  margin-left: 24px;
`;

const UpdatedAt = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
  margin-left: 8px;
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
`;
