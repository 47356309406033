import { all, call, put, takeLatest, fork } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import * as updatesContentsAdApi from "../../utils/api/updatesContentsAdApi";
import {
  UpdateAdContentRequest,
  UpdateAdContentResponse,
  updateAdContent,
  CreateAdContentRequest,
  CreateAdContentResponse,
  createAdContent,
} from "../modules/adContent";
import { history } from "../../App";

export function* handleCreateAdContent(action: Action<CreateAdContentRequest>) {
  try {
    const res: CreateAdContentResponse = yield call(
      updatesContentsAdApi.createAdContent(action.payload)
    );

    yield put(
      createAdContent.done({
        params: action.payload,
        result: res,
      })
    );
    const { adAccountId, adCampaignId, adSetId } = action.payload;
    history.push(
      `/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/ad_sets/${adSetId}/summary`
    );
  } catch (e) {
    yield put(
      createAdContent.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleUpdateAdContent(action: Action<UpdateAdContentRequest>) {
  try {
    const res: UpdateAdContentResponse = yield call(
      updatesContentsAdApi.updateAdContent(action.payload)
    );

    yield put(
      updateAdContent.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      updateAdContent.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

function* watchHandleUpdateAdContent() {
  yield takeLatest(updateAdContent.started, handleUpdateAdContent);
}

function* watchHandleCreateAdContent() {
  yield takeLatest(createAdContent.started, handleCreateAdContent);
}

export function* adContentWorker() {
  yield all([
    fork(watchHandleUpdateAdContent),
    fork(watchHandleCreateAdContent),
  ]);
}
