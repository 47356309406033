import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Droppable, Draggable } from "react-beautiful-dnd";

import colors from "../styles/color";
import { heading } from "../styles/font";
import { RootState } from "../store/modules";
import { searchLinks, addNewLink } from "../store/modules/link";
import { searchedLinksSelector } from "../store/selectors/link";
import { searchingLinksSelector } from "../store/selectors/ui";

import LinkItem from "./Link/LinkItem";
import SearchBoxContainer from "./Link/SearchBoxContainer";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function LinkIndexContainer(props: Props) {
  //  props & state
  // -----------------------------------------------

  const { links, loading } = props;
  if (!links) {
    return null;
  }

  //  functions
  // -----------------------------------------------

  const handleClickCreateNewLink = (e: React.SyntheticEvent) => {
    props.addNewLink();
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Droppable droppableId="link-index">
      {(provided, _snapshot) => (
        <Container ref={provided.innerRef} {...provided.droppableProps}>
          <Header>Link</Header>
          <SearchBoxContainer />
          {loading ? (
            <LoaderWrapper>
              <Loader src="/loader-circle.gif" alt="loader" />
            </LoaderWrapper>
          ) : (
            <>
              <Links>
                {links.map((link, index) =>
                  link ? (
                    <Draggable
                      key={link.uuid}
                      draggableId={link.uuid}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const style = {
                          backgroundColor: snapshot.isDragging
                            ? "#f7f7f7"
                            : "#fff",
                          boxShadow: snapshot.isDragging
                            ? "0 12px 24px 0 rgba(0, 0, 0, 0.1)"
                            : "",
                          display: "block",
                          ...provided.draggableProps.style,
                        };
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={style}
                          >
                            <LinkItem
                              linkUuid={link.uuid}
                              title={link.title}
                              imageUrl={link.imageUrl || ""}
                              media={link.media}
                              createdAt={link.createdAt}
                              updatedAt={link.updatedAt}
                              isDragging={snapshot.isDragging}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  ) : null
                )}
              </Links>
              <CreateNewLink onClick={handleClickCreateNewLink}>
                + Create a New Link from URL
              </CreateNewLink>
            </>
          )}
        </Container>
      )}
    </Droppable>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkIndexContainer);

function mapStateToProps(state: RootState) {
  return {
    links: searchedLinksSelector(state),
    loading: searchingLinksSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      searchLinks: ({ word: string }) => searchLinks.started({ word: string }),
      addNewLink: () => addNewLink.started(),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  width: 640px;
  margin: 72px auto 0;
`;

const Header = styled.div`
  ${heading}
  font-size: 32px;
  color: ${colors.blackForeground840};
  padding: 0 16px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const Loader = styled.img`
  width: 40px;
  height: 40px;
  opacity: 0.4;
`;

const Links = styled.div`
  margin-top: 28px;
`;

const CreateNewLink = styled.div`
  ${heading}
  padding: 12px 16px;
  margin-top: 18px;
  margin-bottom: 72px;
  font-size: 14px;
  line-height: 1.43;
  border-radius: 4px;
  color: ${colors.blackForeground800};
  background-color: ${colors.blackForeground25};
  cursor: pointer;
`;
