import { put, all, takeLatest, fork, call, delay } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import {
  changeLink,
  ChangeLinkParams,
  ChangeLinkResponse,
  searchLinks,
  SearchLinksResponse,
  addNewLink,
  AddNewLinkResponse,
  SearchLinksParams,
  addLinkListItem,
  AddLinkListItemResponse,
  AddLinkListItemParams,
} from "../modules/link";
import * as updatesContentsApi from "../../utils/api/updatesContentsApi";
import { history } from "../../App";

function* handleChangeLink(action: Action<ChangeLinkParams>) {
  const { link, rawImage, linkListUuid } = action.payload;
  yield delay(1000);
  try {
    const res = (yield call(
      updatesContentsApi.changeLink({ link, rawImage })
    )) as ChangeLinkResponse;
    yield put(changeLink.done({ params: { link, linkListUuid }, result: res }));
  } catch (e) {
    yield put(changeLink.failed({ params: { link, linkListUuid }, error: e }));
  }
}

function* handleSearchLinks(action: Action<SearchLinksParams>) {
  const { word } = action.payload;
  try {
    const res: SearchLinksResponse = yield call(
      updatesContentsApi.searchLinks({ word })
    );
    yield put(searchLinks.done({ params: { word }, result: res }));
  } catch (e) {
    yield put(searchLinks.failed({ params: { word }, error: e }));
  }
}

function* handleAddNewLink() {
  try {
    const res: AddNewLinkResponse = yield call(updatesContentsApi.addNewLink);
    yield put(addNewLink.done({ result: res }));
    const { link } = res;
    history.push(`/admin/articles/links/${link.uuid}/edit`);
  } catch (e) {
    yield put(addNewLink.failed({ error: e }));
  }
}

function* handleAddLinkListItem(action: Action<AddLinkListItemParams>) {
  const { linkListUuid } = action.payload;
  try {
    const res: AddLinkListItemResponse = yield call(
      updatesContentsApi.addNewLink
    );
    yield put(addLinkListItem.done({ params: { linkListUuid }, result: res }));
  } catch (e) {
    yield put(addLinkListItem.failed({ params: { linkListUuid }, error: e }));
  }
}

function* watchChangeLink() {
  yield takeLatest(changeLink.started, handleChangeLink);
}

function* watchSearchLinks() {
  yield takeLatest(searchLinks.started, handleSearchLinks);
}

function* watchAddNewLink() {
  yield takeLatest(addNewLink.started, handleAddNewLink);
}

function* watchAddLinkListItem() {
  yield takeLatest(addLinkListItem.started, handleAddLinkListItem);
}

export function* linkWorker() {
  yield all([
    fork(watchChangeLink),
    fork(watchSearchLinks),
    fork(watchAddNewLink),
    fork(watchAddLinkListItem),
  ]);
}
