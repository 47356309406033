import React from "react";
import styled, { css } from "styled-components";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  sceneEntriesSelector,
  lastPublishedAtSelector,
} from "../../store/selectors/sceneEntries";
import { linkListsSelector } from "../../store/selectors/linkList";
import { linksSelector } from "../../store/selectors/link";
import {
  publishSceneEntries,
  PublishParams,
  ContentEntryResponse,
  ContentEntry,
  SceneEntryResponse,
} from "../../store/modules/sceneEntry";
import { isArticleEditedSelector } from "../../store/selectors/ui";
import { Link } from "../../store/modules/link";
import { LinkListResponse } from "../../store/modules/linkList";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function PublishButtonContainer(props: Props) {
  //  props
  // -----------------------------------------------
  const {
    sceneEntries,
    linkLists,
    links,
    isArticleEdited,
    lastPublishedAt,
  } = props;

  //  functions
  // -----------------------------------------------

  const buildSceneEntriesForRequestBoby = () => {
    const ses: SceneEntryResponse[] = [];
    sceneEntries.forEach(sceneEntry => {
      const contentEntries: ContentEntryResponse[] = [];
      sceneEntry.contentEntries.forEach((contentEntry: ContentEntry) => {
        const linkList = linkLists[contentEntry.contentUuid];
        const linkListResponse = linkList as LinkListResponse & {
          links: string[];
        };
        const contentEntryResponse = {
          ...contentEntry,
          content: linkListResponse,
        } as ContentEntryResponse;
        if (String(contentEntryResponse.id).includes("DRAFT_ID:")) {
          contentEntryResponse.id = "";
        }
        if (String(contentEntryResponse.sceneEntryId).includes("DRAFT_ID:")) {
          contentEntryResponse.sceneEntryId = "";
        }
        contentEntries.push(contentEntryResponse);
      });
      const sceneEntryResponse = {
        ...sceneEntry,
        contentEntries,
      } as SceneEntryResponse;
      if (String(sceneEntryResponse.id).includes("DRAFT_ID:")) {
        sceneEntryResponse.id = "";
      }
      ses.push(sceneEntryResponse);
    });
    return ses;
  };

  const handleClickPublish = () => {
    const ses = buildSceneEntriesForRequestBoby();
    props.publishSceneEntries({ sceneEntries: ses });
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container disabled={!isArticleEdited} onClick={handleClickPublish}>
      <Title enabled={isArticleEdited}>Publish</Title>
      {lastPublishedAt && (
        <Info enabled={isArticleEdited}>
          Edited : {new Date(lastPublishedAt).toLocaleString("ja")}
        </Info>
      )}
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishButtonContainer);

function mapStateToProps(state: RootState) {
  return {
    sceneEntries: sceneEntriesSelector(state),
    linkLists: linkListsSelector(state),
    links: linksSelector(state),
    isArticleEdited: isArticleEditedSelector(state),
    lastPublishedAt: lastPublishedAtSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      publishSceneEntries: ({ sceneEntries }: PublishParams) =>
        publishSceneEntries.started({ sceneEntries }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

interface ButtonStyleProps {
  enabled: boolean;
}

const Container = styled.button`
  position: fixed;
  width: 342px;
  height: 44px;
  bottom: 22px;
  border-style: none;
  border-radius: 22px;
  cursor: pointer;
  outline: 0;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  ${props =>
    props.disabled
      ? css`
          background-color: #f0f0f0;
          cursor: not-allowed;
        `
      : css`
          background-image: linear-gradient(98deg, #32bdd9, #a96dd7);
        `};
`;

const Title = styled.span`
  ${heading}
  font-size: 14px;
  line-height: 1.71;
  ${(props: ButtonStyleProps) =>
    props.enabled
      ? css`
          color: ${colors.white};
        `
      : css`
          color: ${colors.blackForeground300};
        `}
`;

const Info = styled.span`
  font-size: 12px;
  line-height: 1.33;
  margin-left: 12px;
  ${(props: ButtonStyleProps) =>
    props.enabled
      ? css`
          color: ${colors.whiteForeground700};
        `
      : css`
          color: ${colors.blackForeground300};
        `};
`;
