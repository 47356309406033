import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";

import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import { UpdateAdSetRequest, updateAdSet } from "../../store/modules/adSet";
import Toggle from "../../lib/Toggle";

import AdSetReportContainer from "./AdSetReportContainer";
import AdSetSettingsContainer from "./AdSetSettingsContainer";

type Open = "reports" | "deliveryDesign";
interface State {
  open: Open;
}

interface OwnProps {
  adSetId: string;
}
type Props = ReturnType<typeof mapStateProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  OwnProps;

class AdSetDetailContainer extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      open: props.adSetId ? "reports" : "deliveryDesign",
    };
    this.handleChangeToggleEnabled = this.handleChangeToggleEnabled.bind(this);
  }

  public render() {
    const adSet = this.props.idAdSet[this.props.adSetId];
    const { adSetId } = this.props;
    return (
      <Container>
        <Header>
          <Name>{!!adSet ? adSet.name : "New Ad Set"}</Name>
          <EnableToggle
            checked={adSet && adSet.enabled ? adSet.enabled : false}
            icons={false}
            onChange={this.handleChangeToggleEnabled}
            disabled={adSetId === null}
          />
        </Header>
        <TabList>
          <ReportsTab
            open={this.state.open === "reports"}
            onClick={this.handleOnClick("reports")}
          >
            REPORTS
          </ReportsTab>
          <DeliveryDesignTab
            open={this.state.open === "deliveryDesign"}
            onClick={this.handleOnClick("deliveryDesign")}
          >
            DELIVERY DESIGN
          </DeliveryDesignTab>
        </TabList>
        <Content>
          {this.state.open === "reports" ? (
            <AdSetReportContainer adSetId={this.props.adSetId} />
          ) : (
            <AdSetSettingsContainer adSetId={this.props.adSetId} />
          )}
        </Content>
      </Container>
    );
  }

  private handleOnClick = (open: Open) => () => {
    this.setState({ open });
  };

  private handleChangeToggleEnabled() {
    const { adSetId } = this.props;
    if (!adSetId || !this.props.match) {
      return;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const adCampaignId = params["ad_campaign_id"];
    const adSet = this.props.idAdSet[adSetId];
    this.props.updateAdSet({
      adAccountId,
      adCampaignId,
      adSetId,
      enabled: !adSet.enabled,
    });
  }
}

// connect
// -----------------------------------------------

function mapStateProps(state: RootState) {
  const { adSets: idAdSet } = state.adSet;
  return { idAdSet };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateAdSet: (params: UpdateAdSetRequest) => updateAdSet.started(params),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateProps, mapDispatchToProps)(AdSetDetailContainer)
);

const Container = styled.div`
  width: 320px;
  padding: 24px;
  background-color: #fafafa;
`;

const Header = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const Name = styled.div`
  ${heading}
  width: 220px;
  min-height: 28px;
  word-wrap: break-word;
  font-size: 20px;
  line-height: 1.4;
  color: ${colors.blackForeground840};
`;

const EnableToggle = styled(Toggle)`
  margin: auto;
`;

const TabList = styled.div`
  margin-top: 10px;
  display: flex;
`;

const Tab = styled.div<{ open: boolean }>`
  ${heading}
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${colors.blackForeground600};

  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom-style: ${props => (props.open ? "solid" : "")};
  border-bottom-color: #7a78ff;
  cursor: pointer;
`;

const ReportsTab = styled(Tab)``;

const DeliveryDesignTab = styled(Tab)`
  margin-left: 20px;
`;

const Content = styled.div`
  width: 272px;
  height: 100vh;
  margin-top: 30px;
`;
