import { createSelector } from "reselect";

import { RootState } from "../modules";

export const linksSelector = (state: RootState) => state.link.links;

export const linkSelectorFactory = (uuid: string) =>
  createSelector(
    linksSelector,
    links => links[uuid]
  );

export const searchedLinkUuidSelector = (state: RootState) =>
  state.link.searchedLinkUuids;

export const searchedLinksSelector = createSelector(
  linksSelector,
  searchedLinkUuidSelector,
  (links, linkUuids) => linkUuids.map(uuid => links[uuid])
);
