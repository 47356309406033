import * as React from "react";

export type IconNames =
  | "wt-icon-android"
  | "wt-icon-apple"
  | "wt-icon-arrow-down"
  | "wt-icon-at-mark-o-1"
  | "wt-icon-at-mark"
  | "wt-icon-b"
  | "wt-icon-bar-chart"
  | "wt-icon-balloon-o-1"
  | "wt-icon-balloon-o-2"
  | "wt-icon-balloon"
  | "wt-icon-balloons-o-1"
  | "wt-icon-balloons-o-2"
  | "wt-icon-balloons"
  | "wt-icon-bell-o-1"
  | "wt-icon-bell-o-2"
  | "wt-icon-bell"
  | "wt-icon-bers-o-1"
  | "wt-icon-bers"
  | "wt-icon-bookmark-o-1"
  | "wt-icon-bookmark-o-2"
  | "wt-icon-bookmark"
  | "wt-icon-business-bag-o-1"
  | "wt-icon-business-bag-o-2"
  | "wt-icon-business-bag"
  | "wt-icon-calendar-o-1"
  | "wt-icon-calendar"
  | "wt-icon-camera-o-2"
  | "wt-icon-camera"
  | "wt-icon-chavron-down-o-1"
  | "wt-icon-chavron-down"
  | "wt-icon-chavron-up"
  | "wt-icon-check-circle"
  | "wt-icon-check"
  | "wt-icon-chevron-left-o-1"
  | "wt-icon-chevron-left"
  | "wt-icon-chevron-right-o-1"
  | "wt-icon-chevron-right"
  | "wt-icon-chevron-up-o-1"
  | "wt-icon-circle"
  | "wt-icon-circles-o-1"
  | "wt-icon-circles"
  | "wt-icon-clock-o-2"
  | "wt-icon-clock"
  | "wt-icon-close-circle-o-1"
  | "wt-icon-close-circle-o-2"
  | "wt-icon-close-circle"
  | "wt-icon-close-o-1"
  | "wt-icon-close"
  | "wt-icon-code"
  | "wt-icon-company-o-1"
  | "wt-icon-company-o-2"
  | "wt-icon-company-plus-o-1"
  | "wt-icon-company-plus-o-2"
  | "wt-icon-company-plus"
  | "wt-icon-company"
  | "wt-icon-compass-o-1"
  | "wt-icon-compass-o-2"
  | "wt-icon-compass"
  | "wt-icon-copy-o-1"
  | "wt-icon-copy"
  | "wt-icon-crip-o-1"
  | "wt-icon-crip"
  | "wt-icon-dashboard"
  | "wt-icon-door-o-1"
  | "wt-icon-door-o-2"
  | "wt-icon-door"
  | "wt-icon-download-circle-o-2"
  | "wt-icon-download-circle"
  | "wt-icon-download-cloud-o-2"
  | "wt-icon-download-cloud"
  | "wt-icon-download"
  | "wt-icon-ellipsis-h-o-1"
  | "wt-icon-ellipsis-h"
  | "wt-icon-ellipsis-v-o-1"
  | "wt-icon-ellipsis-v"
  | "wt-icon-exclamation-circle-o-1"
  | "wt-icon-exclamation-circle-o-2"
  | "wt-icon-exclamation-circle"
  | "wt-icon-eyes"
  | "wt-icon-facebook"
  | "wt-icon-file-o-1"
  | "wt-icon-file-o-2"
  | "wt-icon-file"
  | "wt-icon-fire-o-1"
  | "wt-icon-fire"
  | "wt-icon-folder-o-1"
  | "wt-icon-folder-o-2"
  | "wt-icon-folder"
  | "wt-icon-gear-o-1"
  | "wt-icon-gear"
  | "wt-icon-github"
  | "wt-icon-google"
  | "wt-icon-h2"
  | "wt-icon-h3"
  | "wt-icon-hash"
  | "wt-icon-hatena"
  | "wt-icon-heart-o-2"
  | "wt-icon-heart"
  | "wt-icon-infomation-o-1"
  | "wt-icon-infomation-o-2"
  | "wt-icon-infomation"
  | "wt-icon-jump"
  | "wt-icon-label-text-o-1"
  | "wt-icon-label-text-o-2"
  | "wt-icon-label-text"
  | "wt-icon-line-chart-o-2"
  | "wt-icon-link"
  | "wt-icon-linkedin"
  | "wt-icon-list"
  | "wt-icon-location"
  | "wt-icon-mail-o-1"
  | "wt-icon-mail-o-2"
  | "wt-icon-mail"
  | "wt-icon-message-o-1"
  | "wt-icon-message-o-2"
  | "wt-icon-message"
  | "wt-icon-minus-o-1"
  | "wt-icon-minus"
  | "wt-icon-newspaper-o-1"
  | "wt-icon-newspaper-o-2"
  | "wt-icon-newspaper"
  | "wt-icon-pencil-o-1"
  | "wt-icon-pencil-paper-o-1"
  | "wt-icon-pencil-paper"
  | "wt-icon-pencil"
  | "wt-icon-person-o-1"
  | "wt-icon-person-o-2"
  | "wt-icon-person-plus-o-1"
  | "wt-icon-person-plus-o-2"
  | "wt-icon-person-plus"
  | "wt-icon-person"
  | "wt-icon-phone-o-1"
  | "wt-icon-phone"
  | "wt-icon-pin"
  | "wt-icon-plus-circle-o-1"
  | "wt-icon-plus-o-1"
  | "wt-icon-plus"
  | "wt-icon-quote"
  | "wt-icon-refresh-o-1"
  | "wt-icon-refresh"
  | "wt-icon-reply"
  | "wt-icon-right-pane-o-1"
  | "wt-icon-right-pane"
  | "wt-icon-search-o-1"
  | "wt-icon-search-short-o-1"
  | "wt-icon-search-short"
  | "wt-icon-search"
  | "wt-icon-separator"
  | "wt-icon-share-apple"
  | "wt-icon-share-google"
  | "wt-icon-share-ms"
  | "wt-icon-share"
  | "wt-icon-sliders"
  | "wt-icon-spanner-o-1"
  | "wt-icon-spanner"
  | "wt-icon-star-o-1"
  | "wt-icon-star"
  | "wt-icon-support"
  | "wt-icon-tag-o-1"
  | "wt-icon-tag-o-2"
  | "wt-icon-tag"
  | "wt-icon-target"
  | "wt-icon-text-square-o-1"
  | "wt-icon-text-square-o-2"
  | "wt-icon-text-square"
  | "wt-icon-thumb-up-o-1"
  | "wt-icon-thumb-up-o-2"
  | "wt-icon-thumb-up"
  | "wt-icon-trash-o-1"
  | "wt-icon-trash-o-2"
  | "wt-icon-trash"
  | "wt-icon-trencher-o-1"
  | "wt-icon-trencher-o-2"
  | "wt-icon-trencher"
  | "wt-icon-triangle-down"
  | "wt-icon-triangle-up"
  | "wt-icon-triangle"
  | "wt-icon-twitter"
  | "wt-icon-undo-o-1"
  | "wt-icon-undo"
  | "wt-icon-upload-cloud-o-1"
  | "wt-icon-wanted"
  | "wt-icon-wantedly-o-1"
  | "wt-icon-wantedly-o-2"
  | "wt-icon-wantedly"
  | "wt-icon-whatsapp"
  | "wt-icon-windows";

type Props = {
  name: IconNames;
  innerRef?(ref: HTMLElement | null): void;
} & React.HTMLAttributes<any>;

const Icon: React.SFC<Props> = ({ name, className, innerRef, ...rest }) => {
  return (
    <i className={`${className} wt-icon ${name}`} {...rest} ref={innerRef} />
  );
};

export default Icon;
