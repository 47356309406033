const colors = {
  /* black-foreground */
  blackForeground25: "rgba(0, 0, 0, 0.04)",
  blackForeground50: "rgba(0, 0, 0, 0.06)",
  blackForeground100: "rgba(0, 0, 0, 0.12)",
  blackForeground300: "rgba(0, 0, 0, 0.24)",
  blackForeground400: "rgba(0, 0, 0, 0.4)",
  blackForeground600: "rgba(0, 0, 0, 0.56)",
  blackForeground700: "rgba(0, 0, 0, 0.74)",
  blackForeground800: "rgba(0, 0, 0, 0.8)",
  blackForeground840: "rgba(0, 0, 0, 0.84)",
  blackTranspaent: "rgba(0, 0, 0, 0)",

  /* white-foreground */
  whiteForeground700: "rgba(255, 255, 255, 0.7)",
  whiteForeground400: "rgba(255, 255, 255, 0.4)",
  whiteForeground200: "rgba(255, 255, 255, 0.2)",
  whiteForeground100: "rgba(255, 255, 255, 0.12)",
  whiteTransparent: "rgba(255, 255, 255, 0)",

  /* gray */
  gray500: "#9e9e9e",
  gray400: "#ccc",
  gray300: "#e0e0e0",
  gray200: "#eee",
  gray100: "#f5f5f5",
  gray50: "#fafafa",

  /* white */
  white: "#fff",

  /* floating-item-boundary */
  floatingItemBoundary: "rgba(0, 0, 0, 0.02)",

  /* colors */
  blue400: "#38b2c4",
  redAccent500: "#dd3247",

  /* gradients */
  gradientBlue: "#31bdd9",
  gradientPurple: "#a96dd8",

  /* brands */
  nobrandLight: "#dfdfdf",
  facebookLight: "#3b5998",
  facebookLogin: "#5890ff",
  facebookLoginLight: "#79a6ff",
  twitterLight: "#55acee",
  googleLight: "#d9453d",
  linkedinLight: "#1278b3",
  githubLight: "#333",
};
export default colors;
