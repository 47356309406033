import { css } from "styled-components";

export function truncateLines(rows: number, fontSize = 14, lineHeight = 1.5) {
  return rows > 1
    ? css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: ${lineHeight};
        font-size: ${fontSize}px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${rows};
        max-height: ${rows * fontSize * lineHeight}px;
      `
    : css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `;
}
