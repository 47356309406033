import { all, call, put, takeLatest, fork } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import * as updatesContentsAdApi from "../../utils/api/updatesContentsAdApi";
import {
  AdAccountNavigationResponse,
  fetchAdAccountNavigation,
  fetchAdAccountSummary,
  AdAccountSummaryResponse,
  AdAccountsResponse,
  fetchAdAccounts,
  CreateAdAccountRequest,
  CreateAdAccountResponse,
  createAdAccount,
  UpdateAdAccountRequest,
  UpdateAdAccountResponse,
  updateAdAccount,
  fetchConversionTagSummary,
  ConversionTagSummaryResponse,
  CreateConversionTagRequest,
  CreateConversionTagResponse,
  createConversionTag,
  UpdateConversionTagRequest,
  UpdateConversionTagResponse,
  updateConversionTag,
} from "../modules/adAccount";
import { history } from "../../App";

export function* handleAdAccountNativation(
  action: Action<{ adAccountId: string }>
) {
  const { adAccountId } = action.payload;
  try {
    const res = yield call(
      updatesContentsAdApi.fetchAdAccountNavigation({ adAccountId })
    );
    const adAccountNavigation = res as AdAccountNavigationResponse;
    yield put(
      fetchAdAccountNavigation.done({
        params: { adAccountId },
        result: adAccountNavigation,
      })
    );
  } catch (e) {
    yield put(
      fetchAdAccountSummary.failed({ params: { adAccountId }, error: e })
    );
  }
}

export function* handleAdAccounts() {
  try {
    const res = yield call(updatesContentsAdApi.fetchAdAccounts());
    const adAccounts = res as AdAccountsResponse;
    yield put(
      fetchAdAccounts.done({
        result: adAccounts,
      })
    );
  } catch (e) {
    yield put(fetchAdAccounts.failed({ error: e }));
  }
}

export function* handleAdAccountSummary(
  action: Action<{ adAccountId: string }>
) {
  const { adAccountId } = action.payload;
  try {
    const res = yield call(
      updatesContentsAdApi.fetchAdAccountSummary({ adAccountId })
    );
    const adAccountSummary = res as AdAccountSummaryResponse;
    yield put(
      fetchAdAccountSummary.done({
        params: { adAccountId },
        result: adAccountSummary,
      })
    );
  } catch (e) {
    yield put(
      fetchAdAccountSummary.failed({ params: { adAccountId }, error: e })
    );
  }
}

export function* handleCreateAdAccount(action: Action<CreateAdAccountRequest>) {
  try {
    const res: CreateAdAccountResponse = yield call(
      updatesContentsAdApi.createAdAccount(action.payload)
    );
    yield put(
      createAdAccount.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      createAdAccount.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleUpdateAdAccount(action: Action<UpdateAdAccountRequest>) {
  try {
    const res: UpdateAdAccountResponse = yield call(
      updatesContentsAdApi.updateAdAccount(action.payload)
    );
    yield put(
      updateAdAccount.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      updateAdAccount.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleCreateConversionTag(
  action: Action<CreateConversionTagRequest>
) {
  try {
    const res: CreateConversionTagResponse = yield call(
      updatesContentsAdApi.createConversionTag(action.payload)
    );
    const { id, adAccountId } = res;

    yield put(
      createConversionTag.done({
        params: action.payload,
        result: res,
      })
    );
    history.push(
      `/admin/ad/ad_accounts/${adAccountId}/conversion_tags/${id}`
    );
  } catch (e) {
    yield put(
      createConversionTag.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleUpdateConversionTag(
  action: Action<UpdateConversionTagRequest>
) {
  try {
    const res: UpdateConversionTagResponse = yield call(
      updatesContentsAdApi.updateConversionTag(action.payload)
    );
    yield put(
      updateConversionTag.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      updateConversionTag.failed({
        params: action.payload,
        error: e,
      })
    );
  }
}

export function* handleFetchConversionTagSummary(
  action: Action<{adAccountId: string, conversionTagId: string}>
) {
  try {
    const res: ConversionTagSummaryResponse = yield call(
      updatesContentsAdApi.fetchConversionTagSummary(action.payload)
    );
    yield put(
      fetchConversionTagSummary.done({
        params: action.payload,
        result: res,
      })
    );
  } catch (e) {
    yield put(
      fetchConversionTagSummary.failed({
        params: action.payload,
        error: e,
      })
    );
  }

}

function* watchHandleAdAccountSummary() {
  yield takeLatest(fetchAdAccountSummary.started, handleAdAccountSummary);
}

function* watchHandleAdAccounts() {
  yield takeLatest(fetchAdAccounts.started, handleAdAccounts);
}

function* watchHandleAdAccountNavigation() {
  yield takeLatest(fetchAdAccountNavigation.started, handleAdAccountNativation);
}

function* watchHandleCreateAdAccount() {
  yield takeLatest(createAdAccount.started, handleCreateAdAccount);
}

function* watchHandleUpdateAdAccount() {
  yield takeLatest(updateAdAccount.started, handleUpdateAdAccount);
}

function* watchHandleCreateConversionTag() {
  yield takeLatest(createConversionTag.started, handleCreateConversionTag);
}

function* watchHandleUpdateConversionTag() {
  yield takeLatest(updateConversionTag.started, handleUpdateConversionTag);
}

function* watchHandleFetchConversionTag() {
  yield takeLatest(fetchConversionTagSummary.started, handleFetchConversionTagSummary);
}

export function* adAccountWorker() {
  yield all([
    fork(watchHandleAdAccountSummary),
    fork(watchHandleAdAccounts),
    fork(watchHandleAdAccountNavigation),
    fork(watchHandleCreateAdAccount),
    fork(watchHandleUpdateAdAccount),
    fork(watchHandleCreateConversionTag),
    fork(watchHandleUpdateConversionTag),
    fork(watchHandleFetchConversionTag),
  ]);
}
