import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { Dispatch, bindActionCreators } from "redux";

import color from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  updateAdCampaign,
  UpdateAdCampaignRequest,
  CreateAdCampaignRequest,
  createAdCampaign,
} from "../../store/modules/adCampaign";

interface OwnProps {
  adCampaignId: string;
}
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  OwnProps;

interface State {
  name: string;
  totalBudget: string;
  dailyBudget: string;
}

class AdCampaignSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const adCampaign = this.props.idAdCampaign[this.props.adCampaignId];
    this.state = {
      name: adCampaign ? adCampaign.name : "",
      totalBudget:
        adCampaign && adCampaign.totalBudget ? adCampaign.totalBudget : "",
      dailyBudget:
        adCampaign && adCampaign.dailyBudget ? adCampaign.dailyBudget : "",
    };
    this.handleChangeAdCampaign = this.handleChangeAdCampaign.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.adCampaignId !== prevProps.adCampaignId) {
      const adCampaign = this.props.idAdCampaign[this.props.adCampaignId];
      this.setState({
        name: adCampaign ? adCampaign.name : "",
        totalBudget:
          adCampaign && adCampaign.totalBudget ? adCampaign.totalBudget : "",
        dailyBudget:
          adCampaign && adCampaign.dailyBudget ? adCampaign.dailyBudget : "",
      });
    }
  }

  // jsx
  // -----------------------------------------------

  public render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <NameHeader>Name</NameHeader>
        <NameInput
          type="text"
          name="name"
          value={this.state.name}
          onChange={this.handleChangeAdCampaign}
        />
        <TotalBudgetHeader>Total Budget</TotalBudgetHeader>
        <TotalBudgetInput
          type="text"
          name="totalBudget"
          value={this.state.totalBudget}
          onChange={this.handleChangeAdCampaign}
        />
        <Unit>Yen</Unit>
        <DailyBudgetHeader>Daily Budget</DailyBudgetHeader>
        <DailyBudgetInput
          type="text"
          name="dailyBudget"
          value={this.state.dailyBudget}
          onChange={this.handleChangeAdCampaign}
        />
        <Unit>Yen</Unit>
        <PublishWrapper>
          <Publish>Publish</Publish>
        </PublishWrapper>
      </form>
    );
  }

  private handleChangeAdCampaign(e: React.FormEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value,
    } as Pick<State, "name" | "totalBudget" | "dailyBudget">);
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.props.match) {
      return;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const { adCampaignId } = this.props;
    if (adCampaignId) {
      this.props.updateAdCampaign({
        adAccountId,
        adCampaignId,
        totalBudget: this.state.totalBudget,
        dailyBudget: this.state.dailyBudget,
        name: this.state.name,
      });
    } else {
      this.props.createAdCampaign({
        adAccountId,
        name: this.state.name,
        totalBudget: this.state.totalBudget,
        dailyBudget: this.state.dailyBudget,
      });
    }
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { adCampaigns: idAdCampaign } = state.adCampaign;
  return { idAdCampaign };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateAdCampaign: (params: UpdateAdCampaignRequest) =>
        updateAdCampaign.started(params),
      createAdCampaign: (params: CreateAdCampaignRequest) =>
        createAdCampaign.started(params),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdCampaignSettings)
);

const NameHeader = styled.div`
  ${heading}
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: ${color.blackForeground840};
`;

const TotalBudgetHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  color: ${color.blackForeground840};
`;

const DailyBudgetHeader = styled.div`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
  color: ${color.blackForeground840};
`;

const NameInput = styled.input`
  width: 272px;
  height: 40px;
  margin-top: 12px;
  text-indent: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
`;

const TotalBudgetInput = styled.input`
  width: 272px;
  height: 40px;
  margin-top: 12px;
  font-size: 16px;
  text-indent: 12px;
  border-radius: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
`;

const DailyBudgetInput = styled.input`
  width: 272px;
  height: 40px;
  margin-top: 12px;
  font-size: 16px;
  text-indent: 12px;
  border-radius: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
`;

const Unit = styled.div`
  font-size: 12px;
  margin: 5px auto auto 11px;
  line-height: 1.33;
  color: ${color.blackForeground400};
`;

const PublishWrapper = styled.div`
  text-align: right;
  margin-top: 10px;
`;

const Publish = styled.button`
  ${heading}
  right: 0px;
  font-size: 14px;
  line-height: 1.71;
  border-radius: 18px;
  margin-top: 10px;
  color: #ffffff;
  width: 112px;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
`;
