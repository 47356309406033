import { all, call, put, takeLatest, fork } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import {
  fetchSceneEntries,
  FetchScenesResponse,
  publishSceneEntries,
  PublishParams,
  PublishResponse,
} from "../modules/sceneEntry";
import * as updatesContentsApi from "../../utils/api/updatesContentsApi";

export function* handleFetchScenes() {
  try {
    const res: FetchScenesResponse = yield call(
      updatesContentsApi.fetchSceneEntries
    );
    const { sceneEntries, subjects, lastPublishedAt } = res;
    yield put(
      fetchSceneEntries.done({
        result: { sceneEntries, subjects, lastPublishedAt },
      })
    );
  } catch (e) {
    yield put(fetchSceneEntries.failed({ error: e }));
  }
}

function* handlePublishScenes(action: Action<PublishParams>) {
  const {
    payload: { sceneEntries },
  } = action;
  try {
    const res: PublishResponse = yield call(
      updatesContentsApi.requestToPublishSceneEntries({ sceneEntries })
    );
    yield put(
      publishSceneEntries.done({
        params: { sceneEntries },
        result: {
          sceneEntries: res.sceneEntries,
          lastPublishedAt: res.lastPublishedAt,
        },
      })
    );
  } catch (e) {
    // TODO: いつかちゃんとする
    window.alert("保存に失敗しました");
    yield put(
      publishSceneEntries.failed({ params: { sceneEntries }, error: e })
    );
  }
}

function* watchFetchScenes() {
  yield takeLatest(fetchSceneEntries.started, handleFetchScenes);
}

function* watchPublishSceneEntries() {
  yield takeLatest(publishSceneEntries.started, handlePublishScenes);
}

export function* sceneEntriesWorker() {
  yield all([fork(watchFetchScenes), fork(watchPublishSceneEntries)]);
}
