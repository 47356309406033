import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Reducer } from "redux";
import produce from "immer";

import { AdSetStatus } from "./adSet";
import { fetchAdAccountNavigation, fetchAdAccountSummary } from "./adAccount";

//  Types
// -----------------------------------------------

export type AdCampaignStatus = "ACTIVE" | "STOPPED" | "BUDGET_OVER";

export interface AdCampaignSummaryResponse {
  id: string;
  name: string;
  totalBudget: string;
  dailyBudget: string;
  enabled: boolean;
  status: AdCampaignStatus;
  report: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
  adSets: AdCampaignSummaryAdSetResponse[];
}

export interface AdCampaignSummaryAdSetResponse {
  id: string;
  name: string;
  unitPrice: string;
  unitType: string;
  enabled: boolean;
  targetingQueryId: string;
  startedAt: string;
  endedAt: string;
  frequencyCap: string;
  frequencyCapPeriod: string;
  strength: string;
  adContentType: string;
  adCampaignId: string;
  status: AdSetStatus;
  report: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
}

export interface IdAdCampaign {
  [id: string]: AdCampaign;
}

export interface AdCampaign {
  id: string;
  name: string;
  totalBudget?: string;
  dailyBudget?: string;
  enabled?: boolean;
  adAccountId?: string;
  status: AdCampaignStatus;
  report?: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
  adSets: string[];
}

export interface CreateAdCampaignRequest {
  adAccountId: string;
  name: string;
  totalBudget: string;
  dailyBudget?: string;
}

export interface CreateAdCampaignResponse {
  id: string;
  name: string;
  totalBudget: string;
  dailyBudget: string;
  enabled: boolean;
  status: AdCampaignStatus;
  adAccountId: string;
}

export interface UpdateAdCampaignRequest {
  adAccountId: string;
  adCampaignId: string;
  name?: string;
  totalBudget?: string;
  dailyBudget?: string;
  enabled?: boolean;
}

export interface UpdateAdCampaignResponse {
  id: string;
  name: string;
  totalBudget: string;
  dailyBudget: string;
  enabled: boolean;
  status: AdCampaignStatus;
}

//  State
// -----------------------------------------------

export interface AdCampaignState {
  adCampaigns: IdAdCampaign;
}

export const initialState: AdCampaignState = {
  adCampaigns: {},
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("AD_CAMPAIGN");

export const fetchAdCampaignSummary = actionCreator.async<
  { adAccountId: string; adCampaignId: string },
  AdCampaignSummaryResponse,
  Error
>("FETCH_AD_CAMPAIGN_SUMMARY");

export const createAdCampaign = actionCreator.async<
  CreateAdCampaignRequest,
  CreateAdCampaignResponse,
  Error
>("CREATE_AD_CAMPAIGN");

export const updateAdCampaign = actionCreator.async<
  UpdateAdCampaignRequest,
  UpdateAdCampaignResponse,
  Error
>("UPDATE_AD_CAMPAIGN");

// FIXME: createAdSet from "/adSet" をreducerの処理に追加するべきだが、
// importするとundefiedになってしまうため、別でactionを定義している
export interface AdSetCreated {
  adCampaignId: string;
  adSetId: string;
}
export const adSetCreated = actionCreator<AdSetCreated>("AD_SET_CREATED");

const adCampaignReducer: Reducer<AdCampaignState> = reducerWithInitialState<
  AdCampaignState
>(initialState)
  .case(
    fetchAdAccountNavigation.done,
    (state, { result: adAccountNavigation }) =>
      produce(state, (draft: AdCampaignState) => {
        adAccountNavigation.adCampaigns.forEach(adCampaign => {
          if (draft.adCampaigns[adCampaign.id]) {
            Object.assign(draft.adCampaigns[adCampaign.id], adCampaign, {
              adSets: adCampaign.adSets.map(adSet => adSet.id),
            });
          } else {
            draft.adCampaigns[adCampaign.id] = {
              ...adCampaign,
              adSets: adCampaign.adSets.map(adSet => adSet.id),
            };
          }
          draft.adCampaigns[adCampaign.id].adSets.sort((a, b) => {
            if (a < b) {
              return 1;
            } else {
              return -1;
            }
          });
        });
      })
  )
  .case(
    fetchAdAccountSummary.done,
    (state, { result: adAccountSummaryResponse }) =>
      produce(state, (draft: AdCampaignState) => {
        adAccountSummaryResponse.adCampaigns.forEach(adCampaign => {
          if (draft.adCampaigns[adCampaign.id]) {
            Object.assign(draft.adCampaigns[adCampaign.id], adCampaign);
          } else {
            draft.adCampaigns[adCampaign.id] = {
              ...adCampaign,
              adSets: [],
            };
          }
        });
      })
  )
  .case(
    fetchAdCampaignSummary.done,
    (state, { result: adCampaignSummaryResponse }) =>
      produce(state, (draft: AdCampaignState) => {
        const adCampaignId = adCampaignSummaryResponse.id;
        if (draft.adCampaigns[adCampaignId]) {
          Object.assign(
            draft.adCampaigns[adCampaignId],
            adCampaignSummaryResponse,
            { adSets: adCampaignSummaryResponse.adSets.map(adSet => adSet.id) }
          );
        } else {
          draft.adCampaigns[adCampaignId] = {
            ...adCampaignSummaryResponse,
            adSets: adCampaignSummaryResponse.adSets.map(adSet => adSet.id),
          };
        }
        draft.adCampaigns[adCampaignId].adSets.sort((a, b) => {
          if (a < b) {
            return 1;
          } else {
            return -1;
          }
        });
      })
  )
  .case(createAdCampaign.done, (state, { result: createAdCampaignResponse }) =>
    produce(state, (draft: AdCampaignState) => {
      const { id } = createAdCampaignResponse;
      draft.adCampaigns[id] = {
        ...createAdCampaignResponse,
        adSets: [],
      };
    })
  )
  .case(updateAdCampaign.done, (state, { result: updateAdCampaignResponse }) =>
    produce(state, (draft: AdCampaignState) => {
      const { id } = updateAdCampaignResponse;
      Object.assign(draft.adCampaigns[id], updateAdCampaignResponse);
    })
  )
  .case(adSetCreated, (state, createdAdSet) =>
    produce(state, (draft: AdCampaignState) => {
      const { adCampaignId, adSetId } = createdAdSet;
      draft.adCampaigns[adCampaignId].adSets.push(adSetId);
      draft.adCampaigns[adCampaignId].adSets.sort((a, b) => {
        if (a < b) {
          return 1;
        } else {
          return -1;
        }
      });
    })
  );

export default adCampaignReducer;
