export const LINK_EDIT_PATH = "/articles/link/edit";

export const getYashimaUpdatesContentsDomain = (env: string | undefined) => {
    switch (env) {
        case 'production':
            return 'https://yashima-updates-contents.wantedly.com';
        case 'qa':
            return 'https://yashima-updates-contents-qa.wantedly.com';
        case 'sandbox':
            return 'https://yashima-updates-contents-sandbox.wantedly.com';
        default:
            return 'http://localhost:3027';
    }
};
