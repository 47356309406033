import React from "react";

import RootTemplate from "./RootTemplate";
import AdAccountContainer from "./AdAccountContainer";

function AdAccountPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <AdAccountContainer />;
}

export default AdAccountPage;
