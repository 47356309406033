import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";

import { linkWorker } from "./link";
import { linkListWorker } from "./linkList";
import { sceneEntriesWorker } from "./sceneEntry";
import { adAccountWorker } from "./adAccount";
import { adCampaignWorker } from "./adCampaign";
import { adSetWorker } from "./adSet";
import { adContentWorker } from "./adContent";
import { linkReportWorker } from "./linkReport";

export default function* mainSaga(): SagaIterator {
  yield all([fork(linkWorker)]);
  yield all([fork(linkListWorker)]);
  yield all([fork(sceneEntriesWorker)]);
  yield all([fork(adCampaignWorker)]);
  yield all([fork(adAccountWorker)]);
  yield all([fork(adSetWorker)]);
  yield all([fork(adContentWorker)]);
  yield all([fork(linkReportWorker)]);
}
