import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";

import { RootState } from "../store/modules";

import AdContentList from "./AdContentListContainer";
import AdContentDetail from "./AdContent/AdContentDetailContainer";
import AdSetDetailContainer from "./AdSet/AdSetDetailContainer";

type Props = ReturnType<typeof mapStateToProps> & RouteComponentProps;

class AdSetSummaryContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  // jsx
  // -----------------------------------------------

  public render() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    const adSetId = params["ad_set_id"];

    return (
      <Container>
        <AdContentList />
        {!!this.props.focusedAdContentId ||
        this.props.match.path ===
          "/admin/ad/ad_accounts/:ad_account_id/ad_campaigns/:ad_campaign_id/ad_sets/:ad_set_id/ad_contents/new" ? (
          <AdContentDetail adContentId={this.props.focusedAdContentId} />
        ) : (
          <AdSetDetailContainer adSetId={adSetId} />
        )}
      </Container>
    );
  }
}

// connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { focusedAdContentId } = state.ui.adSetSummary;
  return { focusedAdContentId };
}

export default withRouter(connect(mapStateToProps)(AdSetSummaryContainer));

const Container = styled.div`
  display: flex;
`;
