import React from "react";

import RootTemplate from "./RootTemplate";
import AdSettingsContainer from "./AdSettingsContainer";

function AdSettingsPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <AdSettingsContainer />;
}

export default AdSettingsPage;
