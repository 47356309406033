import React from "react";

import RootTemplate from "./RootTemplate";
import LinkListEditContainer from "./LinkListEditContainer";

function LinkListPage() {
  return <RootTemplate render={() => <LinkListEditContainer />} />;
}

export default LinkListPage;
