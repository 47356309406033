import styled from "styled-components";
import ReactToggle from "react-toggle";
import "react-toggle/style.css";

import colors from "../styles/color";

const Toggle = styled(ReactToggle)`
  width: 36px;
  height: 20px;
  background-color: #7a78ff;

  .react-toggle-track {
    width: 36px;
    height: 20px;
  }

  &.react-toggle--checked .react-toggle-track {
    left: 17px;
    background-color: #7a78ff;
    &:hover {
      background-color: #7a78ff;
    }
  }

  &.react-toggle--checked .react-toggle-thumb {
    left: 17px;
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: ${colors.blackForeground300};
  }

  .react-toggle-thumb {
    width: 18px;
    height: 18px;
    border: none;
  }
`;

export default Toggle;
