import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";

import { RootState } from "../store/modules";

import AdCampaignListContainer from "./AdCampaignListContainer";
import AdCampaignDetailContainer from "./AdCampaign/AdCampaignDetailContainer";

type Props = ReturnType<typeof mapStateToProps> & RouteComponentProps;

class AdAccountSummaryContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  //  jsx
  // -----------------------------------------------

  public render() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const newAdCampaignPath = `/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/new`;

    return (
      <Container>
        <AdCampaignListContainer />
        {!!this.props.focusedAdCampaignId ||
        this.props.location.pathname === newAdCampaignPath ? (
          <AdCampaignDetailContainer
            adCampaignId={this.props.focusedAdCampaignId}
          />
        ) : (
          <AdAccountDetail />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state: RootState) {
  const { focusedAdCampaignId } = state.ui.adAccountSummary;
  return { focusedAdCampaignId };
}

export default withRouter(connect(mapStateToProps)(AdAccountSummaryContainer));

const Container = styled.div`
  display: flex;
`;

const AdAccountDetail = styled.div`
  width: 320px;
  height: 850px;
  padding: 24px;
  background-color: #fafafa;
`;
