import React from "react";
import { ComponentType } from "react";
import { Switch, Route } from "react-router";

import LinkIndexPage from "./Delivery/LinkIndexPage";
import LinkListEditPage from "./Delivery/LinkListEditPage";
import LinkEditPage from "./Delivery/LinkEditPage";
import LinkReportIndexPage from "./Delivery/LinkReportIndexPage";
import AdAccountSummaryPage from "./Ad/AdAccountSummaryPage";
import AdCampaignSummaryPage from "./Ad/AdCampaignSummaryPage";
import AdSetSummaryPage from "./Ad/AdSetSummaryPage";
import ConversionTagPage from "./Ad/ConversionTagPage";
import AdSettingsPage from "./AdAdmin/AdSettingsPage";
import AdAccountPage from "./AdAdmin/AdAccountPage";
import TargetingQueryPage from "./AdAdmin/TargetingQueryPage";

type RouteComponentType = ComponentType<any>;

interface RouteDefinition {
  component: RouteComponentType;
  key: string;
  path: string;
  exact?: boolean;
  requireAuth?: boolean;
}

const routeDefs: RouteDefinition[] = [
  {
    component: LinkIndexPage,
    key: "link#index",
    path: "/admin/articles/links",
    exact: true,
  },
  {
    component: LinkListEditPage,
    key: "link_list#show",
    path: "/admin/articles/link_lists/:uuid/edit",
    exact: true,
  },
  {
    component: LinkEditPage,
    key: "link_list_items#edit",
    path: "/admin/articles/link_list_items/:uuid/edit",
    exact: true,
  },
  {
    component: LinkEditPage,
    key: "link#edit",
    path: "/admin/articles/links/:uuid/edit",
    exact: true,
  },
  {
    component: LinkReportIndexPage,
    key: "link#edit",
    path: "/admin/articles/link_reports",
    exact: true,
  },
  {
    component: AdAccountSummaryPage,
    key: "ad_accounts#index",
    path: "/admin/ad/ad_accounts",
    exact: true,
  },
  {
    component: AdAccountSummaryPage,
    key: "ad_accounts#summary",
    path: "/admin/ad/ad_accounts/:ad_account_id/summary",
    exact: true,
  },
  {
    component: AdAccountSummaryPage,
    key: "ad_campaigns#new",
    path: "/admin/ad/ad_accounts/:ad_account_id/ad_campaigns/new",
    exact: true,
  },
  {
    component: AdCampaignSummaryPage,
    key: "ad_campaigns#summary",
    path:
      "/admin/ad/ad_accounts/:ad_account_id/ad_campaigns/:ad_campaign_id/summary",
    exact: true,
  },
  {
    component: AdCampaignSummaryPage,
    key: "ad_sets#new",
    path:
      "/admin/ad/ad_accounts/:ad_account_id/ad_campaigns/:ad_campaign_id/ad_sets/new",
    exact: true,
  },
  {
    component: AdSetSummaryPage,
    key: "ad_sets#summary",
    path:
      "/admin/ad/ad_accounts/:ad_account_id/ad_campaigns/:ad_campaign_id/ad_sets/:ad_set_id/summary",
    exact: true,
  },
  {
    component: AdSetSummaryPage,
    key: "ad_contents#new",
    path:
      "/admin/ad/ad_accounts/:ad_account_id/ad_campaigns/:ad_campaign_id/ad_sets/:ad_set_id/ad_contents/new",
    exact: true,
  },
  {
    component: AdSettingsPage,
    key: "ad_settings#index",
    path: "/admin/ad/ad_settings",
    exact: true,
  },
  {
    component: AdAccountPage,
    key: "ad_accounts#index",
    path: "/admin/ad/ad_settings/ad_accounts",
    exact: true,
  },
  {
    component: TargetingQueryPage,
    key: "targeting_queries#index",
    path: "/admin/ad/ad_settings/targeting_queries",
    exact: true,
  },
  {
    component: ConversionTagPage,
    key: "conversion_tags#show",
    path:
      "/admin/ad/ad_accounts/:ad_account_id/conversion_tags/:conversion_tag_id",
    exact: true,
  },
  {
    component: ConversionTagPage,
    key: "conversion_tags#new",
    path: "/admin/ad/ad_accounts/:ad_account_id/conversion_tags/new",
    exact: true,
  },
];

function Routes() {
  return (
    <Switch>
      {routeDefs.map(routeDef => (
        <Route {...routeDef} />
      ))}
    </Switch>
  );
}

export default Routes;
