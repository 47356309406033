import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";

import { heading } from "../../styles/font";
import colors from "../../styles/color";

interface Props {
  header: string;
  onChangeHeader: (header: string) => void;
}

function EditableSceneEntryHeaderContainer(props: Props) {
  //  functions
  // -----------------------------------------------

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: headline } = e.currentTarget;
    props.onChangeHeader(headline);
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      <SceneHeaderInput
        value={props.header}
        placeholder="Input title..."
        onChange={handleChange}
      />
    </Container>
  );
}

export default EditableSceneEntryHeaderContainer;

//  style
// -----------------------------------------------

const Container = styled.div``;

const SceneHeaderInput = styled.input`
  ${heading};
  width: 100%;
  height: 40px;
  text-indent: 8px;
  background-color: #fafafa;
  outline: none;
  border-style: none;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.blackForeground600};
  margin-bottom: 12px;
  padding: 0 8px;
  &:hover {
    background-color: #f7f7f7;
    color: #000;
  }
`;
