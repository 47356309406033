import { camelizeKeys, decamelizeKeys } from "humps";

import {
  CreateAdAccountRequest,
  UpdateAdAccountRequest,
  CreateConversionTagRequest,
  UpdateConversionTagRequest,
} from "../../store/modules/adAccount";
import {
  UpdateAdCampaignRequest,
  CreateAdCampaignRequest,
} from "../../store/modules/adCampaign";
import {
  UpdateAdSetRequest,
  CreateAdSetRequest,
  TargetingCountRequest,
  FetchQueryJobResultRequest,
  CreateTargetingQueryRequest,
  UpdateTargetingQueryRequest,
} from "../../store/modules/adSet";
import {
  UpdateAdContentRequest,
  CreateAdContentRequest,
} from "../../store/modules/adContent";

import { updatesContentsApi } from "./updatesContentsApi";

export function fetchAdAccountSummary(params: { adAccountId: string }) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/summary`,
      {
        method: "GET",
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchAdAccountNavigation(params: { adAccountId: string }) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/navigation`,
      {
        method: "GET",
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchAdAccounts() {
  return async () => {
    const res = await updatesContentsApi(`/api/admin/ad_accounts`, {
      method: "GET",
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function createAdAccount(params: CreateAdAccountRequest) {
  return async () => {
    const res = await updatesContentsApi(`/api/admin/ad_accounts`, {
      method: "POST",
      body: {
        name: params.name,
      },
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchAdCampaignSummary(params: {
  adAccountId: string;
  adCampaignId: string;
}) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/ad_campaigns/${params.adCampaignId}/summary`,
      {
        method: "GET",
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function createAdCampaign(params: CreateAdCampaignRequest) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/ad_campaigns`,
      {
        method: "POST",
        body: {
          name: params.name,
          total_budget: params.totalBudget,
          daily_budget: params.dailyBudget,
        },
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function updateAdAccount(params: UpdateAdAccountRequest) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.id}`,
      {
        method: "PUT",
        body: {
          name: params.name,
        },
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function updateAdCampaign(params: UpdateAdCampaignRequest) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/ad_campaigns/${params.adCampaignId}`,
      {
        method: "PUT",
        body: {
          name: params.name,
          total_budget: params.totalBudget,
          daily_budget: params.dailyBudget,
          enabled: params.enabled,
        },
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchAdSetSummary(params: {
  adAccountId: string;
  adCampaignId: string;
  adSetId: string;
}) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/ad_campaigns/${params.adCampaignId}/ad_sets/${params.adSetId}/summary`,
      {
        method: "GET",
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function createAdSet(params: CreateAdSetRequest) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/ad_campaigns/${params.adCampaignId}/ad_sets`,
      {
        method: "POST",
        body: {
          name: params.name,
          unit_price: params.unitPrice,
          unit_type: "impression",
          ad_content_type: "ad_link",
          targeting_query_id: params.targetingQueryId,
          started_at: params.startedAt,
          ended_at: params.endedAt,
          frequency_cap: params.frequencyCap,
          frequency_cap_period: params.frequencyCapPeriod,
          strength: params.strength,
          conversion_tag_ids: params.conversionTagIds,
        },
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function updateAdSet(params: UpdateAdSetRequest) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/ad_campaigns/${params.adCampaignId}/ad_sets/${params.adSetId}`,
      {
        method: "PUT",
        body: {
          name: params.name,
          unit_price: params.unitPrice,
          enabled: params.enabled,
          targeting_query_id: params.targetingQueryId,
          started_at: params.startedAt,
          ended_at: params.endedAt,
          frequency_cap: params.frequencyCap,
          frequency_cap_period: params.frequencyCapPeriod,
          strength: params.strength,
          conversion_tag_ids: params.conversionTagIds,
        },
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function createAdContent(params: CreateAdContentRequest) {
  return async () => {
    const {
      adAccountId,
      adCampaignId,
      adSetId,
      name,
      title,
      sourceName,
      finalUrl,
      rawImage,
      onPageloadFinishedScript,
    } = params;
    const requestBody = {
      name,
      title,
      source_name: sourceName,
      final_url: finalUrl,
      on_pageload_finished_script: onPageloadFinishedScript,
    };
    if (rawImage) {
      const base64Image = rawImage.split(",")[1];
      requestBody["image_base64"] = base64Image;
    }
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/ad_sets/
      ${adSetId}/ad_contents`,
      {
        method: "POST",
        body: requestBody,
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function updateAdContent(params: UpdateAdContentRequest) {
  return async () => {
    const {
      adAccountId,
      adCampaignId,
      adSetId,
      id,
      rawImage,
      ...body
    } = params;
    if (rawImage) {
      const base64Image = rawImage.split(",")[1];
      body["image_base64"] = base64Image;
    }
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/ad_sets/
      ${adSetId}/ad_contents/${id}`,
      {
        method: "PUT",
        body: decamelizeKeys(
          Object.keys(body).reduce((o, k) => {
            if (o[k] == null) {
              delete o[k];
            }
            return o;
          }, body)
        ),
      }
    );
    if (res.status === 204) {
      return {};
    }
    return camelizeKeys(await res.json());
  };
}

export function fetchTargetingQueries() {
  return async () => {
    const res = await updatesContentsApi(`/api/admin/targeting_queries`, {
      method: "GET",
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchTargetingQueryNames() {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/targeting_queries?fields=id,name`,
      {
        method: "GET",
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchTargetingCount(params: TargetingCountRequest) {
  return async () => {
    const { query, expanded } = params;
    const res = await updatesContentsApi(
      `/api/admin/targeting_queries/targeting_count`,
      {
        method: "POST",
        body: {
          query,
          expanded,
        },
      }
    );
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchQueryJobResult(params: FetchQueryJobResultRequest) {
  return async () => {
    const { jobId } = params;
    const res = await updatesContentsApi(
      `/api/admin/targeting_queries/query_job_result?job_id=${jobId}`,
      {
        method: "GET",
      }
    );
    if (res.status === 202 || res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function createTargetingQuery(params: CreateTargetingQueryRequest) {
  return async () => {
    const { name, query, expanded, targetingCount } = params;
    const res = await updatesContentsApi(`/api/admin/targeting_queries`, {
      method: "POST",
      body: {
        name,
        query,
        expanded,
        target_numbers: targetingCount,
      },
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function updateTargetingQuery(params: UpdateTargetingQueryRequest) {
  return async () => {
    const { id, name, query, expanded, targetingCount } = params;
    const res = await updatesContentsApi(`/api/admin/targeting_queries/${id}`, {
      method: "PUT",
      body: {
        name,
        query,
        expanded,
        target_numbers: targetingCount,
      },
    });
    if (res.status === 204) {
      return {};
    }
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function fetchConversionTagSummary(params: {
  adAccountId: string;
  conversionTagId: string;
}) {
  return async () => {
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${params.adAccountId}/conversion_tags/${params.conversionTagId}/summary`,
      {
        method: "GET",
      }
    );
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function createConversionTag(params: CreateConversionTagRequest) {
  return async () => {
    const { name, adAccountId } = params;
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${adAccountId}/conversion_tags`,
      {
        method: "POST",
        body: {
          name,
        },
      }
    );
    const body = await res.json();
    return camelizeKeys(body);
  };
}

export function updateConversionTag(params: UpdateConversionTagRequest) {
  return async () => {
    const { id, name, adAccountId } = params;
    const res = await updatesContentsApi(
      `/api/admin/ad_accounts/${adAccountId}/conversion_tags/${id}`,
      {
        method: "PUT",
        body: {
          name,
        },
      }
    );
    const body = await res.json();
    return camelizeKeys(body);
  };
}
