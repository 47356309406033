import actionCreatorFactory, { Success, Failure } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { Reducer } from "redux";

import {
  changeLink,
  addLinkListItem,
  searchLinks,
  ChangeLinkParams,
  ChangeLinkResponse,
  AddLinkListItemParams,
  AddLinkListItemResponse,
} from "./link";
import {
  addContent,
  fetchSceneEntries,
  FetchScenesResponse,
  AddContentParams,
  PublishParams,
  publishSceneEntries,
} from "./sceneEntry";
import { fetchAdAccountNavigation, fetchAdAccountSummary } from "./adAccount";
import { fetchAdCampaignSummary } from "./adCampaign";
import {
  fetchAdSetSummary,
  fetchTargetingCount,
  fetchQueryJobResult,
  targetingQueryCanceled,
  createTargetingQuery,
  updateTargetingQuery,
} from "./adSet";
import { fetchLinkReports, FetchLinkReportResponse } from "./linkReport";
import { DragLinkListItemParams, dragLinkListItem } from "./linkList";

// Type
// -----------------------------------------------

interface UiArticles {
  edited: boolean;
  searchingLinks: boolean;
  focusedLinkListUuid: string;
  focusedLinkUuid: string;
  focusedSceneEntryId: string;
  loadingLinkReports: boolean;
}

interface AdAccountSummary {
  focusedAdCampaignId: string;
}

interface AdCampaignSummary {
  focusedAdSetId: string;
}

interface AdSetSummary {
  focusedAdContentId: string;
}

interface TargetingQueries {
  canSubmit: boolean;
}

//  State
// -----------------------------------------------

export interface UiState {
  articles: UiArticles;
  adAccountSummary: AdAccountSummary;
  adCampaignSummary: AdCampaignSummary;
  adSetSummary: AdSetSummary;
  targetingQueries: TargetingQueries;
}

const initialState: UiState = {
  articles: {
    edited: false,
    searchingLinks: false,
    focusedLinkListUuid: "",
    focusedLinkUuid: "",
    focusedSceneEntryId: "",
    loadingLinkReports: false,
  },
  adAccountSummary: {
    focusedAdCampaignId: "",
  },
  adCampaignSummary: {
    focusedAdSetId: "",
  },
  adSetSummary: {
    focusedAdContentId: "",
  },
  targetingQueries: {
    canSubmit: false,
  },
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("UI");

export interface LinkListSelectedParams {
  linkListUuid: string;
  sceneEntryId: string;
}
export const linkListSelected = actionCreator<LinkListSelectedParams>(
  "LINK_LIST_SELECTED"
);

export interface LinkSelectedParams {
  linkUuid: string;
  linkListUuid: string;
  sceneEntryId: string;
}
export const linkSelected = actionCreator<LinkSelectedParams>("LINK_SELECTED");

export interface AdCampaignFocused {
  adCampaignId: string;
}
export const adCampaignFocused = actionCreator<AdCampaignFocused>(
  "AD_CAMPAIGN_FOCUSED"
);

export interface AdSetFocused {
  adSetId: string;
}
export const adSetFocused = actionCreator<AdSetFocused>("AD_SET_FOCUSED");

export interface AdContentFocused {
  adContentId: string;
}
export const adContentFocused = actionCreator<AdContentFocused>(
  "AD_CONTENT_FOCUSED"
);

//  Reducer
// -----------------------------------------------

const uiReducer: Reducer<UiState> = reducerWithInitialState<UiState>(
  initialState
)
  .cases<
    Success<ChangeLinkParams, ChangeLinkResponse>,
    Success<AddLinkListItemParams, AddLinkListItemResponse>,
    AddContentParams,
    Success<void, FetchScenesResponse>
  >(
    [changeLink.done, addLinkListItem.done, addContent, fetchSceneEntries.done],
    state =>
      produce(state, (draft: UiState) => {
        draft.articles.edited = true;
      })
  )
  .cases<DragLinkListItemParams>([dragLinkListItem], state =>
    produce(state, (draft: UiState) => {
      draft.articles.edited = true;
    })
  )
  .cases<Success<void, FetchScenesResponse>, PublishParams>(
    [fetchSceneEntries.done, publishSceneEntries.started],
    state =>
      produce(state, (draft: UiState) => {
        draft.articles.edited = false;
      })
  )
  .case(linkListSelected, (state, { linkListUuid, sceneEntryId }) =>
    produce(state, (draft: UiState) => {
      draft.articles.focusedLinkListUuid = linkListUuid;
      draft.articles.focusedLinkUuid = "";
      draft.articles.focusedSceneEntryId = sceneEntryId;
    })
  )
  .case(linkSelected, (state, { linkUuid, linkListUuid, sceneEntryId }) =>
    produce(state, (draft: UiState) => {
      draft.articles.focusedLinkListUuid = linkListUuid;
      draft.articles.focusedLinkUuid = linkUuid;
      draft.articles.focusedSceneEntryId = sceneEntryId;
    })
  )
  .cases(
    [
      fetchAdAccountNavigation.done,
      fetchAdAccountSummary.done,
      fetchAdCampaignSummary.done,
      fetchAdSetSummary.done,
    ],
    state =>
      produce(state, (draft: UiState) => {
        draft.adAccountSummary.focusedAdCampaignId = "";
        draft.adCampaignSummary.focusedAdSetId = "";
        draft.adSetSummary.focusedAdContentId = "";
      })
  )
  .case(adCampaignFocused, (state, { adCampaignId }) =>
    produce(state, (draft: UiState) => {
      draft.adAccountSummary.focusedAdCampaignId = adCampaignId;
    })
  )
  .case(adSetFocused, (state, { adSetId }) =>
    produce(state, (draft: UiState) => {
      draft.adCampaignSummary.focusedAdSetId = adSetId;
    })
  )
  .case(adContentFocused, (state, { adContentId }) =>
    produce(state, (draft: UiState) => {
      draft.adSetSummary.focusedAdContentId = adContentId;
    })
  )
  .case(fetchTargetingCount.started, (state, {}) =>
    produce(state, (draft: UiState) => {
      draft.targetingQueries.canSubmit = false;
    })
  )
  .cases(
    [
      targetingQueryCanceled,
      fetchTargetingCount.failed,
      createTargetingQuery.started,
      updateTargetingQuery.started,
    ],
    (state, {}) =>
      produce(state, (draft: UiState) => {
        draft.targetingQueries.canSubmit = false;
      })
  )
  .case(fetchQueryJobResult.done, (state, { result: { targetingCount } }) =>
    produce(state, (draft: UiState) => {
      if (!!targetingCount && targetingCount !== "" && targetingCount !== "0") {
        draft.targetingQueries.canSubmit = true;
      }
    })
  )
  .case(searchLinks.started, state =>
    produce(state, (draft: UiState) => {
      draft.articles.searchingLinks = true;
    })
  )
  .cases([searchLinks.done, searchLinks.failed], state =>
    produce(state, (draft: UiState) => {
      draft.articles.searchingLinks = false;
    })
  )
  .case(fetchLinkReports.started, state =>
    produce(state, (draft: UiState) => {
      draft.articles.loadingLinkReports = true;
    })
  )
  .cases<Success<void, FetchLinkReportResponse>, Failure<void, Error>>(
    [fetchLinkReports.done, fetchLinkReports.failed],
    state =>
      produce(state, (draft: UiState) => {
        draft.articles.loadingLinkReports = false;
      })
  );
export default uiReducer;
