import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import colors from "../../styles/color";
import { RootState } from "../../store/modules";
import { LinkList } from "../../store/modules/linkList";
import { subjectsSelector } from "../../store/selectors/linkList";
import {
  UpdateLinkListParams,
  updateLinkList,
} from "../../store/modules/sceneEntry";

interface OwnProps {
  sceneEntryId: string;
  linkList: LinkList;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

function SubjectSelectContainer(props: Props) {
  //  props
  // -----------------------------------------------

  const { subjects, linkList } = props;

  //  functions
  // -----------------------------------------------

  const handleChangeSubject = (e: React.FormEvent<HTMLSelectElement>) => {
    const { linkList, sceneEntryId } = props;
    const { value: subjectId } = e.currentTarget;
    const editedLinkList = { ...linkList, subjectId };
    props.updateLinkList({ linkList: editedLinkList, sceneEntryId });
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      <StyledSelect onChange={handleChangeSubject} value={linkList.subjectId}>
        {Object.entries(subjects).map(([k, v]) => (
          <option key={k} value={k}>
            {v.name}
          </option>
        ))}
      </StyledSelect>
    </Container>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectSelectContainer);

function mapStateToProps(state: RootState) {
  return {
    subjects: subjectsSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateLinkList: ({
        linkList,
        sceneEntryId,
        range,
      }: UpdateLinkListParams) =>
        updateLinkList({ linkList, sceneEntryId, range }),
    },
    dispatch
  );
}

const Container = styled.div``;

const StyledSelect = styled.select`
  border-style: none;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  line-height: 1.75;
  text-indent: 16px;
  font-size: 16px;
  color: ${colors.blackForeground840};
`;
