import { createSelector } from "reselect";

import { RootState } from "../modules";

const uiSelector = (state: RootState) => state.ui;

export const isArticleEditedSelector = createSelector(
  uiSelector,
  ui => ui.articles.edited
);

export const focusedLinkListUuidSelector = createSelector(
  uiSelector,
  ui => ui.articles.focusedLinkListUuid
);

export const focusedLinkUuidSelector = createSelector(
  uiSelector,
  ui => ui.articles.focusedLinkUuid
);

export const focusedSceneEntryIdSelector = createSelector(
  uiSelector,
  ui => ui.articles.focusedSceneEntryId
);

export const searchingLinksSelector = createSelector(
  uiSelector,
  ui => ui.articles.searchingLinks
);
export const loadingLinkReportsSelector = createSelector(
  uiSelector,
  ui => ui.articles.loadingLinkReports
);
