import React from "react";

import RootTemplate from "./RootTemplate";
import AdAccountSummaryContainer from "./AdAccountSummaryContainer";

function AdAccountSummaryPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <AdAccountSummaryContainer />;
}

export default AdAccountSummaryPage;
