import React from "react";
import styled from "styled-components";

import AdGlobalNavigation from "../AdAdmin/AdGlobalNavigation";

import AdNavigationContainer from "./AdNavigation/AdNavigationContainer";

interface RenderProps {
  children?: React.ReactNode;
}

interface Props {
  render: (args?: RenderProps) => React.ReactNode;
}

function RootTemplate(props: Props) {
  // jsx
  // -----------------------------------------------

  return (
    <Container>
      <Admin>
        <AdGlobalNavigation />
      </Admin>
      <Side>
        <AdNavigationContainer />
      </Side>
      <Main>{props.render()}</Main>
    </Container>
  );
}

export default RootTemplate;

//  style
// -----------------------------------------------

const Container = styled.div`
  display: grid;
  grid-template-columns: 64px 240px 1fr;
  grid-template-rows: 100vh;
`;

const Admin = styled.div`
  background-color: #fafafa;
`;

const Side = styled.div`
  background-color: #fafafa;
  overflow-y: scroll;
`;

const Main = styled.div`
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow-y: scroll;
`;
