import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { heading } from "../styles/font";
import colors from "../styles/color";

function NavigationContainer() {
  return (
    <Container>
      <AdLink to="/admin/ad/ad_accounts">ADS</AdLink>
      <AdSettingsLink to="/admin/ad/ad_settings">SETTINGS</AdSettingsLink>
    </Container>
  );
}

export default NavigationContainer;

const Container = styled.div`
  width: 188px;
  background-color: var(--gray25);
`;

const AdLink = styled(Link)`
  ${heading}
  margin-top: 74px;
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 16px;
  color: ${colors.blackForeground600};
  display: block;
`;

const AdSettingsLink = styled(Link)`
  ${heading}
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 16px;
  color: ${colors.blackForeground600};
  display: block;
`;
