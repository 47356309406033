import React from "react";
import { RouteChildrenProps } from "react-router";

import LinkReportIndexContainer from "./LinkReportIndexContainer";

function LinkReportIndexPage(props: RouteChildrenProps) {
  if (!props.match) {
    return null;
  }

  return <LinkReportIndexContainer />;
}

export default LinkReportIndexPage;
