import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import uuid from "uuid";
import { Droppable, Draggable } from "react-beautiful-dnd";

import {
  addContent,
  SceneEntry,
  updateSceneEntry,
  UpdateSceneEntryParams,
  AddContentParams,
  ContentEntry,
} from "../../store/modules/sceneEntry";
import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { LinkList } from "../../store/modules/linkList";
import { RootState } from "../../store/modules";
import { maxDraftContentEntryIdSelector } from "../../store/selectors/sceneEntries";

import ContentEntryContainer from "./ContentEntryContainer";
import EditableSceneEntryHeaderContainer from "./EditableSceneEntryHeaderContainer";

interface OwnProps {
  sceneEntry: SceneEntry;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

function SceneEntryContainer(props: Props) {
  //  props & state
  // -----------------------------------------------

  const { sceneEntry, maxDraftContentEntryId } = props;
  const { contentEntries } = sceneEntry;

  //  functions
  // -----------------------------------------------

  const handleClickAddContent = () => {
    const { sceneEntry } = props;
    const linkList: LinkList = {
      uuid: uuid(),
      subjectId: "1",
      subjectType: "OfficialSubject",
      reason: "manually_submitted",
      heading: "",
      createdAt: null,
      updatedAt: null,
      links: [],
    };
    const contentEntry: ContentEntry = {
      id: `DRAFT_ID:${maxDraftContentEntryId}`, // TODO:(kdnk) Use unique id generated at frontend
      deliverableFrom: null,
      deliverableUntil: null,
      sceneEntryId: sceneEntry.id,
      contentUuid: linkList.uuid,
      order: "1",
      createdAt: null,
      updatedAt: null,
      content: linkList.uuid,
    };
    props.addContent({ sceneEntryId: sceneEntry.id, linkList, contentEntry });
  };

  const handleChangeHeader = (headline: string) => {
    props.updateSceneEntry({ sceneEntryId: sceneEntry.id, headline });
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Container>
      <EditableSceneEntryHeaderContainer
        header={
          sceneEntry.sceneEvent && sceneEntry.sceneEvent.headline
            ? sceneEntry.sceneEvent.headline
            : ""
        }
        onChangeHeader={handleChangeHeader}
      />
      <Droppable
        droppableId={`sceneEntries-index:${sceneEntry.id}`}
        type="SCENE_ENTRY"
      >
        {(provided, _snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {contentEntries.map((contentEntry, index) => {
              return (
                <Draggable
                  key={`${contentEntry.id}`}
                  draggableId={`draggable-content-entry-${contentEntry.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ContentEntryContainer
                        key={contentEntry.id}
                        contentEntry={contentEntry}
                        sceneEntryId={sceneEntry.id}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
      <AddContent onClick={handleClickAddContent}>+ Add Content</AddContent>
    </Container>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SceneEntryContainer);

function mapStateToProps(state: RootState) {
  return {
    maxDraftContentEntryId: maxDraftContentEntryIdSelector(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      addContent: ({
        sceneEntryId,
        linkList,
        contentEntry,
      }: AddContentParams) =>
        addContent({ sceneEntryId, linkList, contentEntry }),
      updateSceneEntry: ({ sceneEntryId, headline }: UpdateSceneEntryParams) =>
        updateSceneEntry({ sceneEntryId, headline }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid ${colors.blackForeground100};
`;

const AddContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 0 8px;
  border-radius: 8px;
  backdrop-filter: blur(2px);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  ${heading}
  font-size: 14px;
  line-height: 1.14;
  color: ${colors.blackForeground600};
  cursor: pointer;
`;
