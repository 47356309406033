import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";

import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  UpdateAdContentRequest,
  updateAdContent,
} from "../../store/modules/adContent";
import Toggle from "../../lib/Toggle";

import AdContentReportContainer from "./AdContentReportContainer";
import AdContentSettingsContainer from "./AdContentSettingsContainer";

type Open = "reports" | "settings";
interface State {
  open: Open;
  name?: string;
  enabeld: boolean;
}

interface OwnProps {
  adContentId: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  OwnProps;

class AdContentDetailContainer extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const adContent = this.props.idAdContent[this.props.adContentId];
    this.state = {
      open: this.props.adContentId ? "reports" : "settings",
      name: adContent ? adContent.name : "",
      enabeld: adContent ? adContent.enabled : false,
    };
    this.handleChangeToggleEnabled = this.handleChangeToggleEnabled.bind(this);
  }

  public render() {
    const adContent = this.props.idAdContent[this.props.adContentId];
    const { adContentId } = this.props;
    return (
      <Container>
        <Header>
          <Name>{!!adContent ? adContent.name : "New Ad Content"}</Name>
          <EnableToggle
            checked={adContent && adContent.enabled ? adContent.enabled : false}
            icons={false}
            onChange={this.handleChangeToggleEnabled}
            disabled={adContentId === null}
          />
        </Header>
        <TabList>
          <ReportsTab
            onClick={this.handleOnClick("reports")}
            open={this.state.open === "reports"}
          >
            REPORTS
          </ReportsTab>
          <SettingsTab
            onClick={this.handleOnClick("settings")}
            open={this.state.open === "settings"}
          >
            SETTINGS
          </SettingsTab>
        </TabList>
        <Content>
          {this.state.open === "reports" ? (
            <AdContentReportContainer adContentId={adContentId} />
          ) : (
            <AdContentSettingsContainer adContentId={adContentId} />
          )}
        </Content>
      </Container>
    );
  }

  private handleOnClick = (open: Open) => () => {
    this.setState({ open });
  };

  private handleChangeToggleEnabled() {
    const { adContentId } = this.props;
    if (!adContentId || !this.props.match) {
      return;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const adCampaignId = params["ad_campaign_id"];
    const adSetId = params["ad_set_id"];
    const adCampaign = this.props.idAdContent[adContentId];
    this.props.updateAdContent({
      adAccountId,
      adCampaignId,
      adSetId,
      id: adContentId,
      enabled: !adCampaign.enabled,
    });
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { adContents: idAdContent } = state.adContent;
  return { idAdContent };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateAdContent: (params: UpdateAdContentRequest) =>
        updateAdContent.started(params),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdContentDetailContainer)
);

const Container = styled.div`
  width: 320px;
  padding: 24px;
  background-color: #fafafa;
`;

const Header = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const Name = styled.div`
  ${heading}
  width: 220px;
  min-height: 28px;
  word-wrap: break-word;
  font-size: 20px;
  line-height: 1.4;
  color: ${colors.blackForeground840};
`;

const EnableToggle = styled(Toggle)`
  margin: auto;
`;

const TabList = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Tab = styled.div<{ open: boolean }>`
  ${heading}
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${colors.blackForeground600};

  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom-style: ${props => (props.open ? "solid" : "")};
  border-bottom-color: #7a78ff;
  cursor: pointer;
`;

const ReportsTab = styled(Tab)``;

const SettingsTab = styled(Tab)`
  margin-left: 20px;
`;

const Content = styled.div`
  width: 272px;
  height: 100vh;
  margin-top: 30px;
`;
