import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactModal from "react-modal";

import Icon from "../../src/lib/Icon";
import { RootState } from "../store/modules";
import {
  fetchAdAccounts,
  createAdAccount,
  updateAdAccount,
} from "../store/modules/adAccount";
import colors from "../styles/color";
import { heading } from "../styles/font";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDisptchToProps>;

interface State {
  showModal: boolean;
  focusedAdAccountId: string;
  name: string;
}

class AdAccountContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      focusedAdAccountId: "",
      name: "",
    };
    this.handleClickNewAccount = this.handleClickNewAccount.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleDone = this.handleDone.bind(this);
  }

  public componentDidMount() {
    this.props.fetchAdAccounts();
  }

  public render() {
    const { idAdAccount } = this.props;
    return (
      <Container>
        <AdAccountModal
          isOpen={this.state.showModal}
          ariaHideApp={false}
          style={{
            overlay: {
              position: "fixed",
              backgroundColor: "rgba(224, 224, 224, 0.64)",
            },
            content: {
              position: "absolute",
              width: "640px",
              height: "180px",
              borderRadius: "4px",
              top: "360px",
              left: "400px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              outline: "none",
            },
          }}
        >
          <NameInput
            name="name"
            value={this.state.name}
            placeholder="Account Name"
            onChange={this.handleChangeName}
          />
          <ButtonList>
            <CancelButton onClick={this.handleCancel}>Cancel</CancelButton>
            <DoneButton onClick={this.handleDone}>Done</DoneButton>
          </ButtonList>
        </AdAccountModal>
        <HeadWrapper>
          <Head>Ad Accounts</Head>
          <NewAccountButton onClick={this.handleClickNewAccount}>
            New Account
          </NewAccountButton>
        </HeadWrapper>
        <AdAccountList>
          {this.props.adAccountIds.map(adAccountId => (
            <AdAccount key={adAccountId}>
              <Name>{idAdAccount[adAccountId].name}</Name>
              <PencilIcon
                name={"wt-icon-pencil"}
                onClick={() => this.handleClickPencil(adAccountId)}
              />
            </AdAccount>
          ))}
        </AdAccountList>
      </Container>
    );
  }

  private handleClickNewAccount() {
    this.setState({ showModal: true });
  }

  private handleClickPencil(adAccountId: string) {
    const adAccount = this.props.idAdAccount[adAccountId];
    this.setState({
      name: adAccount.name,
      showModal: true,
      focusedAdAccountId: adAccountId,
    });
  }

  private handleCancel() {
    this.setState({ name: "", showModal: false, focusedAdAccountId: "" });
  }

  private handleDone() {
    const { focusedAdAccountId: id, name } = this.state;
    if (!name) {
      return;
    }
    if (this.state.focusedAdAccountId) {
      this.props.updateAdAccount({ id, name });
      this.setState({ name: "", showModal: false, focusedAdAccountId: "" });
    } else {
      this.props.createAdAccount({ name });
      this.setState({ name: "", showModal: false });
    }
  }

  private handleChangeName(e: React.FormEvent<HTMLInputElement>) {
    this.setState({ name: e.currentTarget.value });
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { adAccounts: idAdAccount, adAccountIds } = state.adAccount;

  return { idAdAccount, adAccountIds };
}

function mapDisptchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchAdAccounts: () => fetchAdAccounts.started(),
      createAdAccount: (params: { name: string }) =>
        createAdAccount.started(params),
      updateAdAccount: (params: { id: string; name: string }) =>
        updateAdAccount.started(params),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDisptchToProps)(AdAccountContainer);

const Container = styled.div`
  width: 640px;
  margin-top: 73px;
  margin-left: 272px;
`;

const AdAccountModal = styled(ReactModal)``;

const HeadWrapper = styled.div`
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Head = styled.div`
  ${heading}
  background-color: var(--gray25);
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.84);
`;

const NewAccountButton = styled.button`
  ${heading}
  font-size: 14px;
  line-height: 1.71;
  border-radius: 24px;
  color: #ffffff;
  width: 136px;
  height: 40px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  cursor: pointer;
`;

const NameInput = styled.input`
  ${heading}
  width: 536px;
  font-size: 20px;
  line-height: 1.4;
  width: 560px;
  height: 44px;
  border: none;
  border-radius: 22px;
  text-indent: 10px;
  margin: 46px 40px auto;
  color: ${colors.blackForeground840};
  background-color: rgba(0, 0, 0, 0.03);
`;

const ButtonList = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 36px 20px 18px auto;
`;

const CancelButton = styled.button`
  ${heading}
  width: 88px;
  height: 36px;
  border-radius: 18px;
  margin-right: 8px;
  color: ${colors.blackForeground600};
  background-color: var(--white);
  cursor: pointer;
`;

const DoneButton = styled.button`
  ${heading}
  width: 135px;
  height: 36px;
  border-radius: 18px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  line-height: 1.71;
  color: white;
  cursor: pointer;
`;

const AdAccountList = styled.div`
  margin-top: 45px;
`;

const AdAccount = styled.div`
  min-height: 48px;
  padding: 12px 16px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const Name = styled.div`
  ${heading}
  font-size: 14px;
  line-height: 1.71;
  max-width: 580px;
  word-wrap: break-word;
  color: ${colors.blackForeground800};
`;

const PencilIcon = styled(Icon)`
  font-size: 15.5px;
  cursor: pointer;
`;
