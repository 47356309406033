import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Reducer } from "redux";
import produce from "immer";

import { fetchAdSetSummary } from "./adSet";

//  Types
// -----------------------------------------------

export type AdContentStatus = "ACTIVE" | "STOPPED";

export interface IdAdContent {
  [id: string]: AdContent;
}

export interface AdContent {
  id: string;
  name: string;
  title: string;
  sourceName: string;
  finalUrl: string;
  enabled: boolean;
  adSetid: string;
  imageUrl: string;
  status: AdContentStatus;
  onPageloadFinishedScript: string;
  javascriptEnabledUrl: string;
  report: {
    impression: string;
    click: string;
    ctr: string;
    cpc: string;
    spentBudget: string;
  };
}

export interface UpdateAdContentRequest {
  id: string;
  name?: string;
  title?: string;
  sourceName?: string;
  finalUrl?: string;
  enabled?: boolean;
  rawImage?: string;
  adAccountId: string;
  adCampaignId: string;
  adSetId: string;
  onPageloadFinishedScript?: string;
  javascriptEnabledUrl?: string;
}

export interface UpdateAdContentResponse {
  id: string;
  name: string;
  title: string;
  sourceName: string;
  finalUrl: string;
  enabled: boolean;
  adSetid: string;
  imageUrl: string;
  status: AdContentStatus;
  onPageloadFinishedScript: string;
  javascriptEnabledUrl: string;
}

export interface CreateAdContentRequest {
  name: string;
  title: string;
  sourceName: string;
  finalUrl: string;
  rawImage: string;
  adAccountId: string;
  adCampaignId: string;
  adSetId: string;
  onPageloadFinishedScript: string;
  javascriptEnabledUrl: string;
}

export interface CreateAdContentResponse {
  id: string;
  name: string;
  title: string;
  sourceName: string;
  finalUrl: string;
  enabled: boolean;
  adSetid: string;
  imageUrl: string;
  status: AdContentStatus;
  onPageloadFinishedScript: string;
  javascriptEnabledUrl: string;
}

//  State
// -----------------------------------------------

export interface AdContentState {
  adContents: IdAdContent;
}

export const initialState: AdContentState = {
  adContents: {},
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("AD_CONTENT");

export const createAdContent = actionCreator.async<
  CreateAdContentRequest,
  CreateAdContentResponse,
  Error
>("CREATE_AD_CONTENT");

export const updateAdContent = actionCreator.async<
  UpdateAdContentRequest,
  UpdateAdContentResponse,
  Error
>("UPDATE_AD_CONTENT");

//  Ad Content Reducer
// -----------------------------------------------

const adContentReducer: Reducer<AdContentState> = reducerWithInitialState<
  AdContentState
>(initialState)
  .case(fetchAdSetSummary.done, (state, { result: adSetSummary }) =>
    produce(state, (draft: AdContentState) => {
      adSetSummary.adContents.forEach(adContent => {
        draft.adContents[adContent.id] = adContent;
      });
    })
  )
  .case(createAdContent.done, (state, { result: createdAdContent }) =>
    produce(state, (draft: AdContentState) => {
      draft.adContents[createdAdContent.id] = {
        ...createdAdContent,
        report: {
          impression: "0",
          click: "0",
          cpc: "0",
          ctr: "0",
          spentBudget: "0",
        },
      };
    })
  )
  .case(updateAdContent.done, (state, { result: updatedAdContent }) =>
    produce(state, (draft: AdContentState) => {
      Object.assign(draft.adContents[updatedAdContent.id], updatedAdContent);
    })
  );

export default adContentReducer;
