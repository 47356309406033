import React from "react";
import styled from "styled-components";

import colors from "../../styles/color";
import { heading } from "../../styles/font";
import Icon from "../../lib/Icon";
import { history } from "../../App";

interface Content {
  uuid: string;
  createdAt: string;
  updatedAt: string;
}

interface Props {
  title: string;
  content: Content;
  onDelete?: (args: any) => void;
}

function LinkEditHeader(props: Props) {
  const { title, content } = props;

  //  functions
  // -----------------------------------------------

  const handleClickTrashIcon = () => {
    const { content } = props;
    if (!props.onDelete) {
      return;
    }
    props.onDelete(content.uuid);
    history.push("/admin/articles/links");
  };

  //  jsx
  // -----------------------------------------------

  return (
    <Header>
      <HeaderMain>
        <Title>{title}</Title>
        <HeaderMeta>
          <Id>UUID: {content.uuid}</Id>
          <Dates>
            <CreatedAt>
              Created : {new Date(content.createdAt).toLocaleString("ja")}
            </CreatedAt>
            <UpdatedAt>
              Edited : {new Date(content.updatedAt).toLocaleString("ja")}
            </UpdatedAt>
          </Dates>
        </HeaderMeta>
      </HeaderMain>
      <div>
        {!!props.onDelete && (
          <TrashIcon onClick={handleClickTrashIcon} name="wt-icon-trash" />
        )}
      </div>
    </Header>
  );
}

export default LinkEditHeader;

//  style
// -----------------------------------------------

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 32px;
`;

const HeaderMain = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  ${heading}
  font-size: 32px;
  color: ${colors.blackForeground840};
`;

const HeaderMeta = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground400};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
`;

const Id = styled.div`
  margin-bottom: 2px;
`;

const Dates = styled.div`
  display: flex;
  margin-top: 2px;
`;

const CreatedAt = styled.div``;
const UpdatedAt = styled.div`
  margin-left: 8px;
`;

const SubHeader = styled.div`
  ${heading}
  font-size: 20px;
  line-height: 1.4;
  color: ${colors.blackForeground840};
  padding: 0 16px;
  margin-bottom: 16px;
`;

const TrashIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: ${colors.blackForeground400};
  cursor: pointer;
`;
