import React from "react";

import RootTemplate from "./RootTemplate";
import TargetingQueryContainer from "./TargetingQueryContainer";

function TargetingQueryPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <TargetingQueryContainer />;
}

export default TargetingQueryPage;
