import produce from "immer";
import actionCreatorFactory, { Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Reducer } from "redux";

import { Link, addLinkListItem, changeLink } from "./link";
import {
  addContent,
  fetchSceneEntries,
  updateLinkList,
  FetchScenesResponse,
} from "./sceneEntry";

//  Types
// -----------------------------------------------

export interface LinkList {
  uuid: string;
  subjectType: string;
  subjectId: string;
  reason: string;
  heading: string;
  createdAt: string | null;
  updatedAt: string | null;
  links: string[];
}

export interface LinkListResponse {
  uuid: string;
  subjectType: string;
  subjectId: string;
  reason: string;
  heading: string;
  createdAt: string;
  updatedAt: string;
  links: Link[];
}

export interface UuidLinkList {
  [uuid: string]: LinkList;
}

export interface Orders {
  [linkUuid: string]: string;
}

export interface OfficialSubject {
  avatarUrl: string;
  name: string;
  transitionUrl?: string;
  shortDescription?: string;
}

export interface IdSubject {
  [id: string]: OfficialSubject;
}

//  State
// -----------------------------------------------

export interface LinkListState {
  linkLists: UuidLinkList;
  subjects: IdSubject;
}

export const initialState: LinkListState = {
  linkLists: {},
  subjects: {},
};

//  Action
// -----------------------------------------------

const actionCreator = actionCreatorFactory("LINK_LIST");

//  DELETE_LINK_LIST
// -----------------------
export interface DeleteLinkListParams {
  uuid: string;
}
export const deleteLinkList = actionCreator<DeleteLinkListParams>(
  "DELETE_LINK_LIST"
);

//  DRAG_LINK_LIST_ITEM
// -----------------------
export interface DragLinkListItemParams {
  linkListUuid: string;
  startIndex: number;
  endIndex: number;
}
export interface DragLinkListItemResponse {
  linkList: LinkList;
  orders: Orders;
}
export const dragLinkListItem = actionCreator<DragLinkListItemParams>(
  "DRAG_LINK_LIST_ITEM"
);

//  DRAG_FROM_LINK_INDEX
// -----------------------
export interface DragFromLinkIndexParams {
  linkListUuid: string;
  linkUuid: string;
  insertedIndex: number;
}
export const dragFromLinkIndex = actionCreator<DragFromLinkIndexParams>(
  "DRAG_FROM_LINK_INDEX"
);

//  DELETE_LINK_LIST_ITEM
// -----------------------
export interface DeleteLinkListItemParams {
  linkUuid: string;
  linkListUuid: string;
}
export const deleteLinkListItem = actionCreator<DeleteLinkListItemParams>(
  "DELETE_LINK_LIST_ITEM"
);

//  Reducer
// -----------------------------------------------

const LinkListReducer: Reducer<LinkListState> = reducerWithInitialState<
  LinkListState
>(initialState)
  .case<Success<void, FetchScenesResponse>>(
    fetchSceneEntries.done,
    (state, { result: { sceneEntries, subjects } }) =>
      produce(state, (draft: LinkListState) => {
        const linkLists = {};
        sceneEntries.forEach(se => {
          se.contentEntries.forEach(ce => {
            // TODO:(kdnk) Check this links were already ordered.
            const linkUUids = ce.content.links.map(link => link.uuid);
            const content = { ...ce.content, links: linkUUids };
            linkLists[ce.content.uuid] = content;
          });
        });
        draft.linkLists = linkLists;
        draft.subjects = subjects;
      })
  )
  .case(
    addLinkListItem.done,
    (state, { params: { linkListUuid }, result: { link } }) =>
      produce(state, (draft: LinkListState) => {
        if (!draft.linkLists[linkListUuid].links) {
          draft.linkLists[linkListUuid].links = [link.uuid];
        } else if (!draft.linkLists[linkListUuid].links.includes(link.uuid)) {
          draft.linkLists[linkListUuid].links.push(link.uuid);
        }
      })
  )
  .case(addContent, (state, { linkList }) =>
    produce(state, (draft: LinkListState) => {
      draft.linkLists[linkList.uuid] = linkList;
    })
  )
  .case(updateLinkList, (state, { linkList }) =>
    produce(state, (draft: LinkListState) => {
      draft.linkLists[linkList.uuid] = { ...linkList };
    })
  )
  .case(deleteLinkList, (state, { uuid }) =>
    produce(state, (draft: LinkListState) => {
      delete draft.linkLists[uuid];
    })
  )
  .case(dragLinkListItem, (state, { linkListUuid, startIndex, endIndex }) =>
    produce(state, (draft: LinkListState) => {
      const { links } = draft.linkLists[linkListUuid];
      const [removed] = links.splice(startIndex, 1);
      links.splice(endIndex, 0, removed);
    })
  )
  .case(dragFromLinkIndex, (state, { linkListUuid, linkUuid, insertedIndex }) =>
    produce(state, (draft: LinkListState) => {
      const { links } = draft.linkLists[linkListUuid] || [];
      if (links && links.includes(linkUuid)) {
        return;
      }
      links.splice(insertedIndex, 0, linkUuid);
    })
  )
  .case(
    changeLink.done,
    (state, { params: { link: prevLink, linkListUuid }, result: { link } }) => {
      return produce(state, (draft: LinkListState) => {
        if (prevLink.uuid === link.uuid) {
          // noop
        } else if (linkListUuid) {
          const { links } = draft.linkLists[linkListUuid];
          const index = links.findIndex(link => link === prevLink.uuid);
          links.splice(index, 1, link.uuid);
        }
      });
    }
  )
  .case(deleteLinkListItem, (state, { linkListUuid, linkUuid }) =>
    produce(state, (draft: LinkListState) => {
      const linkList = draft.linkLists[linkListUuid];
      linkList.links = linkList.links.filter(l => l !== linkUuid);
    })
  );

export default LinkListReducer;
