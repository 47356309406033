import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";

import colors from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";
import {
  UpdateAdCampaignRequest,
  updateAdCampaign,
} from "../../store/modules/adCampaign";
import Toggle from "../../lib/Toggle";

import AdCampaignReportContainer from "./AdCampaignReportContainer";
import AdCampaignSettingsContainer from "./AdCampaignSettingsContainer";

type Open = "reports" | "budget";
interface State {
  open: Open;
}

interface OwnProps {
  adCampaignId: string;
}
type Props = ReturnType<typeof mapStateProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  OwnProps;

class AdCampaignDetailContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: this.props.adCampaignId ? "reports" : "budget",
    };
    this.handleChangeEnabledToggle = this.handleChangeEnabledToggle.bind(this);
  }

  public render() {
    const adCampaign = this.props.idAdCampaign[this.props.adCampaignId];
    const { adCampaignId } = this.props;
    return (
      <Container>
        <Header>
          <Name>{!!adCampaign ? adCampaign.name : "New Ad Campaign"}</Name>
          <EnableToggle
            checked={
              adCampaign && adCampaign.enabled ? adCampaign.enabled : false
            }
            icons={false}
            onChange={this.handleChangeEnabledToggle}
            disabled={adCampaignId === null}
          />
        </Header>
        <TabList>
          <ReportsTab
            open={this.state.open === "reports"}
            onClick={this.handleOnClick("reports")}
          >
            REPORTS
          </ReportsTab>
          <BudgetTab
            open={this.state.open === "budget"}
            onClick={this.handleOnClick("budget")}
          >
            BUDGET
          </BudgetTab>
        </TabList>
        <Content>
          {this.state.open === "reports" ? (
            <AdCampaignReportContainer adCampaignId={adCampaignId} />
          ) : (
            <AdCampaignSettingsContainer adCampaignId={adCampaignId} />
          )}
        </Content>
      </Container>
    );
  }

  private handleOnClick = (open: Open) => () => {
    this.setState({ open });
  };

  private handleChangeEnabledToggle() {
    const { adCampaignId } = this.props;
    if (!adCampaignId || !this.props.match) {
      return;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const adCampaign = this.props.idAdCampaign[adCampaignId];
    this.props.updateAdCampaign({
      adAccountId,
      adCampaignId,
      enabled: !adCampaign.enabled,
    });
  }
}

// connect
// -----------------------------------------------

function mapStateProps(state: RootState) {
  const { adCampaigns: idAdCampaign } = state.adCampaign;
  return { idAdCampaign };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateAdCampaign: (params: UpdateAdCampaignRequest) =>
        updateAdCampaign.started(params),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateProps, mapDispatchToProps)(AdCampaignDetailContainer)
);

const Container = styled.div`
  width: 320px;
  padding: 24px;
  background-color: #fafafa;
`;

const Header = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const Name = styled.div`
  ${heading}
  width: 220px;
  min-height: 28px;
  word-wrap: break-word;
  font-size: 20px;
  line-height: 1.4;
  color: ${colors.blackForeground840};
`;

const EnableToggle = styled(Toggle)`
  margin: auto;
`;

const TabList = styled.div`
  margin-top: 10px;
  display: flex;
`;

const Tab = styled.div<{ open: boolean }>`
  ${heading}
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${colors.blackForeground600};

  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom-style: ${props => (props.open ? "solid" : "")};
  border-bottom-color: #7a78ff;
  cursor: pointer;
`;

const ReportsTab = styled(Tab)``;

const BudgetTab = styled(Tab)`
  margin-left: 20px;
`;

const Content = styled.div`
  width: 272px;
  height: 100vh;
  margin-top: 30px;
`;
