import React, { useState, useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";

import { heading } from "../styles/font";
import { RootState } from "../store/modules";
import {
  createConversionTag,
  CreateConversionTagRequest,
  updateConversionTag,
  UpdateConversionTagRequest,
  fetchConversionTagSummary,
} from "../store/modules/adAccount";
import { getYashimaUpdatesContentsDomain } from "../setttings/constants";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

function ConversionTagContainer(props: Props) {
  //  hooks
  // -----------------------------------------------

  const [name, setName] = useState("");

  useEffect(() => {
    if (!props.match) {
      return;
    }
    const { params } = props.match;
    const conversionTagId = params["conversion_tag_id"];
    const adAccountId = params["ad_account_id"];
    const newConversionTagPath = `/admin/ad/ad_accounts/${adAccountId}/conversion_tags/new`;

    if (props.location.pathname === newConversionTagPath) {
      setName("");
      return;
    }

    props.fetchConversionTagSummary({ conversionTagId, adAccountId });
  }, [props.location]);

  useEffect(() => {
    if (!props.match) {
      return;
    }
    const { params } = props.match;
    const conversionTagId = params["conversion_tag_id"];
    const adAccountId = params["ad_account_id"];
    const conversionTag = props.idConversionTag[conversionTagId];
    const newConversionTagPath = `/admin/ad/ad_accounts/${adAccountId}/conversion_tags/new`;
    if (props.location.pathname === newConversionTagPath) {
      setName("");
      return;
    }
    setName(!!conversionTag ? conversionTag.name : "");
  }, [props.idConversionTag]);

  //  props & state
  // -----------------------------------------------

  if (!props.match) {
    return null;
  }
  const { params } = props.match;
  const adAccountId = params["ad_account_id"];
  const conversionTagId = params["conversion_tag_id"];
  const { idAdAccount, idConversionTag } = props;
  const adAccount = idAdAccount[adAccountId];
  if (!adAccount) {
    return null;
  }
  const conversionTag = idConversionTag[conversionTagId];

  //  functions
  // -----------------------------------------------

  const handleSubmit = () => {
    if (!name || name === "") {
      return;
    }
    if (!props.match) {
      return;
    }
    const { params } = props.match;
    const adAccountId = params["ad_account_id"];
    const conversionTagId = params["conversion_tag_id"];

    const newConversionTagPath = `/admin/ad/ad_accounts/${adAccountId}/conversion_tags/new`;
    if (props.location.pathname === newConversionTagPath) {
      props.createConversionTag({ name, adAccountId });
    } else {
      props.updateConversionTag({ id: conversionTagId, name, adAccountId });
    }
  };

  // jsx
  // -----------------------------------------------

  return (
    <Container>
      <Wrapper>
        <NameInput
          name="name"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        ></NameInput>
        {conversionTagId === "new" ? (
          <CreateConversionTag onClick={handleSubmit}>
            Create
          </CreateConversionTag>
        ) : (
          <UpdateConversionTag onClick={handleSubmit}>
            Update
          </UpdateConversionTag>
        )}
      </Wrapper>
      {!!conversionTag && !!conversionTag.report && (
        <ConversionCountWrapper>
          <ConversionCountHeader>Conversion Count: </ConversionCountHeader>
          <ConversionCountText>
            {conversionTag.report["conversion"]}
          </ConversionCountText>
        </ConversionCountWrapper>
      )}
      {!!conversionTag && (
        <JavaScriptTagWrapper>
          <JavaScriptTagHeader>CVタグ</JavaScriptTagHeader>
          <JavaScriptTag>
            {`<script src='${getYashimaUpdatesContentsDomain(process.env.REACT_APP_NODE_ENV)}/api/ad/conversion.js?conversion_tag_id=${conversionTag.id}' type='text/javascript'></script>`}
          </JavaScriptTag>
        </JavaScriptTagWrapper>
      )}
    </Container>
  );
}

// connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const {
    adAccounts: idAdAccount,
    conversionTags: idConversionTag,
  } = state.adAccount;
  return { idAdAccount, idConversionTag };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchConversionTagSummary: (params: {
        adAccountId: string;
        conversionTagId: string;
      }) => fetchConversionTagSummary.started(params),
      createConversionTag: (params: CreateConversionTagRequest) =>
        createConversionTag.started(params),
      updateConversionTag: (params: UpdateConversionTagRequest) =>
        updateConversionTag.started(params),
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConversionTagContainer)
);

const Container = styled.div`
  width: 640px;
  margin: 71px auto auto;
`;

const NameInput = styled.input`
  border-style: none;
  height: 40px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-right: 20px;
  width: 100%;
  outline: 0;
  text-indent: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
`;

const UpdateConversionTag = styled.div`
  ${heading}
  line-height: 1.71;
  border-radius: 24px;
  color: #ffffff;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  padding: 8px 32px;
  flex-shrink: 0;
  cursor: pointer;
`;

const CreateConversionTag = styled.div`
  ${heading}
  line-height: 1.71;
  border-radius: 24px;
  color: #ffffff;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  padding: 8px 32px;
  flex-shrink: 0;
  cursor: pointer;
`;

const ConversionCountWrapper = styled.div`
  display: flex;
`;

const ConversionCountHeader = styled.div``;

const ConversionCountText = styled.div`
  padding-left: 10px;
`;

const JavaScriptTagWrapper = styled.div`
  margin-top: 15px;
`;
const JavaScriptTagHeader = styled.div``;
const JavaScriptTag = styled.div`
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 12px;
`;
