import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { heading } from "../styles/font";
import colors from "../styles/color";
import { RootState } from "../store/modules";
import { fetchAdSetSummary } from "../store/modules/adSet";
import { adContentFocused } from "../store/modules/ui";
import AdReport from "../lib/AdReport";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

class AdContentListContainer extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    this.props.fetchAdSetSummary({
      adAccountId: params["ad_account_id"],
      adCampaignId: params["ad_campaign_id"],
      adSetId: params["ad_set_id"],
    });
    this.handleClickNewAdContentLink = this.handleClickNewAdContentLink.bind(
      this
    );
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      if (!this.props.match) {
        return null;
      }
      const { params } = this.props.match;
      this.props.fetchAdSetSummary({
        adAccountId: params["ad_account_id"],
        adCampaignId: params["ad_campaign_id"],
        adSetId: params["ad_set_id"],
      });
    }
  }

  // jsx
  // -----------------------------------------------

  public render() {
    if (!this.props.match) {
      return null;
    }
    const { params } = this.props.match;
    const adAccountId = params["ad_account_id"];
    const adCampaignId = params["ad_campaign_id"];
    const adSetId = params["ad_set_id"];
    const { idAdSet, idAdContent, focusedAdContentId } = this.props;
    const adSet = idAdSet[this.props.match.params["ad_set_id"]];
    return (
      <Container>
        <Wrapper>
          <SearchInput />
          <NewAdContentLink
            to={`/admin/ad/ad_accounts/${adAccountId}/ad_campaigns/${adCampaignId}/ad_sets/${adSetId}/ad_contents/new`}
            onClick={this.handleClickNewAdContentLink}
          >
            Create Ad
          </NewAdContentLink>
        </Wrapper>
        <ContentsTable>
          <HeaderRow>
            <AdContentNameHeader>Ad Content</AdContentNameHeader>
            <AdContentHeader>Spent Budget</AdContentHeader>
            <AdContentHeader>Imp</AdContentHeader>
            <AdContentHeader>Click</AdContentHeader>
            <AdContentHeader>Ctr</AdContentHeader>
            <AdContentHeader>Cpc</AdContentHeader>
            <StatusHeader />
          </HeaderRow>
          {!!adSet &&
            adSet.adContents.map(adContentId => {
              const adContent = idAdContent[adContentId];
              return (
                <TableRow
                  key={adContentId}
                  focused={Number(adContentId) === Number(focusedAdContentId)}
                  onClick={() =>
                    this.props.focusAdContent({
                      adContentId,
                    })
                  }
                >
                  <td>
                    <AdContent>
                      <Meta>
                        <Title>{adContent.title}</Title>
                        <SourceName>{adContent.name}</SourceName>
                      </Meta>
                      <Image src={adContent.imageUrl} />
                    </AdContent>
                  </td>
                  <td>
                    <ReportData>
                      {adContent.report.spentBudget.toLocaleString()}
                    </ReportData>
                  </td>
                  <td>
                    <ReportData>
                      {adContent.report.impression.toLocaleString()}
                    </ReportData>
                  </td>
                  <td>
                    <ReportData>
                      {adContent.report.click.toLocaleString()}
                    </ReportData>
                  </td>
                  <td>
                    <ReportData>{adContent.report.ctr}</ReportData>
                  </td>
                  <td>
                    <ReportData>{adContent.report.cpc}</ReportData>
                  </td>
                  <td>
                    <Status>
                      {adContent.status === "ACTIVE" && <ActiveIndicator />}
                      {adContent.status === "STOPPED" && <StoppedIndicator />}
                    </Status>
                  </td>
                </TableRow>
              );
            })}
        </ContentsTable>
        <AdReportWrapper>
          {!!adSet && !!adSet.dailyReport && (
            <AdReport rawData={adSet.dailyReport} />
          )}
        </AdReportWrapper>
      </Container>
    );
  }

  private handleClickNewAdContentLink() {
    this.props.focusAdContent({ adContentId: "" });
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdContentListContainer)
);

function mapStateToProps(state: RootState) {
  const { adSets: idAdSet } = state.adSet;
  const { adContents: idAdContent } = state.adContent;
  const { focusedAdContentId } = state.ui.adSetSummary;
  return { idAdSet, idAdContent, focusedAdContentId };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchAdSetSummary: (params: {
        adAccountId: string;
        adCampaignId: string;
        adSetId: string;
      }) => fetchAdSetSummary.started(params),
      focusAdContent: (params: { adContentId: string }) =>
        adContentFocused(params),
    },
    dispatch
  );
}

const Container = styled.div`
  /* FIXME: */
  min-width: 640px;
  margin: 71px auto auto;
`;

const NewAdContentLink = styled(Link)`
  ${heading}
  line-height: 1.71;
  border-radius: 24px;
  color: #ffffff;
  width: 112px;
  height: 36px;
  background-image: linear-gradient(108deg, #7983f2, #ab6bdb);
  padding: 8px 32px;
  width: 136px;
  flex-shrink: 0;
`;

const TableRow = styled.tr<{ focused: boolean }>`
  border-top: 1px solid ${colors.blackForeground50};
  cursor: pointer;
  ${props =>
    props.focused
      ? css`
          background-color: rgba(0, 0, 0, 0.03);
        `
      : css``}
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
  flex-shrink: 0;
  object-fit: cover;
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: ${colors.blackForeground840};
`;

const ActiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #08c5e7;
  margin-left: auto;
`;

const StoppedIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
  margin-left: auto;
`;

const SearchInput = styled.input`
  border-style: none;
  height: 40px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-right: 20px;
  width: 100%;
  outline: 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
`;

const ContentsTable = styled.table`
  min-width: 640px;
`;

const HeaderRow = styled.tr`
  height: 30px;
`;

const AdContentNameHeader = styled.th`
  text-align: left;
  text-indent: 7px;
`;
const AdContentHeader = styled.th`
  text-align: right;
`;
const StatusHeader = styled.th`
  text-align: center;
`;

const AdContent = styled.div`
  margin: 7px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: left;
`;
const ReportData = styled.div`
  line-height: 1.33;
  text-align: right;
  margin-left: 10px;
`;
const Status = styled.div`
  margin: auto 20px;
`;

const Meta = styled.div``;
const Title = styled.div`
  ${heading}
  font-size: 14px;
  line-height: 1.43;
  max-width: 240px;
  color: ${colors.blackForeground800};
  word-wrap: break-word;
`;

const SourceName = styled.div`
  font-size: 12px;
  line-height: 1.33;
  max-width: 240px;
  color: ${colors.blackForeground400};
  word-wrap: break-word;
`;

const AdReportWrapper = styled.div`
  margin-top: 20px;
`;
