import React from "react";

import RootTemplate from "./RootTemplate";
import ConversionTagContainer from "./ConversionTagContainer";

function ConversionTagPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <ConversionTagContainer />;
}

export default ConversionTagPage;
