import React from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";

import color from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";

interface OwnProps {
  adSetId: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps &
  OwnProps;

class AdSetReportContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  // jsx
  // -----------------------------------------------

  public render() {
    const adSet = this.props.idAdSet[this.props.adSetId];
    if (!adSet || !adSet.report) {
      return null;
    }

    return (
      <>
        <TopBody>{adSet.report.impression.toLocaleString()}</TopBody>
        <Head>Impression</Head>
        <Body>{adSet.report.click.toLocaleString()}</Body>
        <Head>Click</Head>
        <Body>{adSet.report.cpc.toLocaleString()}</Body>
        <Head>Cpc</Head>
        <Body>{adSet.report.spentBudget.toLocaleString()}</Body>
        <Head>Spent Budget</Head>
      </>
    );
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { adSets: idAdSet } = state.adSet;
  return { idAdSet };
}

export default withRouter(connect(mapStateToProps)(AdSetReportContainer));

const Body = styled.div`
  ${heading}
  margin-top: 23px;
  font-size: 28px;
  line-height: 1;
  color: ${color.blackForeground840};
`;

const TopBody = styled(Body)`
  margin-top: 0px;
`;

const Head = styled.div`
  font-size: 14px;
  line-height: 1.8;
  color: ${color.blackForeground840};
`;
