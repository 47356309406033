import React from "react";

import RootTemplate from "./RootTemplate";
import AdCampaignSummaryContainer from "./AdCampaignSummaryContainer";

function AdCampaignSummaryPage() {
  return <RootTemplate render={render} />;
}

function render() {
  return <AdCampaignSummaryContainer />;
}

export default AdCampaignSummaryPage;
