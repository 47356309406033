import actionCreatorFactory, { Success } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import produce from "immer";
import { Reducer } from "redux";

//  Types
// -----------------------------------------------

export interface Report {
  id: number;
  uuid: string;
  impressionCount: number;
  clickCount: number;
  linkListUuid: string;
}

export interface LinkReport {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  media: string;
  title: string;
  report: Report;
  url: string;
}

//  State
// -----------------------------------------------

export interface LinkReportState {
  linkReports: LinkReport[];
}

export const initialState: LinkReportState = {
  linkReports: [],
};

//  Action
// -----------------------------------------------
const actionCreator = actionCreatorFactory("LINK_REPORT");

//  FETCH_LINK_REPORTS
// -----------------------
export interface FetchLinkReportResponse {
  links: LinkReport[];
}
export const fetchLinkReports = actionCreator.async<
  void,
  FetchLinkReportResponse,
  Error
>("FETCH_LINK_REPORTS");

//  Reducer
// -----------------------------------------------

const LinkReportReducer: Reducer<LinkReportState> = reducerWithInitialState<
  LinkReportState
>(initialState).case<Success<void, FetchLinkReportResponse>>(
  fetchLinkReports.done,
  (state, { result: { links } }) =>
    produce(state, (draft: LinkReportState) => {
      draft.linkReports = links;
    })
);
export default LinkReportReducer;
