import React from "react";
import styled from "styled-components";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

import colors from "../styles/color";
import {
  DragLinkListItemParams,
  dragLinkListItem,
  DragFromLinkIndexParams,
  dragFromLinkIndex,
} from "../store/modules/linkList";
import { LinkListDragged, linkListDragged } from "../store/modules/sceneEntry";
import Icon from "../lib/Icon";

import SceneEntriesContainer from "./SceneEntry/SceneEntriesContainer";

interface RenderProps {
  children?: React.ReactNode;
}

interface OwnProps {
  render: (args?: RenderProps) => React.ReactNode;
}

type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;

function RootTemplate(props: Props) {
  //  functions
  // -----------------------------------------------

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.droppableId === "link-index") {
      return;
    }
    if (result.source.droppableId === "link-index") {
      const linkUuid = result.draggableId;
      const {
        index: insertedIndex,
        droppableId: linkListUuid,
      } = result.destination;
      props.dragFromLinkIndex({
        linkListUuid,
        linkUuid,
        insertedIndex,
      });
    } else if (result.source.droppableId.startsWith("sceneEntries-index")) {
      const sceneEntryId = result.destination.droppableId.split(":")[1];
      props.linkListDragged({
        sceneEntryId,
        startIndex: result.source.index,
        endIndex: result.destination.index,
      });
    } else {
      props.draglinklistItem({
        linkListUuid: result.source.droppableId,
        startIndex: result.source.index,
        endIndex: result.destination.index,
      });
    }
  };

  //  jsx
  // -----------------------------------------------

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Nav>
          <StyledLink href="/admin/articles/link_reports" target="_blank">
            <StyledIcon name="wt-icon-bar-chart" />
          </StyledLink>
        </Nav>
        <Side>
          <SceneEntriesContainer />
        </Side>
        <Main>{props.render()}</Main>
      </Container>
    </DragDropContext>
  );
}

//  connect
// -----------------------------------------------

export default connect(
  null,
  mapDispatchToProps
)(RootTemplate);

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      draglinklistItem: ({
        linkListUuid,
        startIndex,
        endIndex,
      }: DragLinkListItemParams) =>
        dragLinkListItem({ linkListUuid, startIndex, endIndex }),
      dragFromLinkIndex: ({
        linkListUuid,
        linkUuid,
        insertedIndex,
      }: DragFromLinkIndexParams) =>
        dragFromLinkIndex({
          linkListUuid,
          linkUuid,
          insertedIndex,
        }),
      linkListDragged: ({
        sceneEntryId,
        startIndex,
        endIndex,
      }: LinkListDragged) =>
        linkListDragged({
          sceneEntryId,
          startIndex,
          endIndex,
        }),
    },
    dispatch
  );
}

//  style
// -----------------------------------------------

const Container = styled.div`
  display: grid;
  grid-template-columns: 64px 375px 1fr;
  grid-template-rows: 100vh;
`;

const Nav = styled.div`
  background-color: #fafafa;
  padding: 20px;
`;

const Side = styled.div`
  background-color: #fafafa;
  overflow-y: scroll;
`;

const Main = styled.div`
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow-y: scroll;
`;

const StyledIcon = styled(Icon)`
  font-size: 24px;
  color: ${colors.gray500};
  cursor: pointer;
`;

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  width: 24px;
  height: 24px;
`;
