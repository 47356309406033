import React from "react";
import styled, { css } from "styled-components";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import "antd/dist/antd.css";

import configureStore from "./store/configureStore";
import Routes from "./Routes";
import colors from "./styles/color";

export const history = createHistory();
const store = configureStore();

function App() {
  const isProd = process.env.REACT_APP_NODE_ENV === "production";
  return (
    <Provider store={store}>
      {!isProd ? (
        <EnvHeader
          env={
            process.env.REACT_APP_NODE_ENV as "qa" | "sandbox" | "development"
          }
        >
          You are in {process.env.REACT_APP_NODE_ENV} environment :)
        </EnvHeader>
      ) : null}
      <Container isProd={isProd}>
        <Router history={history}>
          <Routes />
        </Router>
      </Container>
    </Provider>
  );
}

export default App;

interface ContainerStyle {
  isProd: boolean;
}

interface EnvHeaderStyle {
  env: "qa" | "sandbox" | "development";
}

const Container = styled.div`
  ${(props: ContainerStyle) =>
    props.isProd
      ? css``
      : css`
          padding-top: 30px;
        `}
`;

const EnvHeader = styled.div`
  position: fixed;
  width: 100%;
  text-align: center;
  padding: 4px 0;
  background-color: ${(props: EnvHeaderStyle) =>
    props.env === "qa"
      ? "#FF9900"
      : props.env === "sandbox"
      ? "#C52587"
      : colors.gray400};
`;
