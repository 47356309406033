import React from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";

import color from "../../styles/color";
import { heading } from "../../styles/font";
import { RootState } from "../../store/modules";

interface OwnProps {
  adCampaignId: string;
}

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps &
  OwnProps;

class AdCampaignReportContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  // jsx
  // -----------------------------------------------

  public render() {
    const adCampaign = this.props.idAdCampaign[this.props.adCampaignId];
    return (
      <>
        <TopBody>
          {!!adCampaign &&
            !!adCampaign.report &&
            adCampaign.report.impression.toLocaleString()}
        </TopBody>
        <Head>Impression</Head>
        <Body>
          {!!adCampaign &&
            !!adCampaign.report &&
            adCampaign.report.click.toLocaleString()}
        </Body>
        <Head>Click</Head>
        <Body>
          {!!adCampaign &&
            !!adCampaign.totalBudget &&
            adCampaign.totalBudget.toLocaleString()}
        </Body>
        <Head>Total Buget</Head>
        <Body>
          {!!adCampaign &&
            adCampaign.dailyBudget &&
            adCampaign.dailyBudget.toLocaleString()}
        </Body>
        <Head>Daily Buget</Head>
        <Body>
          {!!adCampaign &&
            !!adCampaign.report &&
            adCampaign.report.spentBudget.toLocaleString()}
        </Body>
        <Head>Spent Budget</Head>
      </>
    );
  }
}

//  connect
// -----------------------------------------------

function mapStateToProps(state: RootState) {
  const { adCampaigns: idAdCampaign } = state.adCampaign;
  return { idAdCampaign };
}

export default withRouter(connect(mapStateToProps)(AdCampaignReportContainer));

const Body = styled.div`
  ${heading}
  margin-top: 23px;
  font-size: 28px;
  line-height: 1;
  color: ${color.blackForeground840};
`;

const TopBody = styled(Body)`
  margin-top: 0px;
`;

const Head = styled.div`
  font-size: 14px;
  line-height: 1.8;
  color: ${color.blackForeground840};
`;
