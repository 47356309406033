import React from "react";
import styled from "styled-components";

import Icon from "../lib/Icon";
import { history } from "../App";

class AdGlobalNavigation extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
    this.handleClickAdDeliverySettings = this.handleClickAdDeliverySettings.bind(
      this
    );
    this.handleClickAdAdminSettings = this.handleClickAdAdminSettings.bind(
      this
    );
  }

  public render() {
    return (
      <Container>
        <WantedlyIcon src="https://de7iszmjjjuya.cloudfront.net/assets/wantedly-logo-a0ae0c0da51ab402f4ae76a1772c19579277ec2b369fc42ea61a35c9957a2acc.svg" />
        <AdDeliverySettingsIcon
          onClick={this.handleClickAdDeliverySettings}
          name="wt-icon-newspaper-o-2"
        />
        <AdAdminSettingsIcon
          onClick={this.handleClickAdAdminSettings}
          name="wt-icon-gear"
        />
      </Container>
    );
  }

  private handleClickAdDeliverySettings() {
    history.push("/admin/ad/ad_accounts");
  }

  private handleClickAdAdminSettings() {
    history.push("/admin/ad/ad_settings");
  }
}

const Container = styled.div`
  width: 64px;
`;

const WantedlyIcon = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  margin: 16px auto 0px;
`;

const AdDeliverySettingsIcon = styled(Icon)`
  font-size: 24px;
  display: block;
  margin-top: 22px;
  margin-left: 20px;
  object-fit: contain;
  cursor: pointer;
`;

const AdAdminSettingsIcon = styled(Icon)`
  font-size: 24px;
  display: block;
  margin-top: 14px;
  margin-left: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export default AdGlobalNavigation;
